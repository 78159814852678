import React, { useState, useEffect } from 'react';
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/projects.css";
import car1 from "../../assets/images/car1.png";
import car from "../../assets/images/car.png";
import ModelSearchFormSecond from './ModelSearchFormSecond';
import { getNews } from "../../api";

const Projects = () => {
    const [activeTab, setActiveTab] = useState('GROWING_NUMBERS');
    const toggleTab = (tabName) => {
        setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
    };

    const [activePage, setActivePage] = useState(1);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const itemsPerPage = 9;
    
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await getNews(token); 
                if (response.status_code && response.data) {
                    setProjects(response.data);
                } else {
                    throw new Error(response.message || "Failed to retrieve projects");
                }
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    const totalPages = Math.ceil(projects.length / itemsPerPage);
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePageClick = (page) => {
        setActivePage(page);
    };
    const getCurrentItems = () => {
        const start = (activePage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return projects.slice(start, end);
    };
    const getImageUrl = (images) => {
        if (images && images.length > 0 && images[0].url) {
            return images[0].url;
        }
        return car; 
    };
    const formatDate = (dateString) => {
        if (!dateString) return "";
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        } catch (error) {
            return dateString;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading projects: {error.message}</div>;
    }
    const currentItems = getCurrentItems();
    const rows = [];
    for (let i = 0; i < currentItems.length; i += 3) {
        rows.push(currentItems.slice(i, i + 3));
    }

    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='projects_page'>
                <div className='container'>
                    <div className='row project_responsive'>
                        <div className='col-2 projects_heading my-5'>
                            PROJECTS
                        </div>
                        <div className='col-8 project_search my-5'>
                            <span><svg className='project_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg></span>
                            <input className='project_search_tab' type="text" placeholder='Search vehicle and/or tuning type' />
                        </div>
                        <div className='col-2 my-5 pencel_tab_responsive'>
                            <div className='tab_growing_project'>
                                <div
                                    className={`project_pragraph_tab ${activeTab === 'GROWING_NUMBERS' ? 'active' : ''}`}
                                    onClick={() => toggleTab('GROWING_NUMBERS')}
                                >
                                    <svg className='penal_tab_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm160 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM352 160a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM320 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>
                                </div>
                                <div
                                    className={`project_pragraph_tab ${activeTab === 'CONTINUOUS_IMPROVEMENT' ? 'active' : ''}`}
                                    onClick={() => toggleTab('CONTINUOUS_IMPROVEMENT')}
                                >
                                    <svg className='penal_tab_icon_1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z" /></svg>
                                </div>
                            </div>
                        </div>
                        {activeTab === 'GROWING_NUMBERS' && (
                            <div className='project_one'>
                                {rows.map((row, rowIndex) => (
                                    <div key={rowIndex} className='cars_pagination project_responsive'>
                                        {row.map((project) => (
                                            <div key={project._id} className='car_pagination'>
                                                <img src={getImageUrl(project.images)} alt={project.title} width={300} height={230} className='box_image_car' />
                                                <h5 className='car_heading'>{formatDate(project.create_date)}</h5>
                                                <p className='car_heading_1'>
                                                    <a href={`/car-overview/${project.slug}`} className='car_heading_1'>{project.title}</a>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                           
                        )}
                        {activeTab === 'CONTINUOUS_IMPROVEMENT' && (
                            <div>
                                {currentItems.map((project) => (
                                    <div key={project._id} className='project_one_car Larger shadow'>
                                        <img src={getImageUrl(project.images)} alt={project.title} width={80} height={60} />
                                        <div>
                                            <h5 className='car_heading'>{formatDate(project.create_date)}</h5>
                                            <p className='car_heading_2'>
                                                <a href={`/caroverveiw/${project.slug}`} className='car_heading_2'>{project.title}</a>
                                            </p>
                                        </div>
                                        <div className='icon_car_tab'>
                                            <svg className='icon_car' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='container'>
                    <div className='row projects_pagiation'>
                        <div className='col-6 my-3 pagination_pragraph_res'>
                            <p className='pagination_pragraph'>
                                {((activePage - 1) * itemsPerPage) + 1} - {Math.min(activePage * itemsPerPage, projects.length)} of {projects.length} projects
                            </p>
                        </div>
                        <div className="col-6 my-3 pagination_pragraph_res pagination_pragraph_end">
                            <ul className="pagination">
                                <li className={`Previous_btn ${activePage === 1 ? "disabled" : ""}`}>
                                    <a
                                        href="#"
                                        className='pagination_btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (activePage > 1) handlePageClick(activePage - 1);
                                        }}
                                    >
                                        Previous
                                    </a>
                                </li>

                                {pages.map((page) => (
                                    <li key={page} className='pt-2'>
                                        <a
                                            href="#"
                                            className={`pagination_btn ${activePage === page ? "active" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handlePageClick(page);
                                            }}
                                        >
                                            {page}
                                        </a>
                                    </li>
                                ))}

                                <li className={`Next_btn pt-2 ${activePage === pages.length ? "disabled" : ""}`}>
                                    <a
                                        href="#"
                                        className='pagination_btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (activePage < pages.length) handlePageClick(activePage + 1);
                                        }}
                                    >
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <MainFooter />
        </>
    );
};

export default Projects;