import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart, clearCart, updateQuantity } from "../../../cartSlice";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import paypal from "../../../assets/images/cards/paypal.svg";
import ideal from "../../../assets/images/cards/ideal.svg";
import visa from "../../../assets/images/cards/visa.svg";
import mastercard from "../../../assets/images/cards/mastercard.svg";
import amex from "../../../assets/images/cards/amex.svg";
import bancontact from "../../../assets/images/cards/bancontact.svg";
import applepay from "../../../assets/images/cards/applepay.svg";
import googlepay from "../../../assets/images/cards/googlepay.svg";
import wechat from "../../../assets/images/cards/wechat.svg";
import alipay from "../../../assets/images/cards/alipay.svg";
import { toast } from "react-toastify";
import { saveTransaction, getProfile, directPayment } from "../../../api";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { processStripePayment } from "../../../api";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeCheckoutForm = ({
  items,
  subtotal,
  onError,
  isProcessing,
  setIsProcessing,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [paymentError, setPaymentError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      onError("Stripe hasn't loaded yet. Please try again in a moment.");
      return;
    }

    setIsProcessing(true);
    setPaymentError(null);

    try {
      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (error) {
        return new Error(error.message);
      }

      if (token) {
        const creditsData = items
          .filter((item) => item.type !== "file_service")
          .map((item) => ({
            value:
              item.payableCredits !== undefined
                ? Number(item.payableCredits) * item.quantity
                : Number(item.details.totalCredits) * item.quantity || 0,
            type: item.details.type || "credits",
            usedWalletCredits: item.usedWalletCredits || 0,
          }));

        const orderId = Math.floor(100000 + Math.random() * 900000);
        const fileServiceItems = items.filter(
          (item) => item.type === "file_service"
        );

        let totalUsedWalletCredits = 0;
        let totalFileServiceValue = 0;

        fileServiceItems.forEach((item) => {
          totalUsedWalletCredits += item.usedWalletCredits || 0;
          totalFileServiceValue +=
            item.payableCredits !== undefined
              ? Number(item.payableCredits) * item.quantity
              : Number(item.details.totalCredits) * item.quantity || 0;
        });

        const paymentPayload = {
          token_id: token.id,
          amount: parseFloat(subtotal).toFixed(2),
          credit: creditsData,
          order_id: orderId.toString(),
        };

        if (fileServiceItems.length > 0) {
          paymentPayload.pay_mode = "file_service";
          paymentPayload.usedWalletCredits = totalUsedWalletCredits;
          paymentPayload.fileServiceValue = totalFileServiceValue;
        }

        const response = await processStripePayment(
          paymentPayload,
          localStorage.getItem("Access-Token")
        );

        if (response.status === "succeeded") {
          toast.success(response.message || "Payment processed successfully!");
          dispatch(clearCart());
        } else if (response.error) {
          return new Error(response.error || "Payment processing failed");
        } else if (response.status === "failed") {
          return new Error(response.message || "Payment processing failed");
        } else {
          toast.warning(
            "Payment may have been processed, but we couldn't confirm it. Please check your account."
          );
          dispatch(clearCart());
        }
      } else {
        return new Error("Payment token creation failed");
      }
    } catch (error) {
      console.error("Payment error:", error);
      setPaymentError(error.message);
      onError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mb-3">
        <div className="payment-element-container p-3 border rounded">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
              hidePostalCode: true,
            }}
          />
        </div>
      </div>

      {paymentError && (
        <div className="alert alert-danger" role="alert">
          {paymentError}
        </div>
      )}

      <button
        type="submit"
        className="btn btn-primary w-100"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Processing...
          </>
        ) : (
          `Pay €${subtotal.toFixed(2)}`
        )}
      </button>
    </form>
  );
};

const appearanceOptions = {
  theme: "stripe",
  variables: {
    colorPrimary: "#007bff",
  },
};

const Cart = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [userCredits, setUserCredits] = useState([]);
  const [isLoadingCredits, setIsLoadingCredits] = useState(false);
  const paypalRef = useRef();
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.cart);

  const creditPricing = {
    0.5: 50,
    1: 90,
    2: 180,
    5: 400,
    10: 700,
    25: 1625,
    50: 3000,
    100: 6000,
  };

  const handleZeroAmountOrder = async () => {
    if (items.length === 0) {
      toast.error("Your cart is empty");
      return;
    }

    setIsProcessing(true);

    try {
      const orderId = Math.floor(100000 + Math.random() * 900000);
      const token = localStorage.getItem("Access-Token");
      const creditsData = items
      .filter((item) => item.type !== "file_service")
      .map((item) => ({
        value:
          item.payableCredits !== undefined
            ? Number(item.payableCredits) * item.quantity
            : Number(item.details.totalCredits) * item.quantity || 0,
        type: item.details.type || "credits",
        usedWalletCredits: item.usedWalletCredits || 0,
      }));
    
      // Create payload similar to Stripe
      const directData = {
        order_id: orderId.toString(),
        amount: parseFloat(subtotal).toFixed(2),
        payment_type: "direct",
        credit:creditsData
      };

      // Add file service specific fields if applicable
      const fileServiceItems = items.filter(
        (item) => item.type === "file_service"
      );
      if (fileServiceItems.length > 0) {
        directData.pay_mode = "file_service";

        const totalUsedWalletCredits = fileServiceItems.reduce(
          (sum, item) => sum + (item.usedWalletCredits || 0),
          0
        );

        const totalFileServiceValue = fileServiceItems.reduce(
          (sum, item) =>
            sum +
            (item.payableCredits !== undefined
              ? Number(item.payableCredits) * item.quantity
              : Number(item.details.totalCredits) * item.quantity || 0),
          0
        );

        directData.usedWalletCredits = totalUsedWalletCredits;
        directData.fileServiceValue = totalFileServiceValue;
      }

      const response = await directPayment(directData, token);

      if (response.status === "succeeded" || response.status === "success") {
        dispatch(clearCart());
        toast.success(response.message || "Order processed successfully!");
      } else {
        toast.error(response.message || "Order processing failed");
      }
    } catch (error) {
      console.error("Zero amount order error:", error);
      toast.error("Order failed. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };
  useEffect(() => {
    const fetchUserCredits = async () => {
      setIsLoadingCredits(true);
      try {
        const response = await getProfile();
        if (response && response.status_code === 200 && response.detail) {
          setUserCredits(response.detail.credit || []);
        }
      } catch (error) {
        console.error("❌ Error fetching user credits:", error);
      } finally {
        setIsLoadingCredits(false);
      }
    };

    fetchUserCredits();
  }, []);
  useEffect(() => {
    if (userCredits.length > 0 && items.length > 0) {
      let availableCredits = userCredits.reduce((total, credit) => {
        if (credit?.type?.includes("file_service") || credit?.type === "master-credits") {
          return total + (credit.value || 0);
        }
        return total;
      }, 0);
  
      let updatedItems = [...items];
      let creditsChanged = false;
      const sortedItems = [...updatedItems].sort((a, b) => {
        const aCredits = Number(a.details.totalCredits) * a.quantity;
        const bCredits = Number(b.details.totalCredits) * b.quantity;
        return aCredits - bCredits;
      });
      const processedItems = sortedItems.map((item) => {
        if (item.type === "file_service" && availableCredits > 0) {
          const requiredCredits = Number(item.details.totalCredits) * item.quantity;
          const usedCredits = Math.min(availableCredits, requiredCredits);
          const payableCredits = Math.max(0, requiredCredits - usedCredits);
          availableCredits -= usedCredits;
  
          if (usedCredits > 0) {
            creditsChanged = true;
            return {
              ...item,
              usedWalletCredits: usedCredits,
              payableCredits: payableCredits,
            };
          }
        }
  
        return item;
      });
      updatedItems = processedItems.reduce((result, item) => {
        const originalIndex = updatedItems.findIndex(orig => orig.id === item.id);
        result[originalIndex] = item;
        return result;
      }, [...updatedItems]);
  
      if (creditsChanged) {
        updatedItems.forEach((item) => {
          if (item.usedWalletCredits > 0) {
            dispatch(
              updateQuantity({
                id: item.id,
                changes: {
                  usedWalletCredits: item.usedWalletCredits,
                  payableCredits: item.payableCredits,
                },
              })
            );
          }
        });
      } else {
        console.log("⏩ No credits were applied - skipping cart update");
      }
    } else {
      console.log(
        "⏩ Either userCredits or items are empty - skipping credit application"
      );
    }
  }, [userCredits, items, dispatch]);

  const calculateItemPrice = (item) => {
    if (item.payableCredits !== undefined) {
      const payableCredits = Number(item.payableCredits) || 0;
      if (creditPricing[payableCredits]) {
        const price = creditPricing[payableCredits] * item.quantity;
        return price;
      }

      const price = payableCredits * 120 * item.quantity;
      return price;
    } else {
      const credits = Number(item.details.totalCredits) || 0;
      if (creditPricing[credits]) {
        const price = creditPricing[credits] * item.quantity;
        return price;
      }
      const price = credits * 120 * item.quantity;
      return price;
    }
  };

  const calculateTotal = () => {
    return items.reduce((total, item) => {
      return total + calculateItemPrice(item);
    }, 0);
  };

  const subtotal = calculateTotal();
  const totalItems = items.reduce((acc, item) => acc + item.quantity, 0);

  const paymentMethods = [
    { name: "PayPal", image: paypal, type: "paypal" },
    { name: "iDEAL", image: ideal, type: "stripe" },
    { name: "VISA", image: visa, type: "stripe" },
    { name: "Mastercard", image: mastercard, type: "stripe" },
    { name: "American Express", image: amex, type: "stripe" },
    { name: "Bancontact", image: bancontact, type: "stripe" },
    { name: "Apple Pay", image: applepay, type: "stripe" },
    { name: "Google Pay", image: googlepay, type: "stripe" },
    { name: "WeChat Pay", image: wechat, type: "other" },
    { name: "Alipay", image: alipay, type: "other" },
  ];

  useEffect(() => {
    if (showStripeForm) {
      setStripeOptions({
        appearance: appearanceOptions,
        clientSecret: null,
        mode: "payment",
      });
    }
  }, [showStripeForm]);

  useEffect(() => {
    if (window.paypal) {
      return;
    }
    const script = document.createElement("script");
    script.src = `https://www.sandbox.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&currency=EUR`;
    script.async = true;
    script.onload = () => console.log("PayPal SDK loaded");
    script.onerror = () => console.error("PayPal SDK failed to load");

    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !showPayPalButton ||
      !window.paypal ||
      !paypalRef.current ||
      items.length === 0
    ) {
      return;
    }

    try {
      paypalRef.current.innerHTML = "";

      window.paypal
        .Buttons({
          style: {
            layout: "horizontal",
            color: "blue",
            shape: "rect",
            label: "pay",
          },

          createOrder: function (data, actions) {
            if (items.length === 0) {
              toast.error("Your cart is empty");
              return Promise.reject("Cart is empty");
            }
            const purchaseUnits = items.map((item) => {
              const credits =
                item.payableCredits !== undefined
                  ? Number(item.payableCredits)
                  : Number(item.details.totalCredits) || 0;

              const itemPrice = calculateItemPrice(item) / item.quantity;

              return {
                amount: {
                  currency_code: "EUR",
                  value: itemPrice.toFixed(2),
                  breakdown: {
                    item_total: {
                      currency_code: "EUR",
                      value: itemPrice.toFixed(2),
                    },
                  },
                },
                items: [
                  {
                    name:
                      `${credits} Credits` +
                      (item.usedWalletCredits
                        ? ` (+ ${item.usedWalletCredits} from wallet)`
                        : ""),
                    unit_amount: {
                      currency_code: "EUR",
                      value: itemPrice.toFixed(2),
                    },
                    quantity: item.quantity.toString(),
                    description:
                      `${credits} Credits` +
                      (item.usedWalletCredits
                        ? ` (+ ${item.usedWalletCredits} from wallet)`
                        : ""),
                  },
                ],
              };
            });

            const consolidatedPurchaseUnit = {
              amount: {
                currency_code: "EUR",
                value: subtotal.toFixed(2),
              },
              description: `Credits Purchase - ${totalItems} items`,
            };

            return actions.order.create({
              purchase_units:
                items.length > 1 ? [consolidatedPurchaseUnit] : purchaseUnits,
            });
          },

          onApprove: function (data, actions) {
            setIsProcessing(true);

            return actions.order.capture().then(function (orderData) {
              const transactionId = orderData.id;
              const amount = orderData.purchase_units[0].amount.value;
              const status = orderData.status;
              const orderId = Math.floor(100000 + Math.random() * 900000);
              const token = localStorage.getItem("Access-Token");
              const creditsData = items
              .filter((item) => item.type !== "file_service")
              .map((item) => ({
                value:
                  item.payableCredits !== undefined
                    ? Number(item.payableCredits) * item.quantity
                    : Number(item.details.totalCredits) * item.quantity || 0,
                type: item.details.type || "credits",
                usedWalletCredits: item.usedWalletCredits || 0,
              }));
            
              // Align payload with Stripe structure
              const paymentPayload = {
                transaction_id: transactionId,
                amount: parseFloat(amount).toFixed(2),
                status: status.toLowerCase(),
                payment_type: "paypal",
                order_id: orderId.toString(),
                credit: creditsData, // This will be an empty array if only file services are in the cart
              };
              
              const fileServiceItems = items.filter((item) => item.type === "file_service");
              if (fileServiceItems.length > 0) {
                paymentPayload.pay_mode = "file_service";
              
                const totalUsedWalletCredits = fileServiceItems.reduce(
                  (sum, item) => sum + (item.usedWalletCredits || 0),
                  0
                );
                const totalFileServiceValue = fileServiceItems.reduce(
                  (sum, item) =>
                    sum +
                    (item.payableCredits !== undefined
                      ? Number(item.payableCredits) * item.quantity
                      : Number(item.details.totalCredits) * item.quantity || 0),
                  0
                );
              
                paymentPayload.usedWalletCredits = totalUsedWalletCredits;
                paymentPayload.fileServiceValue = totalFileServiceValue;
              }
              return saveTransaction(paymentPayload, token)
                .then((response) => {
                  dispatch(clearCart());
                  toast.success(`Payment successful!`);
                  setIsProcessing(false);
                  setShowPayPalButton(false);
                })
                .catch((error) => {
                  console.error("Error saving transaction:", error);
                  dispatch(clearCart());
                  toast.success(
                    `Payment successful! Transaction ID: ${transactionId}`
                  );
                  toast.warning(
                    "Transaction record may not have been saved to our system."
                  );
                  setIsProcessing(false);
                  setShowPayPalButton(false);
                });
            });
          },
          onError: function (err) {
            console.error("PayPal error", err);
            toast.error("Payment failed. Please try again.");
            setIsProcessing(false);
          },
        })
        .render(paypalRef.current);
    } catch (error) {
      console.error("Error rendering PayPal buttons:", error);
      toast.error(
        "Failed to initialize PayPal. Please try again or choose another payment method."
      );
    }
  }, [showPayPalButton, subtotal, totalItems, dispatch, items]);

  const handleCardClick = (index) => {
    if (items.length === 0) {
      toast.error("Your cart is empty");
      return;
    }

    setSelectedCard(index);
    const method = paymentMethods[index];
    setShowPayPalButton(false);
    setShowStripeForm(false);
    if (method.type === "paypal") {
      setShowPayPalButton(true);
    } else if (method.type === "stripe") {
      if (stripeError) {
        toast.error(stripeError);
        return;
      }
      setShowStripeForm(true);
    } else {
      toast.info(`${method.name} integration coming soon!`);
    }
  };

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id));
    toast.success("Item removed from cart");

    if (items.length === 1) {
      setShowPayPalButton(false);
      setShowStripeForm(false);
      setSelectedCard(null);
    }
  };

  const handlePlaceOrder = () => {
    if (items.length === 0) {
      toast.error("Your cart is empty");
      return;
    }
    if (subtotal === 0) {
      handleZeroAmountOrder();
      return;
    }

    if (selectedCard === null) {
      toast.error("Please select a payment method");
      return;
    }

    const method = paymentMethods[selectedCard];

    if (method.type === "paypal") {
      setShowPayPalButton(true);

      setTimeout(() => {
        if (paypalRef.current) {
          paypalRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else if (method.type === "stripe") {
      if (stripeError) {
        toast.error(stripeError);
        return;
      }

      setShowStripeForm(true);

      setTimeout(() => {
        document
          .querySelector(".stripe-form-container")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      toast.info(`${method.name} integration coming soon!`);
    }
  };

  const handleStripeError = (message) => {
    toast.error(message || "Payment failed. Please try again.");
  };

  const getItemPrice = (credits) => {
    return creditPricing[credits] || credits * 120;
  };

  const renderStripeForm = () => {
    if (!showStripeForm) return null;

    return (
      <div className="row p-3">
        <div className="col-12">
          <div className="card p-3 stripe-form-container">
            <h5 className="mb-3">Complete your payment with Card</h5>
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm
                items={items}
                subtotal={subtotal}
                onError={handleStripeError}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
              />
            </Elements>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      <TopCrumb route={"Cart"} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8 fsection p-0">
            {/* Only show payment methods if subtotal is NOT 0 */}
            {subtotal > 0 && (
              <div className="row p-3">
                {paymentMethods?.map((method, index) => (
                  <div key={index} className="col-md-3 col-sm-6 mb-4">
                    <div
                      className={`payment-box d-flex flex-column align-items-center justify-content-center ${
                        selectedCard === index ? "selected" : ""
                      }`}
                      onClick={() => handleCardClick(index)}
                      style={{
                        cursor: "pointer",
                        border:
                          selectedCard === index
                            ? "2px solid #007bff"
                            : "3px dashed #c6e9f5",
                        padding: "10px",
                        borderRadius: "8px",
                        boxShadow:
                          selectedCard === index
                            ? "0 4px 8px rgba(0, 123, 255, 0.5)"
                            : "none",
                      }}
                    >
                      <img
                        src={method.image}
                        alt={method.name}
                        className="img-fluid mb-2"
                        style={{ maxHeight: "50px" }}
                      />
                      <h6>{method.name}</h6>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* PayPal Button Container */}
            {showPayPalButton && (
              <div className="row p-3">
                <div className="col-12">
                  <div className="card p-3">
                    <h5 className="mb-3">Complete your payment with PayPal</h5>
                    <div
                      ref={paypalRef}
                      className="paypal-button-container"
                      style={{ minHeight: "150px" }}
                    ></div>
                    {!window.paypal && (
                      <div className="text-center py-3">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Loading PayPal...</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Stripe Form Container */}
            {renderStripeForm()}
            {items.length > 0 && (
              <div className="d-flex justify-content-between align-items-center mb-3 bottom-borde">
                <div className="d-flex gap-3 ms-3">
                  <i
                    className="bi bi-database-fill-add text-primary icon-bordered"
                    style={{ fontSize: "30px" }}
                  ></i>
                  <i
                    className="bi bi-credit-card-2-front text-primary icon2"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
                <button
                  className="btn btn-primary me-3"
                  onClick={handlePlaceOrder}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Processing..." : "Place Order"}
                </button>
              </div>
            )}
          </div>

          <div className="col-md-4 p-4 mt-2 ">
            <h5>My Shopping Cart</h5>

            {items.length === 0 ? (
              <div className="text-center py-4">
                <i
                  className="bi bi-cart text-muted"
                  style={{ fontSize: "60px" }}
                ></i>
                <p className="mt-3">Your cart is empty</p>
                <a href="/" className="btn btn-outline-primary mt-2">
                  Browse Services
                </a>
              </div>
            ) : (
              <>
                {isLoadingCredits && (
                  <div className="alert alert-info">
                    <div className="d-flex align-items-center">
                      <div
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}

                {items.map((item) => {
                  const totalCredits = Number(item.details.totalCredits) || 0;
                  const usedWalletCredits = item.usedWalletCredits || 0;
                  const payableCredits =
                    item.payableCredits !== undefined
                      ? Number(item.payableCredits)
                      : totalCredits;
                  const itemPrice = getItemPrice(payableCredits);

                  return (
                    <div
                      key={item.id}
                      className="d-flex justify-content-between mb-3"
                    >
                      <div className="d-flex gap-2">
                        <i
                          className="bi bi-database-fill-add text-primary "
                          style={{ fontSize: "60px" }}
                        ></i>
                        <div className="mt-auto">
                          <h3 className="mb-0">{item.quantity}</h3>
                          <p>{item.name}</p>
                          <div>
                            {usedWalletCredits > 0 ? (
                              <>
                                <span className="d-block">
                                  {totalCredits.toFixed(1)} Credits Total
                                </span>
                                <span className="d-block text-success">
                                  <i className="bi bi-wallet me-1"></i>
                                  {usedWalletCredits.toFixed(1)} Credits from
                                  Wallet
                                </span>
                                <span className="d-block">
                                  <strong>
                                    {payableCredits.toFixed(1)} Credits to
                                    Purchase
                                  </strong>
                                </span>
                              </>
                            ) : (
                              <span>{totalCredits.toFixed(1)} Credits</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end justify-content-center">
                        <span className="mb-2">€{itemPrice.toFixed(2)}</span>
                        <button
                          className="btn btn-link text-danger"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}

                <div className="table-divider mt-4"></div>

                <div className="d-flex justify-content-between mt-3">
                  <span className="text-muted">Subtotal</span>
                  <span>€{subtotal.toFixed(2)}</span>
                </div>

                <div className="d-flex justify-content-between">
                  <span className="text-muted">VAT (0%)</span>
                  <span>€0.00</span>
                </div>

                <div className="d-flex justify-content-between">
                  <span className="text-muted">Total</span>
                  <strong style={{ fontSize: "25px" }}>
                    €{subtotal.toFixed(2)}
                  </strong>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Cart;
