import React from 'react'
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/chiptuningbusiness.css"
import auto from "../../assets/images/auto.jpg"
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';
const chiptuningbusiness = () => {
  return (
    <>
      <section>
        <MainHeader />
      </section>
      <section className='chiptuning_business '>
        <section className='chiptuningbusiness_image '>
          <div className='container' >
            <div className='row'>
              <div className='col-12 my-5 m-auto'>
                <p className='starting_heading heading_responsive'>START A CHIPTUNING BUSINESS
                </p>
                <h1 className='starting_heading_1 heading_responsive_1'>THINKING OF STARTING A CHIPTUNING BUSINESS?
                </h1>
                <p className='starting_heading_2 heading_responsive_2'>Anyone can start chiptuning, certainly if you have the right partners who can support you at every stage. It doesn’t really matter whether you have experience in the automotive industry or not. It’s obviously an advantage if you do have experience.
                </p>
              </div>
            </div>
            
          </div>
        </section>
        <div className='container '>
          <div className='row'>
            <div className='col-6 m-auto no_experience'>
              <h1 className='starting_heading_1 mt-5 '><span className='starting_heading_3'>NO EXPERIENCE,</span> NO PROBLEM</h1>
            </div>
          </div>
          
        </div>
        <div className='container'>
          <div className='row starting_responsive'>
            <div className='col-6 my-5'>
              <img src={auto} alt="auto" width={500} height={550} className='box_imag_bussiness' />
            </div>
            <div className='col-6 my-5 starting_content'>
              <h1 className='starting_heading_1 my-5'><span className='starting_heading_3'>STARTING WITH</span> CHIPTUNING</h1>

              <h1 className='starting_heading_5 my-4'>Want to take up a new challenge in the chiptuning sector?
              </h1>
              <p className='starting_heading_4 my-4'>Don’t let lack of experience and expertise stop you! Pro-ChiptuningFiles and Tuning-Shop can assist you from A to Z in all aspects of chiptuning, from supplying the necessary ECU software, advising on business processes, the necessary equipment and tools, to quality controls.
              </p>
              <h1 className='starting_heading_5 my-4'>Assisting companies worldwide with high-quality tuning for more than 10 years

              </h1>
              <p className='starting_heading_4 my-4'>We also advise companies on their choice of necessary equipment through our online shop (Tuning-shop.com). Having acquired years of experience in our own chiptuning company, we know what needs to be done before starting and expanding a tuning business in the chiptuning industry.

              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row responsive_alientech'>
            <div className='col-4 mt-5 responsive_tab'>
              <img src={car} alt="car" width={500} height={300} className='box_image' />
            </div>
            <div className='col-8 my-5 responsive_tab_1'>
              {/* <form action="" className='from_car shadow-lg '>
                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                <div className='select_tab_alienech'>
                  <select name="" id="" className='Choose_tab'>
                    <option value="">Choose a make

                    </option>
                  </select>
                  <select name="" id="" className='Choose_tab'>
                    <option value="">Choose a model

                    </option>
                  </select>
                </div>
                <div className='select_tab_alienech responsive_choose'>
                  <select name="" id="" className='Choose_tab'>
                    <option value="">Choose a make

                    </option>
                  </select>
                  <select name="" id="" className='Choose_tab'>
                    <option value="">Choose a model

                    </option>
                  </select>
                </div>
                <button className='Start_Seaching my-3 shadow-lg ' href="/chiptuningfile">Start Seaching</button>
              </form> */}
              <ModelSearchFormSecond />
            </div>
          </div>
        </div>

      
      </section>

      <section>
        <MainFooter />
      </section>
    </>
  )
}

export default chiptuningbusiness