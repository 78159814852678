import React from "react";
const VehicleSummary = ({active, vehicleData ,vehicleInfoData}) => {
    if (!vehicleData) return null;
    const toTitleCase = (str) => {
      return str
        .toLowerCase()
        .split(/[\s-]/) 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
    };
    return (
      <div className="p-3">
        <h5 className="mb-3">Vehicle Information</h5>
  
              <h6>{toTitleCase(vehicleData.make === "otherwise,namely" ? vehicleData.other_make : vehicleData.make)} {toTitleCase(vehicleData.model === "otherwise,namely" ? vehicleData.other_model : vehicleData.model)}</h6>
              <p>{toTitleCase(vehicleData.generation === "otherwise,namely" ? vehicleData.other_generation : vehicleData.generation)} {toTitleCase(vehicleData.engine === "otherwise,namely" ? vehicleData.other_engine : vehicleData.engine)}</p>
            <tr>
              <th>ECU</th>
              <td>{toTitleCase(vehicleData.ecu === "otherwise,namely" ? vehicleData.other_ecu : vehicleData.ecu)}</td>
            </tr>

            <tr>
              <th>Gearbox</th>
              <td>{toTitleCase(vehicleData.gearbox)}</td>
            </tr>
            {(vehicleData.engine_hp || vehicleData.engine_kw) && (
  <tr>
    <th>Engine Power</th>
    <td>
      {vehicleData.engine_hp && `${toTitleCase(vehicleData.engine_hp)} HP`}
      {vehicleData.engine_hp && vehicleData.engine_kw && " / "}
      {vehicleData.engine_kw && `${toTitleCase(vehicleData.engine_kw)} kW`}
    </td>
  </tr>
)}

            {vehicleData.vin_number && (
              <tr>
                <th>VIN Number</th>
                <td>{toTitleCase(vehicleData.vin_number)}</td>
              </tr>
            )}
      
      </div>
    );
  };
  export default VehicleSummary