import React, { useState, useEffect } from 'react';
import ExtraInformation from '../../../ui/ExtraInformation';

const ExtraInfoForm = ({data, setData,errors}) => {
  const handleForm = (formData) => {
    setData({
      ...formData,
     
    });
  };

  return (
    <div className='row'>
      <form>
        <div>
          <span className='fw-semibold'></span>
          <div className='my-3'>
            <ExtraInformation 
              initialData={data}
              onChange={handleForm} 
              errors={errors}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExtraInfoForm;