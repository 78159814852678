import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authClient } from '../../../lib/client';
import { getOriVehicles, getSeries, getOriEngine, sendCarEngineData, EngineDetails } from '../../../api';
import MakeSelect from '../../ui/MakeSelect';
import ModelSelect from '../../ui/ModelSelect';
import EngineSelect from '../../ui/EngineSelect';
import { toast } from 'react-toastify';
import { useVehicleContext } from '../../home/vehicleContext';

const SearchModel = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [selectedIds, setSelectedIds] = useState({
    vehicle_id: '',
    model_id: '',
    engine_id: ''
  });
  const {
    setVehicles,
    setSeries,
    setEngines,
    setSelectedVehicle,
    setSelectedSeries,
    setSelectedEngine,
    setEcuData
  } = useVehicleContext();
  
  useEffect(() => {
    const token = localStorage.getItem('Access-Token');
    setAccessToken(token);
    getVehicles(token);
  }, []);

  const getVehicles = async (token) => {
    try {
      const response = await getOriVehicles(token);
      setVehiclesData(response);
      setVehicles(response);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      toast.error("Failed to load vehicles. Please try again.");
    }
  };

  const getLast5Chars = (str) => {
    if (!str || typeof str !== "string") return "";
    return str.length >= 5 ? str.slice(-5) : str;
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setSelectedIds(prev => ({ ...prev, [name]: value }));

    try {
      switch (name) {
        case 'vehicle_id':
          const modelResponse = await getSeries(value, accessToken);
          setModelData(modelResponse);
          setSeries(modelResponse);
          setEngineData([]);
          setSelectedIds(prev => ({
            ...prev,
            model_id: '',
            engine_id: ''
          }));
          break;

        case 'model_id':
          const engineResponse = await getOriEngine(value, accessToken);
          if (engineResponse?.status_code === 200 && Array.isArray(engineResponse?.data)) {
            setEngineData(engineResponse.data);
            setEngines(engineResponse.data);
          } else {
            console.error("Unexpected response structure:", engineResponse);
            setEngineData([]);
          }
          setSelectedIds(prev => ({
            ...prev,
            engine_id: ''
          }));
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Failed to load data. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    
    try {
      const selectedCar = selectedIds.vehicle_id;
      const selectedSeriesName = selectedIds.model_id;
      const selectedEngineName = selectedIds.engine_id;
      setSelectedVehicle(selectedCar);
      setSelectedSeries(selectedSeriesName);
      setSelectedEngine(selectedEngineName);
      const response = await sendCarEngineData(
        { car_name: selectedCar, series_name: selectedSeriesName, engine_name: selectedEngineName },
        accessToken
      );
      
      if (!response || response?.status_code === 404) {
        toast.error(response?.error || "Error: Not Found");
        return;
      }
      
      if (Array.isArray(response.data) && response.data.length > 0) {
        if (response.data.length > 1) {
          setEcuData(response.data);
          navigate('/tuning-files');
        } else {
          try {
            const fileSpec = response.data[0]?.all_fields?.file_specification || {};
            const fieldId = response.data[0]?.all_fields?.field_id;
            const fieldIdLast5 = getLast5Chars(fieldId);
            
            const payload = {
              ecu_manufacturer: fileSpec["ECU manufacturer"] || "",
              ecu_name: fileSpec["ECU name"] || "",
              ecu_prod_nr: fileSpec["ECU prod nr"] || "",
              hardware_nr: fileSpec["Hardware nr"] || "",
              software: fileSpec["Software"] || "",
              field_id: fieldIdLast5, 
            };
            
            const engineDetailsResponse = await EngineDetails(payload, accessToken);
            
            if (!engineDetailsResponse || engineDetailsResponse?.status_code === 404) {
              toast.error("Could not find data for the selected vehicle configuration");
              return;
            }
            
            const urlParams = [
              fileSpec["ECU manufacturer"],
              fileSpec["ECU name"],
              fileSpec["ECU prod nr"] 
                ? fileSpec["ECU prod nr"].replace(/#/g, "-hash-") 
                : "NA",
              fileSpec["Hardware nr"],
              fileSpec["Software"],
              fieldIdLast5, 
            ]
              .map((param) =>
                param
                  ? param.replace(/_/g, "-ud-").replace(/\//g, "-sh-")
                  : "NA"
              )
              .join("_");
            
            const finalUrl = `/tuning-ecu-files-database/${urlParams}`;
            navigate(finalUrl);
          } catch (engineError) {
            console.error("Error fetching engine details:", engineError);
            toast.error("An error occurred while fetching engine details. Please try again.");
          }
        }
      } else {
        toast.info("No ECU files found for the selected configuration.");
      }
    } catch (error) {
      console.error("Error in search process:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-3 mb-2">
        <MakeSelect
          value={selectedIds.vehicle_id}
          handleChange={handleChange}
          vehiclesData={vehiclesData}
        />
      </div>
      <div className="col-md-3 mb-2">
        <ModelSelect
          value={selectedIds.model_id}
          handleChange={handleChange}
          modelData={modelData}
        />
      </div>
      <div className="col-md-3 mb-2">
        <EngineSelect
          value={selectedIds.engine_id}
          handleChange={handleChange}
          engineData={engineData}
        />
      </div>
      <div className="col-md-3 mb-2">
        <Button 
          className="w-100 btn btn-light-info"
          onClick={handleSubmit}
          disabled={!selectedIds.engine_id || loading}
        >
          {loading ? "Searching..." : "Search"}
        </Button>
      </div>
    </div>
  );
};

export default SearchModel;