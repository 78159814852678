import React, { createContext, useState, useContext } from 'react';

const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedSeries, setSelectedSeries] = useState("");
  const [selectedEngine, setSelectedEngine] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [series, setSeries] = useState([]);
  const [engines, setEngines] = useState([]);
  const [ecuData, setEcuData] = useState([]);
 
  const contextValue = {
    selectedVehicle,
    setSelectedVehicle,
    selectedSeries,
    setSelectedSeries,
    selectedEngine,
    setSelectedEngine,
    vehicles,
    setVehicles,
    series,
    setSeries,
    engines,
    setEngines,
    ecuData,
    setEcuData
  };

  return (
    <VehicleContext.Provider value={contextValue}>
      {children}
    </VehicleContext.Provider>
  );
};
export const useVehicleContext = () => {
  const context = useContext(VehicleContext);
  if (!context) {
    throw new Error('useVehicleContext must be used within a VehicleProvider');
  }
  return context;
};