import React, { useState, useEffect } from "react";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import { Badge, Card, Form } from "react-bootstrap";
import { LineChart } from "@mui/x-charts";
import DataTable from "react-data-table-component";
import SearchInput from "../../ui/SearchInput";
import { getFormList, getFormData } from "../../../api";
import { useNavigate } from "react-router-dom";

const FileServices = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [query, setQuery] = useState("");
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fileId, setFileId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("Access-Token");
        const savedFileId = localStorage.getItem('fileId');
        
        if (!token) {
          window.location.href = "/login";
          return;
        }
          
        const response = await getFormList(token);
        console.log("🚀 ~ fetchData ~ response:", response)
        if (response?.status_code === 200 && response?.data?.length > 0) {
          const transformedData = response.data.map((item, index) => ({
            id: index,
            _id: item.all_fields._id, 
            make: item.make?.name || item.all_fields?.other_make || "Unknown Make",
            model: item.model?.name || item.all_fields?.other_model || "Unknown Model",
            generation: item.genration?.name || item.all_fields?.other_generation || "N/A",
            engine: item.engine?.name || item.all_fields?.other_engine || "N/A",
            year: item.all_fields?.year || "N/A",
            enginePower: item.all_fields?.engine_hp && item.all_fields?.kw 
              ? `${item.all_fields.engine_hp} HP / ${item.all_fields.kw} KW` 
              : "N/A",
            gearbox: item.all_fields?.garebox || "N/A",
            ecu: item.all_fields?.ecu || "N/A",
            vin: item.all_fields?.vin || "N/A",
            status: "Draft",
            desc: `${item.model?.name || item.all_fields?.other_model || ""} ${
              item.genration?.name || item.all_fields?.other_generation || ""
            } (${item.all_fields?.year || "N/A"})`,
            file: item.all_fields?.creation 
              ? new Date(item.all_fields.creation * 1000).toLocaleDateString("en-GB")
              : item.all_fields?.updated_at 
                ? new Date(item.all_fields.updated_at * 1000).toLocaleDateString("en-GB")
                : `File_${index + 1}`,
            original: item
          }));
          
          setFileData(transformedData);
        } else {
          setFileData([]);
        }
        
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
        console.error('Error fetching file service data:', err);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = async (row, e) => {
    e.stopPropagation(); // This prevents the row click from firing
    
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }
      
      const itemId = row._id;
      
      if (!itemId) {
        console.error("No item ID found for editing");
        return;
      }
      
      const payload = {
        _id: itemId
      };
      
      const formData = await getFormData(token, payload);
      console.log("Form data retrieved:", formData);
      
      if (formData && formData.data) {
        localStorage.setItem('editFormData', JSON.stringify(formData.data));
      }
      
      navigate("/new-file-services", { state: { editMode: true, formData: formData.data } });
      
    } catch (error) {
      console.error("Error fetching form data for editing:", error);
    }
  };

  const handleDeleteClick = (row, e) => {
    e.stopPropagation(); // Prevent row click when delete is clicked
    // Add your delete logic here
    console.log("Delete clicked for row:", row);
  };

  // Handle click on the vehicle name/details specifically
  const handleVehicleClick = (row, e) => {
    e.stopPropagation(); // Stop any other events
    setSelectedRow(row); // Set the selected row
    console.log("Vehicle column clicked:", row);
  };

  const columns = [
    {
      name: "Vehicle",
      cell: (row) => (
        <div 
          className="block py-2 cursor-pointer" 
          onClick={(e) => handleVehicleClick(row, e)}
          style={{ cursor: 'pointer' }}
        >
          <h6 className="vehicle_name">{row.make}</h6>
          <p className="vehicle_desc">{row.model} {row.generation} {row.engine} {row.year}</p>
        </div>
      ),
      sortable: true,
      width: "25%",
    },
    {
      name: "File",
      selector: (row) => row.file,
    },
    {
      name: "VIN",
      selector: (row) => row.vin,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge className="badge_design" bg="warning" text="dark">
          {row.status}
        </Badge>
      ),
    },
    {
      name: "Dynograph",
      selector: (row) => row.dynograph || "N/A",
    },
    {
      name: "Rate",
      selector: (row) => row.rate || "N/A",
    },
    {
      name: "",
      cell: (row) => (
        <div className="d-flex gap-2">
          <div 
            onClick={(e) => handleEditClick(row, e)} 
            role="button" 
            title="Edit File"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pen-fill action_icon"
              viewBox="0 0 16 16"
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
          <div
            onClick={(e) => handleDeleteClick(row, e)}
            role="button"
            title="Delete File"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash3-fill action_icon"
              viewBox="0 0 16 16"
            >
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
            </svg>
          </div>
        </div>
      ),
      button: true, // This makes sure the cell is treated as a button
    }
  ];

  const handleRowClick = (row) => {
    setSelectedRow(row);
    console.log("Row clicked:", row);
  };

  // Modified to handle cell clicks as well as row clicks
  const customRowStyles = {
    rows: {
      style: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    cells: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  return (
    <Wrapper>
      <TopCrumb route={"File Services"} />

      <div className="row my-1 p-3">
        <div className="col-md-4 p-2" style={{ height: "auto" }}>
          <Card className="p-2" style={{ height: "100%" }}>
            <Card.Body>
              <div className="Dashboard_heading_2">
                <h4 className="Dashboard_heading_1">New file services</h4>
                <svg
                  className="icon_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                </svg>
              </div>
              <div>
                <div className="d-flex gap-3">
                  <p className="mb-0">
                    <i className="bi bi-calendar2-minus"></i> 1d
                  </p>
                  <p className="mb-0">
                    <i className="bi bi-clock-history"></i> 1m
                  </p>
                </div>
                <LineChart
                  xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                  series={[
                    {
                      data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                  ]}
                  width={300}
                  height={200}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 p-2" style={{ height: "auto" }}>
          <Card className="p-2" style={{ height: "100%" }}>
            <Card.Body>
              <div className="Dashboard_heading_2">
                <h4 className="Dashboard_heading_1">File services</h4>
                <svg
                  className="icon_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                </svg>
              </div>{" "}
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  className="bi bi-file-earmark file_service_icon"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                </svg>
                <div className="mx-2">
                  <p className="mb-1">Total</p>
                  <p className="mb-1">{fileData.length || 0}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 p-2" style={{ height: "auto" }}>
          <Card className="p-2" style={{ height: "100%" }}>
            <Card.Body>
              <div className="Dashboard_heading_2">
                <h4 className="Dashboard_heading_1">Pending file services</h4>
                <svg
                  className="icon_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                </svg>
              </div>
              {loading ? (
                <p>Loading pending services...</p>
              ) : (
                <p className="no_pending">
                  {" "}
                  <i className="bi bi-box-seam-fill"></i> No pending file services.
                </p>
              )}
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-12 p-2">
          <div className="card">
            <div className="card-body">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <SearchInput query={query} setQuery={setQuery} />
                  </div>
                  <div className="col-md-4">
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Select>
                            <option value="">Search in all</option>
                            <option value="car">Search in vehicle</option>
                            <option value="display_id">Search in file</option>
                            <option value="vin_number">Search in VIN</option>
                          </Form.Select>
                        </div>
                        <div className="col-md-6">
                          <Form.Select className="col-md-5">
                            <option value="All">All</option>
                            <option value="Not downloaded">
                              Not downloaded
                            </option>
                          </Form.Select>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="col-md-4">
                    <div className="text-end">
                      <button 
                        className="btn btn-light-info" 
                        onClick={() => navigate("/new-file-services")}
                      >
                        New files services
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-2">
          <div className="row">
            <div className={`${selectedRow ? 'col-md-8' : 'col-md-12'}`}>
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <p>Loading data...</p>
                  ) : error ? (
                    <p className="text-danger">{error}</p>
                  ) : (
                    <DataTable
                      className="datatable"
                      columns={columns}
                      data={fileData}
                      onRowClicked={handleRowClick}
                      pagination
                      highlightOnHover
                      responsive
                      progressPending={loading}
                      pointerOnHover={true}
                      customStyles={customRowStyles}
                    />
                  )}
                </div>
              </div>
            </div>
            
            {selectedRow && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h6 className="vehicle_name">
                        {selectedRow.make} {selectedRow.model} 
                      </h6>
                      <button 
                        type="button" 
                        className="btn-close" 
                        aria-label="Close" 
                        onClick={() => setSelectedRow(null)}
                      ></button>
                    </div>
                    <p>{selectedRow.generation} {selectedRow.engine}</p>
                    <p>Engine power: <span>{selectedRow.enginePower}</span></p>
                    <p>Year: <span>{selectedRow.year}</span></p>
                    <p>Gearbox: <span>{selectedRow.gearbox}</span></p>
                    <p>ECU: <span>{selectedRow.ecu}</span></p>
                    <p>VIN: <span>{selectedRow.vin}</span></p>
                    <p>File: <span>{selectedRow.file}</span></p>
                    <p>Status: <span>{selectedRow.status}</span></p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FileServices;