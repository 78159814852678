import React, { useState } from 'react';
import "../../assets/css/dashboardhome.css"
import Dashboard from "../../Components/dashboard/dashboard"
import Navbar from '../../Components/dashboard/navbar';

const Dashboardhome = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row Dashboard_content_1">


                    <div>
                        <Dashboard />
                    </div>

                    <div className='Dashboard_content'>
                        <Navbar />
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque repudiandae natus dolores doloremque itaque illo vitae debitis ipsa veniam, expedita incidunt voluptates quas sint, mollitia nihil reiciendis dignissimos porro pariatur.
                    </div>


                </div>
            </div>
        </>
    )
}

export default Dashboardhome