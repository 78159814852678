import React, { useState } from "react";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import { Card } from "react-bootstrap";
import SearchModel from "./SearchModel";
import OrderSection from "./OrderSection";

const OriginalFiles = () => {
  const [filters, setFilters] = useState({
    make: "",
    model: "",
    engine: "",
  });

  const [data, setData] = useState([]);

  return (
    <Wrapper>
      <TopCrumb route={"Original files"} />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <Card className="p-4">
            <div className="Dashboard_heading_2">
              <h4 className="Dashboard_heading_1">
                Browse our Tuning files database
              </h4>
              <svg
                className="icon_arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
              </svg>
            </div>
            <div className="my-4">
              <SearchModel filters={filters} setFilters={setFilters} />
            </div>
          </Card>

          <div className="my-4">
            <Card className="p-4 mt-3">
              <div className="Dashboard_heading_2">
                <h4 className="Dashboard_heading_1">My Orders</h4>
                <svg
                  className="icon_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                </svg>
              </div>
              <OrderSection data={data} />
            </Card>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default OriginalFiles;
