import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

const CountrySelector = ({ value, onChange, error, register }) => {
  // List of countries to exclude
  const excludedCountries = [
    'Western Sahara', 
   'Wallis and Futuna',
  'Virgin Islands, U.S.',
  'Virgin Islands, British',
  'ALand Islands,',
  'American Samoa',
  'Antarctica',
  'Antigua and Barbuda',
  'Aruba',
  'Barbados',
  'Bermuda',
  'Bolivia, Plurinational State of',
  'Bonaire, Sint Eustatius and Saba',
  'Bouvet Island',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Cabo Verde',
  'Cayman Islands',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Congo',
  'Congo, Democratic Republic of the',
  'Cook Islands',
  'Curacao',
  'Czechia',
  'Dominica',
  'Equatorial Guinea',
  'Eritrea',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gibraltar',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guernsey',
  'Guinea-Bissau',
  'Guyana',
 'Heard Island and McDonald Islands',
 'Holy See',
 'Hong Kong',
 'Iran, Islamic Republic of',
 'Isle of Man',
 'Jersey',
 'Kiribati',
 'Korea, Democratic Peoples Republic of',
 'Korea, Republic of',
 'Kyrgyzstan',
 'Lesotho',
 'Liberia',
 'Liechtenstein',
 'Macao',
 'Malawi',
 'Marshall Islands',
 'Martinique',
 'Mauritania',
 'Mauritius',
 'Mayotte',
 'Micronesia, Federated States of',
 'Moldova, Republic of',
 'Montenegro',
 'Montserrat',
 'Nauru',
 'New Caledonia',
 'Niue',
 'Norfolk Island',
 'Northern Mariana Islands',
 'Palau',
 'Palestine, State of',
 'Pitcairn',
 'Puerto Rico',
 'Russian Federation',
 'Saint Helena, Ascension and Tristan da Cunha',
 'Saint Kitts and Nevis',
 'Saint Lucia',
 'Saint Pierre and Miquelon',
 'Saint Vincent and the Grenadines',
 'Saint Martin (French part)',
'Samoa',
'San Marino',
'Sao Tome and Principe',
'Seychelles',
'Sierra Leone',
'Sint Maarten (Dutch part)',
'Solomon Islands',
'Somalia',
'South Georgia and the South Sandwich Islands',
'South Sudan',
'Suriname',
'Svalbard and Jan Mayen',
'Syrian Arab Republic',
'Taiwan, Province of China',
'Tajikistan',
'Tanzania, United Republic of',
'Timor-Leste',
'Tokelau',
'Tonga',
'Trinidad and Tobago',
'Turkmenistan',
'Turks and Caicos Islands',
'Tuvalu',
'United States Minor Outlying Islands',
'Vanuatu',
'Venezuela, Bolivarian Republic of',
'Viet Nam'
];

  const options = useMemo(() => {
    return countryList().getData().filter(
      country => !excludedCountries.includes(country.label)
    );
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: error ? '1px solid red' : '1px solid #ced4da',
      borderRadius: '4px',
      minHeight: '45px',
      boxShadow: 'none',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid #ced4da'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : null,
      color: state.isSelected ? 'white' : 'black'
    })
  }

  // Hidden input for react-hook-form registration
  return (
    <div>
      <input
        type="hidden"
        {...register("country", { required: "Country is required" })}
        value={value?.label || ""}
      />
      <Select
        options={options}
        value={value}
        onChange={onChange}
        styles={customStyles}
        className="email_tab_choice"
        placeholder="Select a country"
      />
      {error && <p style={{color: "red", fontSize: "14px"}}>{error}</p>}
    </div>
  )
}

export default CountrySelector