import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

const ModelSelect = ({ value, handleChange, modelData }) => {
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/[\s-]/) 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };
    return (
      <Form.Select name='model_id' onChange={handleChange} value={value}>
        <option value="">Choose a model</option>
        {modelData?.map((model) => (
          <option key={model._id} value={model._id}>
            {toTitleCase(model.model_name)}
          </option>
        ))}
      </Form.Select>
    );
  };

export default ModelSelect