import React, { useState } from 'react'
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/prices.css"
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';

const Prices = () => {
    const [activeTab, setActiveTab] = useState('GROWING_NUMBERS');

    const toggleTab = (tabName) => {
        setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
    };

    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='prices_page'>
                <section className='price_image'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-8 price_responsive'>
                                <h1 className='prices_heading mt-5'>CHIPTUNING PRICES</h1>
                                <p className='prices_pragraph my-4'>The list below contains the tuning types and options we provide, along with the credit costs.
                                </p>
                                <p className='prices_pragraph_1'>
                                    Tuning tools and credits are available in two types: Slave and Master.
                                </p>
                                <ul className='prices_pragraph_1 my-2'>
                                    <li>Slave tools can read and write tuning files, but the files are encrypted. This means there is no option to open or adjust the tuning files.</li>
                                    <li>Master tools, on the other hand, allow file adjustments, as the files are not encrypted.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className='row price_responsive_row'>
                        <div className='col-3 my-5 price_credits'>
                            <div className='d-flex gap-3 '>
                                <div className='credit_score shadow-lg'>
                                    1
                                </div>
                                <h1 className='prices_pragraph_2 mt-1'>CREDITS</h1>

                            </div>
                            <p className='prices_pragraph_3 my-3'>Please select the credit type and amount. This impacts the price calculation for our products as the price per credit is reduced bases on the amout of credits bought.</p>
                            <div className='credit_line'></div>
                        </div>
                        <div className='col-9 my-5 price_credits'>
                            <div className='tab_growing_price'>
                                <div
                                    className={`price_pragraph_tab ${activeTab === 'GROWING_NUMBERS' ? 'active' : ''}`}
                                    onClick={() => toggleTab('GROWING_NUMBERS')}
                                >
                                    <h2 className='heading_price'> Master</h2>
                                    <p className='heading_price_pra'>Required for tuning tools with Master configuration.</p>
                                </div>
                                <div
                                    className={`price_pragraph_tab ${activeTab === 'CONTINUOUS_IMPROVEMENT' ? 'active' : ''}`}
                                    onClick={() => toggleTab('CONTINUOUS_IMPROVEMENT')}
                                >
                                    <h1 className='heading_price'>
                                        Slave</h1>
                                    <p className='heading_price_pra'>Required for tuning tools with Slave configuration.</p>
                                </div>
                                <div
                                    className={`price_pragraph_tab ${activeTab === 'WE_KEEP_DEVELOPING' ? 'active' : ''}`}
                                    onClick={() => toggleTab('WE_KEEP_DEVELOPING')}>
                                    <h2 className='heading_price'>EVC</h2>
                                    <p className='heading_price_pra'>Required for downloading tuning files from our EVC reseller database.
                                    </p>

                                </div>

                            </div>

                            {activeTab === 'GROWING_NUMBERS' && (
                                <div className='price_pragraph_euro'>
                                    <div className='price_pragraph_over'>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio shadow-lg' />
                                            <p className='master_price'>10</p>
                                            <p className='master_price_1'> €100.00 </p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>20</p>
                                            <p className='master_price_2'> €180.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €90.00</p>
                                            <p className='master_price_4 shadow-lg'>Save €1 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio shadow-lg' />
                                            <p className='master_price'>50</p>
                                            <p className='master_price_2'> €425.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €85.00</p>
                                            <p className='master_price_4 shadow-lg'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio shadow-lg' />
                                            <p className='master_price'>100</p>
                                            <p className='master_price_2'> €800.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4 shadow-lg'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio shadow-lg' />
                                            <p className='master_price'>250</p>
                                            <p className='master_price_2'> €2,000.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4 shadow-lg'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio shadow-lg' />
                                            <p className='master_price'>500</p>
                                            <p className='master_price_2'> €4,00.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4 shadow-lg'>Save €2 per credit</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'CONTINUOUS_IMPROVEMENT' && (
                                <div className='price_pragraph_euro'>
                                    <div className='price_pragraph_over'>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>0.5</p>
                                            <p className='master_price_1'> €40.00 </p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>1</p>
                                            <p className='master_price_2'> €80.00</p>
                                            <p className='master_price_3'>€80.00 per credit</p>
                                            <p className='master_price_4'>Save €1 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>2</p>
                                            <p className='master_price_2'> €160.00</p>
                                            <p className='master_price_3'>€80.00 per credit</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>5</p>
                                            <p className='master_price_2'> €375.00</p>
                                            <p className='master_price_3'>€80.00 per credit</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>10</p>
                                            <p className='master_price_2'> €700.00</p>
                                            <p className='master_price_3'>€80.00 per credit</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>50</p>
                                            <p className='master_price_2'> €4,00.00</p>
                                            <p className='master_price_3'>€80.00 per credit</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'WE_KEEP_DEVELOPING' && (
                                <div className='price_pragraph_euro'>
                                    <div className='price_pragraph_over'>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>10</p>
                                            <p className='master_price_1'> €100.00 </p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>20</p>
                                            <p className='master_price_2'> €180.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €90.00</p>
                                            <p className='master_price_4'>Save €1 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>50</p>
                                            <p className='master_price_2'> €425.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €85.00</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>100</p>
                                            <p className='master_price_2'> €800.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>250</p>
                                            <p className='master_price_2'> €2,000.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                        <div className='master_tab'>
                                            <input type="radio" name="" id="" className='master_radio' />
                                            <p className='master_price'>500</p>
                                            <p className='master_price_2'> €4,00.00</p>
                                            <p className='master_price_3'>10 OLSx credits for €80.00</p>
                                            <p className='master_price_4'>Save €2 per credit</p>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-3 mt-5 price_credits'>
                            <div className='d-flex gap-3 '>
                                <div className='credit_score shadow-lg'>
                                    2
                                </div>
                                <h1 className='prices_pragraph_2 mt-1'>PRODUCTS</h1>
                            </div>
                            <p className='prices_pragraph_3 my-3'>Please select the credit type and amount. This impacts the price calculation for our products as the price per credit is reduced bases on the amout of credits bought.</p>
                        </div>
                        <div className='col-9 my-5'>





                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg ' href="/chiptuningfile">Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>

        </>

    )
}

export default Prices