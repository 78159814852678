import React, { useState, useEffect } from "react";
import "../../assets/css/blog.css";
import { getNews } from "../../api";
import { Link } from "react-router-dom";
import MainHeader from "../header/Header";
import MainFooter from "../footer/Footer";
const AllNews = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const token = localStorage.getItem("Access-Token");
        const response = await getNews(token);
        if (response.status_code=200 && response.data) {
          setNews(response.data);
        } else {
          throw new Error(response.message || "Failed to retrieve news");
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const placeholderImage = "https://via.placeholder.com/400x250";

  const getImageUrl = (images) => {
    if (images && images.length > 0 && images[0].url) {
      return images[0].url;
    }
    return placeholderImage;
  };

  const getContentBeforeSeparator = (description) => {
    if (!description) return "";
    const parts = description.split("********************");
    return parts[0] || "";
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      return dateString;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading news: {error.message}</div>;
  }

  return (

    <section className="outer_blog">
       <section>
                <MainHeader />
            </section>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
        <div className="flex-grow-1 text-center">
          <h2 className="fw-bold m-0"> News</h2>
          </div>
    
      </div>
      <div className="container text-center">
        <div className="row align-items-start blog_responsive">
          {news?.map((newsItem) => (
            <div key={newsItem._id} className="col-3 blog_responsive_one">
              <div className="card">
                <div className="card-header">
                  <img
                    src={getImageUrl(newsItem.images)}
                    alt={newsItem.title}
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-2  w-100">
                    <span className="tag tag-teal">
                      {newsItem.news_type || "General"}
                    </span>
                    <small className="text-muted">
                      {formatDate(newsItem.create_date)}
                    </small>
                  </div>
                  <h4>{newsItem.title}</h4>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      getContentBeforeSeparator(newsItem.description)
                    )}
                  />
                  <div className="user">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/4/48/Outdoors-man-portrait_%28cropped%29.jpg"
                      alt="Author"
                    />
                    <div className="user-info">
                      <h5>Admin</h5>
                      <small>Recently</small>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Link
                      to={`/car-overview/${newsItem.slug}`}
                      className="btn btn-primary"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
            <section>
                <MainFooter />
            </section>
        </div>
      </div>
    </section>
  );
};

export default AllNews;
