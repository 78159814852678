import React, { useState } from 'react'
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import '../../assets/css/css_style.css'
import SearchInput from '../ui/SearchInput';

const Faq = () => {
    const [query, setQuery] = useState("");
    return (
        <>
            <section>
                <MainHeader />
            </section>

            <section className="container-fluid faq_main_section">
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <h2 className='text-light text-center'><strong>FREQUENTLY ASKED QUESTIONS</strong></h2>
                    </div>
                </div>

                <div className='container mt-5 mb-5'>
                    <div className="faq-search icon-md-before icon-search reveal">
                        <SearchInput className="form-control border " query={query} setQuery={setQuery} />
                        {/* <input className="form-control border bg-transparent" type="text" placeholder="Search for your question" /> */}
                    </div>
                </div>

                <div className='container'>
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Do you make chiptuning files for every car manufacturer?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Pro chiptuningfiles supplies chip tuning files for almost every car manufacturer. And if we don’t have the vehicle listed on our website, we certainly can find a solution for it. It doesn’t matter if it is a tuning file for a BMW or an Audi, we find a solution!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            What is an Tuning ECU file?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Tuning ECU files are the stock files that come with a vehicle from the factory. It is the stock ECU software from the manufacturer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Do you offer Tuning files?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Yes, we offer Tuning files which you can purchase on our website. You can find the complete database of Tuning ECU files here.   </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 text-light text-center'>
                            <div className='text-uppercase'>
                                <h3>Can't find the answer to your question?</h3>
                                <p className='mt-4'>We’re here to help! You can reach us at:</p>
                            </div>
                            <div className="contact-section text-center py-5">

                                <div className="buttons d-flex justify-content-center align-items-center gap-3">
                                    <a href="mailto:info@pro-chiptuningfiles.com" className="btn btn-primary butn">
                                        INFO@PRO-CHIPTUNINGFILES.COM
                                    </a>
                                    <a href="tel:+31358200967" className="btn btn-primary butn2">
                                        +31 (0) 35 8200967
                                    </a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default Faq
