import React from 'react'
import "../../assets/css/hour.css"
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';

const openinghours = () => {
    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='openinghours_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-8 m-auto my-5'>
                            <h1 className='opening_hours'>OPENING HOURS C.E.T. STANDARD FILE SERVICE</h1>

                            <div className='icon_arrow_hour my-3'>
                                <div className='icon_arrow_heading_hour mt-4'>
                                    <ul className='list_hour_page'>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Wednesday</li>
                                        <li>Thursday</li>
                                        <li>Friday</li>
                                        <li>Saturday</li>
                                        <li>Sunday</li>
                                    </ul>

                                </div>
                                <div className='icon_arrow_heading_hour'>
                                    <div className='heading_time_page'><strong>This week</strong> <div className='icon_i'>i</div></div>
                                    <ul className='list_hour_page'>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>closed</li>
                                    </ul>

                                </div>
                                <div className='icon_arrow_heading_hour'>
                                    <div className='heading_time_page'><strong>Next week </strong> <div className='icon_i'>i</div></div>
                                    <ul className='list_hour_page'>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>08:00 - 18:00</li>
                                        <li>closed</li>
                                    </ul>
                                </div>
                              
                            </div>
                            <h3 className='Opening_hours_link '>Opening hours "Full automatic file service" (winols reseller 24/7) Winols needed! <a href="/" className='read_more_hour'>Read more</a></h3>
                        </div>

                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech '>

                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make
                                        </option>
                                        <option value="">Choose a
                                        </option>
                                        <option value=""> a
                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model
                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make
                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model
                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg ' href="/chiptuningfile">Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default openinghours