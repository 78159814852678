import React, { useState, useEffect } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ExtraInfoForm = ({ initialData, onChange, errors }) => {
  const [formData, setFormData] = useState({
    time_frame: '',
    car_dyno: '',
    altitude_modify: '',
    addition_information_car: '',
    tac: "",
    refund_policy: "",
    ...initialData,
  });

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, ...initialData }));
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? (checked ? "yes" : "") : value;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: newValue };

      if (onChange) {
        onChange({
          ...updatedData,
          errors: { 
            ...(formData.errors || {}), 
            [name]: ""
          }
        });
      }

      return updatedData;
    });
  };

  return (
    <div className='row'>
      <div>
        <span className='fw-semibold'>Extra Information</span>
        <div className='my-3'>
          <div className="container mt-4 extra-info-form">
            <Form>
              {/* Time Frame Selection */}
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Group controlId="timeFrame">
                    <Form.Label>Please choose the required time frame for this file service</Form.Label>
                    <Form.Select 
                      name="time_frame"
                      value={formData.time_frame}
                      onChange={handleInputChange}
                      required
                      isInvalid={!!formData.errors?.time_frame}
                    >
                      <option value="">Make your choice</option>
                      <option value="Client waiting ASAP">Client waiting ASAP</option>
                      <option value="Max 2-3 hours">Max 2-3 hours (0.1 credits discount)</option>
                      <option value="Max 5-6 hours">Max 5-6 hours (0.1 credits discount)</option>
                      <option value="Next business day">Next business day (0.1 credits discount)</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formData.errors?.time_frame}
                    </Form.Control.Feedback>
                    <small className="text-muted">
                      Note: Don't need your file right away? Receive a discount!
                    </small>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="carDyno">
                    <Form.Label>Please let us know if the car is on a pro</Form.Label>
                    <Form.Select
                      name="car_dyno"
                      value={formData.car_dyno}
                      onChange={handleInputChange}
                    >
                      <option value="">Make your choice</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              {/* Altitude Modify with Tooltip */}
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Group controlId="altitudeModify">
                    <Form.Label>
                      Altitude to modify for 
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>If applicable, please let us know if we need to adjust the software to a certain altitude.</Tooltip>}
                      >
                        <i className="bi bi-info-circle ms-2" style={{ cursor: "pointer" }}></i>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control 
                      type="text" 
                      name="altitude_modify"
                      value={formData.altitude_modify}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </div>

              {/* Additional Information */}
              <Form.Group controlId="additionalInfo" className="mb-3">
                <Form.Label>Any additional information about the car (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="addition_information_car"
                  value={formData.addition_information_car}
                  onChange={handleInputChange}
                  placeholder="Please provide more information about your order..."
                />
              </Form.Group>

              {/* Terms and Conditions */}
              <h6>Terms and conditions</h6>
              <div className="mb-3">
                <Form.Group controlId="tac" className="position-relative">
                  <Form.Check
                    type="checkbox"
                    name="tac"
                    checked={formData.tac === "yes"} 
                    onChange={handleInputChange}
                    isInvalid={!!formData.errors?.tac}
                    label={<span>Yes, I agree to the <a href="#">terms and conditions</a>.</span>}
                  />
                  {formData.errors?.tac && (
                    <div className="invalid-feedback d-block">
                      {formData.errors.tac}
                    </div>
                  )}
                </Form.Group>
              </div>

              <div className="mb-3">
                <Form.Group controlId="refund_policy" className="position-relative">
                  <Form.Check
                    type="checkbox"
                    name="refund_policy"
                    checked={formData.refund_policy === "yes"}
                    onChange={handleInputChange}
                    isInvalid={!!formData.errors?.refund_policy}
                    label={<span>Yes, I agree to the <a href="#">no refund policy</a>.</span>}
                  />
                  {formData.errors?.refund_policy && (
                    <div className="invalid-feedback d-block">
                      {formData.errors.refund_policy}
                    </div>
                  )}
                </Form.Group>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfoForm;
