

// NewServiceForm.js
import React from 'react'
import { useEffect } from 'react';
import ModifiedService from '../../../ui/ModifiedService'
import { toast} from 'react-toastify';
import { submitModifiedParts } from '../../../../api';
const NewServiceForm = ({ data, setData }) => {
  const handleModificationChange = (modificationData) => {
    if (JSON.stringify(data) !== JSON.stringify(modificationData)) {
      setData(modificationData);
    }
  };
  return (
    <div className='row'>
      <form>
        <div>
          <span className='fw-semibold'>New file service</span>
          <div className='my-3'>
            <ModifiedService 
              initialData={data}
              onChange={handleModificationChange}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewServiceForm