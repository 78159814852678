import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../header/Header";
import MainFooter from "../footer/Footer";
import { Link } from "react-router-dom";
import { EngineDetails } from "../../api";
import { useVehicleContext } from "./vehicleContext";
import { Toast } from "react-bootstrap";
const OriginalTuningFile = () => {
  const { slug: fullParams } = useParams();
  const navigate = useNavigate();
  const { selectedVehicle, selectedSeries, selectedEngine } =
    useVehicleContext();

  const [
    ecuManufacturer,
    ecuName,
    ecuProdNr,
    hardwareNr,
    software,
    fieldIdSuffix,
  ] = (fullParams || "").split("_");

  const [carData, setCarData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const payload = {
          ecu_manufacturer:
            ecuManufacturer?.replace(/-sh-/g, "/").replace(/-ud-/g, "_") || "",
          ecu_name: ecuName?.replace(/-sh-/g, "/").replace(/-ud-/g, "_") || "",
          ecu_prod_nr:
            ecuProdNr
              ?.replace(/-sh-/g, "/")
              .replace(/-ud-/g, "_")
              .replace(/-hash-/g, "#") || "", // Decode # from -hash-
          hardware_nr:
            hardwareNr?.replace(/-sh-/g, "/").replace(/-ud-/g, "_") || "",
          software: software?.replace(/-sh-/g, "/").replace(/-ud-/g, "_") || "",
          field_id: fieldIdSuffix || "",
        };

        const response = await EngineDetails(payload);
        if (response?.status === 404) {
          Toast.error("Data could not be found");
        } else {
          setCarData(response?.data[0]);
        }
      } catch (err) {
        if (err?.response?.status === 404) {
          setError("Data could not be found");
        } else {
          setError("Failed to load car data");
        }
        console.error("Error fetching car engine data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCarData();
  }, [
    ecuManufacturer,
    ecuName,
    ecuProdNr,
    hardwareNr,
    software,
    fieldIdSuffix,
  ]);

  const fileSpec = carData.file_specification || {};

  useEffect(() => {
    if (!loading && error) {
      setTimeout(() => {
        navigate("/tuning-files");
      }, 3000);
    }
  }, [loading, error, navigate]);

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/tuning-files");
  };

  return (
    <>
      <MainHeader />
      <div className="container-fluid text-light main-cont backgroung-img">
        <div className="row">
          <div className="col-md-12 headsec">
            <h3 className="heading-ori"> Tuning File</h3>
            <h1 className="bosch">
              {`${fileSpec["ECU manufacturer"] || ecuManufacturer} 
                ${fileSpec["ECU name"] || ecuName} 
                ${fileSpec["ECU prod nr"] || ecuProdNr} 
                ${fileSpec["Hardware nr"] || hardwareNr} 
                ${fileSpec["Software"] || software}`}
            </h1>
          </div>
        </div>
      </div>

      <div className="container-fluid text-light main-cont py-5">
        <div className="row">
          <div className="col-md-8 headsec">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-danger">{error}</p>
            ) : (
              <>
                <h4 className="heading-text">Description</h4>
                <div className="content">
                  <p>{carData.description?.replace(/dyno/gi, "pro")}</p>
                  <p>
                    Didn't find what you are looking for? Take a look in our
                    huge
                    <Link to="/tuning-files">
                      {" "}
                      ECU tuning and tuning files database{" "}
                    </Link>{" "}
                    to find the tuning file that you need.
                  </p>
                </div>
                <h2>Price</h2>
                <h1>{carData.price}</h1>
                <p>Excl. VAT</p>
              </>
            )}
          </div>

          <div className="col-md-4 headsec mt-5">
            <h4>Purchase this file</h4>
            <p>
              Please login or create an account if you would like to purchase
              this file.
            </p>
            <button className="btn btn--full btn--search w-100 mb-2">
              Register now
            </button>
            <p className="mb-0 text-center">
              <Link to="/login" className="text-light">
                Already have an account?
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid text-light main-cont py-4">
        <div className="row">
          <div className="col-md-8 headsec">
            <p className="heading-ori">File specifications: {fileSpec.Car}</p>
            <table className="original-table" width="100%">
              <tbody>
                <tr>
                  <th>Car</th>
                  <td>{fileSpec.Car}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{fileSpec.Type}</td>
                </tr>
                <tr>
                  <th>Model year</th>
                  <td>{fileSpec["Model year"]}</td>
                </tr>
                <tr>
                  <th>Displacement</th>
                  <td>{fileSpec.Displacement}</td>
                </tr>
                <tr>
                  <th>Output</th>
                  <td>{fileSpec.Output}</td>
                </tr>
                <tr>
                  <th>Gear</th>
                  <td>{fileSpec.Gear}</td>
                </tr>
                <tr>
                  <th>ECU manufacturer</th>
                  <td>{fileSpec["ECU manufacturer"]}</td>
                </tr>
                <tr>
                  <th>ECU name</th>
                  <td>{fileSpec["ECU name"]}</td>
                </tr>
                <tr>
                  <th>ECU prod nr</th>
                  <td>{fileSpec["ECU prod nr"]}</td>
                </tr>
                <tr>
                  <th>Hardware nr</th>
                  <td>{fileSpec["Hardware nr"]}</td>
                </tr>
                <tr>
                  <th>Software</th>
                  <td>{fileSpec.Software}</td>
                </tr>
                <tr>
                  <th>Software version</th>
                  <td>{fileSpec["Software version"]}</td>
                </tr>
                <tr>
                  <th>Software size</th>
                  <td>{fileSpec["Software size"]}</td>
                </tr>
                <tr>
                  <th>Project type</th>
                  <td>{fileSpec["Project type"]}</td>
                </tr>
                <tr>
                  <th>Read hardware</th>
                  <td>{fileSpec["Read hardware"]}</td>
                </tr>
                <tr>
                  <th>8 bit sum</th>
                  <td>{fileSpec["8 bit sum"]}</td>
                </tr>
              </tbody>
            </table>
            <div className="mt-5">
              <a href="#" onClick={handleBackClick}>
                &lt; Back to overview
              </a>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default OriginalTuningFile;
