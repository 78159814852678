import React, { useEffect, useState } from "react";
import newlogo from "../../assets/images/logo-white.png";
import "../header/Header.css";
import { Link } from "react-router-dom";

const MainHeader = () => {

  const [AccessToken, setAccessToken] = useState('')

  useEffect(() =>{
    const access_token = localStorage.getItem('Access-Token');
    setAccessToken(access_token);
  }, [])

  return (
    <>
      {/* Main Header */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          {/* Logo Section */}
          <Link
            className="navbar-brand d-flex align-items-center new-nav"
            to="/"
          >
            <img
              src={newlogo}
              alt="Logo"
              height="60"
              className="me-2 box_image_logo"
            />
          </Link>

          {/* Toggle Button for Mobile */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navigation Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="bi bi-youtube"></i>
                </a>
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/chiptuning-files">

                  <i className="bi bi-search"></i> Search in Car Database
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={AccessToken ?"/editprofile" : "/login" }>
                  <i className="bi bi-person"></i> Account
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cart">
                  <i className="bi bi-cart"></i> Cart
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Secondary Navbar */}
      <nav className="navbar navbar-expand-lg navbar-secondary bg-secondary">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#secondaryNav"
            aria-controls="secondaryNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="secondaryNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="chiptuningToolsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Chiptuning Tools
                </a>
                <ul
                  className="dropdown-menu shadow-lg"
                  aria-labelledby="chiptuningToolsDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/alientechtools">
                      Alientechtools
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/cmdflashtool">
                      CMD-Flash Tools
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/magicmotorsport">
                      MAGICMOTORSPORT
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/autotuner">
                      Autotuner
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="chiptuningFilesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Chiptuning Files
                </a>
                <ul
                  className="dropdown-menu shadow-lg"
                  aria-labelledby="chiptuningFilesDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/chiptuningfile">
                      chiptuningfile
                    </a>
                  </li>
                  <li>
                    {/* <a className="dropdown-item" href="/Original">
                      Ori Files 
                    </a> */}
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/prices">
                  Prices
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/projects">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/aboutus">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/chiptuningbusiness">
                  Starting a Chiptuning Business
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="supportDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Support
                </a>
                <ul
                  className="dropdown-menu shadow-lg"
                  aria-labelledby="supportDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/openinghours">
                      Opening-hours
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Support 2
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="languageDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  English
                </a>
                <ul
                  className="dropdown-menu shadow-lg"
                  aria-labelledby="languageDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/">
                      English
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Spanish
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainHeader;
