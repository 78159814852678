import React from 'react'
import Wrapper from './Wrapper'
import { Button, Card, Form } from 'react-bootstrap'
import TopCrumb from '../header/TopCrumb'

const ExportInvoice = () => {
  const [period, setPeriod] = React.useState('');
  return (
    <Wrapper>
      <TopCrumb route={"Export Invoice"} />
      <div className='my-4'>
        <Card className='p-4'>
          <span>Download all invoices (and credit invoices) from the selected period.</span>
          <div className='my-4 row'>
            <div className="col-md-4 col-lg-4">
              <label htmlFor='period'>Period</label>
              <Form.Select value={period} onChange={(e) => setPeriod(e.target.value)} >
                <option value=''>Select period</option>
                <option value='1'>Last 7 days</option>
                <option value='2'>Last 30 days</option>
                <option value='3'>Last 90 days</option>
                <option value='4'>Last 180 days</option>
                <option value='5'>Last 365 days</option>
              </Form.Select>
            </div>

          </div>
          <div>
            <Button>Export</Button>
          </div>
        </Card>
      </div>
    </Wrapper>
  )
}

export default ExportInvoice