import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";

const AdditionalTuningForm = ({data, setData,fileId}) => {

  const tuning_options = [
    {
      id:1,
      label: "AdBlue (+1 credit)",
      tooltip: "This option is not available for the selected engine."
    },
    {
      id:2,
      label: "Anti Lag (+1 credit)",
      tooltip: "Anti-lag system (ALS) or misfiring system is a system used on turbocharged engines to minimize turbo lag."
    },
    {
      id:3,
      label: "Cat Heating (+0.2 credits)",
      tooltip: "Cat heating - to stop the long cat heating."
    },
    {
      id:4,
      label: "Cylinder on Demand off (+0.2 credits)",
      tooltip: "Cylinder on Demand off"
    },
    {
      id:5,
      label: "Decat / O2 (free)",
      tooltip: "Removal of catalyst, always write down DTC codes if present in ECU. Used to remove O2 codes."
    },
    {
      id:6,
      label: "DPF off (free)",
      tooltip: "Removal of DPF, always write down DTC codes if present in ECU."
    },
    {
      id:7,
      label: "DSG Farts (+0.5 credits)",
      tooltip: "Programming DSG farts."
    },
    {
      id:8,
      label: "DTC off (+0.2 credits)",
      tooltip: "When you have P0420 this is decat not DTC off."
    },
    {
      id:9,
      label: "E85 Conversion (+2 credits)",
      tooltip: "This option is not available for the selected engine."
    },
    {
      id:10,
      label: "EGR off (free)",
      tooltip: "Removal of EGR, always write down DTC codes if present in ECU."
    },
    {
      id:11,
      label: "Evaporative Emission Control System (+0.2 credits)",
      tooltip: "Fuel tank vent."
    },
    {
      id:12,
      label: "Exhaust Flaps (+0.2 credits)",
      tooltip: "Reprogram the exhaust flaps."
    },
    {
      id:13,
      label: "Hard Cut limiter (diesel) (+0.5 credits)",
      tooltip: "Hard Cut limiter (diesel)."
    },
    {
      id:14,
      label: "Hot Start (+0.2 credits)",
      tooltip: "Hot start modification, so vehicle starts better with warm engine."
    },
    {
      id:15,
      label: "Idle RPM (+0.2 credits)",
      tooltip: "This option is not available for the selected engine."
    },
    {
      id:16,
      label: "Immo off (+1 credit)",
      tooltip: "Please always send a full backup, flash, mps, serial."
    },
    {
      id:17,
      label: "Injector scaling (+0.7 credits)",
      tooltip: "Injector scaling, when you have changed original injectors for others. Specify what you have changed from original size to new size (cc/min)."
    },
    {
      id:18,
      label: "Launch Control (+0.5 credits)",
      tooltip: "Launch Control is an electronic aid to assist drivers to accelerate rapidly from a standing start"
    },
    {
      id:19,
      label: "Limited Vmax to specific speed (+0.5 credits)",
      tooltip: "When you need to have the Vmax limited to a specified speed, the Vmax will not be removed completely."
    },
    {
      id:20,
      label: "MAF off (if possible) (+0.2 credits)",
      tooltip: "Mass Air Flow sensor removal (if possible) write down DTC codes if present in ECU."
    },
    {
      id:21,
      label: "Neutral RPM (+0.2 credits)",
      tooltip: "Adjustment of the rev limiter when idling."
    },
    {
      id:22,
      label: "NOx off (+0.5 credits)",
      tooltip: "Removal of NOx, always write down DTC codes if present in ECU."
    },
    {
      id:23,
      label: "OPF off (free)",
      tooltip: "Removal of OPF, always write down DTC codes if present in ECU."
    },
    {
      id:24,
      label: "Performance Gauge BMW/Mini/VAG (free)",
      tooltip: "Performance Gauge BMW/Mini/VAG."
    },
    {
      id:25,
      label: "Pop & Bang / Crackle map (+1 credit)",
      tooltip: "The burble on overrun is usually caused by ignition timing being retarded and a fuel cut."
    },
    {
      id:26,
      label: "Readiness Monitor (+0.5 credits)",
      tooltip: null
    },
    {
      id:27,
      label: "Rev Limiter (free)",
      tooltip: "Change of Rev Limiter."
    },
    {
      id:28,
      label: "Secundairy Air Pump (+0.2 credits)",
      tooltip: "Air injection exhaust system for emission purposes."
    },
    {
      id:29,
      label: "Start / Stop system off (+0.2 credits)",
      tooltip: "Removal of Start / Stop system, always write down DTC codes if present in ECU."
    },
    {
      id:30,
      label: "Swirl Flaps off (+0.2 credits)",
      tooltip: "Removal of Swirl Flaps, always write down DTC codes if present in ECU."
    },
    {
      id:31,
      label: "Torque Monitoring off (free)",
      tooltip: null
    },
    {
      id:32,
      label: "Vmax off (free)",
      tooltip: "Vmax Removal, if possible write down current Vmax"
    },
    {
      id:33,
      label: "Warranty Patch (BMW/Mini/VAG) (+0.5 credits)",
      tooltip: "Warranty Patch (BMW/Mini/VAG). Software modification to keep warranty after remapping ECU."
    }
  ]

  const handleSelections = (e) => {
    const value = parseInt(e.target.value);
    let array = [...data]; 
    
    if(array.includes(value)){
      const index = array.indexOf(value);
      array.splice(index, 1);
    } else {
      array.push(value);
    }
    setData(array);
  };

  return (
    <div>
     <form onSubmit={(e) => e.preventDefault()}>
        <div>
          <span className='fw-semibold'>Additional tuning options</span>
          <div className='mt-3'>
            <div className='row row-gap-2'>
              {
                tuning_options?.map((option, index) => (
                  <div key={index} className='form-check col-md-6 col-lg-6'>
                    <input
                      type='checkbox'
                      className='form-check-input p-2 border'
                      value={option.id}
                      onChange={handleSelections}
                      id={option.label}
                      defaultChecked={data.includes(option.id)}
                    />
                    <label className='form-check-label fs14' htmlFor={option.label}>
                      {option.label}
                      {
                        option.tooltip && (
                          <i className='ms-1 bi bi-info-circle-fill text-secondary' data-tooltip-id={`option-${index}`}></i>
                        )
                      }
                    </label>
                    <ReactTooltip
                      id={`option-${index}`}
                      place="top"
                      content={option.tooltip}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdditionalTuningForm