import React from "react";
import { Form } from "react-bootstrap";
import SearchInput from "../../ui/SearchInput";
import { Link } from "react-router-dom";

const OrderSection = ({ data, title }) => {
  const [query, setQuery] = React.useState("");
  const [category, setCategory] = React.useState("");

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <SearchInput query={query} setQuery={setQuery} />
        </div>
        <div className="col-md-2">
          <Form.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select {title ? "in all" : "a category"} </option>
            {data?.map((item) => (
              <option key={item.id} value={item.category}>
                {item.category}
              </option>
            ))}
          </Form.Select>
        </div>
        {title && (
          <div className="col-md-6 text-end">
            <Link to="/add-subscriptions">
              <button className="subscription_butn btn btn-light-info">
                Add subscription
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="mt-3">
        <span className="no-data">No Data Found</span>
      </div>
    </>
  );
};

export default OrderSection;
