import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import clsx from "clsx";
import { authClient } from "../../../../lib/client";

const VehicleInfoForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [vehiclesData, setVehiclesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [generationData, setGenerationData] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [ecuData, setEcuData] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPetrolSelected, setIsPetrolSelected] = useState(false);
  const vehicleTypeOptions = [
    { value: "Car", label: "Car" },
    { value: "Bike", label: "Bike" },
    { value: "Boat", label: "Boat" },
  ];
  const octaneRatingOptions = [
    { value: "91AKI/95RON", label: "91AKI/95RON " },
    { value: "93AKI/98RON", label:"93AKI/98RON" },
    { value: "95AKI/102RON", label: "95AKI/102RON" },
    { value: "Racegas 100+RON/105+RON", label: "Racegas 100+RON/105+RON" },
  ];
  const gearboxOptions = [
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "automatic", label: "Automatic Transmission" },
    { value: "cvt", label: "CVT" },
    { value: "dsg", label: "DSG" },
    { value: "dsg6", label: "DSG6" },
    { value: "dsg7", label: "DSG7" },
    { value: "dkg", label: "DKG" },
    { value: "dct", label: "DCT" },
    { value: "smg", label: "SMG" },
    { value: "smg2", label: "SMG2" },
    { value: "smg3", label: "SMG3" },
    { value: "tiptronic", label: "Tiptronic" },
    { value: "multitronic", label: "Multitronic" },
  ];
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/[\s-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(
        /\b(\w+)-(\w+)\b/g,
        (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`
      );
  };

  const initializeFormData = async () => {
    if (vehiclesData.length === 0) {
      const access_token = localStorage.getItem("Access-Token");
      setAccessToken(access_token);
      await getVehicles(access_token);
    }
    if (values.make) {
      if (values.make === "otherwise,namely") {
        setModelData([
          { _id: "otherwise,namely", model_name: "Otherwise,namely" },
        ]);
        setGenerationData([
          { _id: "otherwise,namely", generation_name: "Otherwise,Namely" },
        ]);
      } else {
        try {
          const response = await authClient.GetFormModels(values.make, accessToken);
          const models = [
            ...response.data.map((model) => ({
              ...model,
              model_name: toTitleCase(model.model_name),
            })),
            { _id: "otherwise,namely", model_name: "Otherwise,namely" },
          ];
          setModelData(models);
          if (values.model) {
            if (values.model === "otherwise,namely") {
              setGenerationData([
                {
                  _id: "otherwise,namely",
                  generation_name: "Otherwise,Namely",
                },
              ]);
            } else {
              const genResponse = await authClient.GetFormModelGeneration(
                values.model,
                accessToken
              );
              const generations = [
                ...genResponse.data.map((generation) => ({
                  ...generation,
                  generation_name: toTitleCase(generation.generation_name),
                })),
                {
                  _id: "otherwise,namely",
                  generation_name: "Otherwise,Namely",
                },
              ];
              setGenerationData(generations);

              if (values.generation) {
                if (values.generation === "otherwise,namely") {
      
                } else {
                  const engineResponse =
                    await authClient.GetFormModelGenerationEngine(
                      values.generation,
                      accessToken
                    );
                  setEngineData(engineResponse.data.data);
      
                  if (values.engine && engineResponse.data.data.petrol) {
                    const isPetrol = engineResponse.data.data.petrol.some(
                      engine => engine._id === values.engine
                    );
                    setIsPetrolSelected(isPetrol);

                    if (values.engine && values.engine !== "otherwise,namely") {
                      await fetchEcuData(values.engine);
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error("Error initializing form data:", error);
        }
      }
    }
  };

  useEffect(() => {
    initializeFormData();
  }, []);
  
  useEffect(() => {
    if (values.generation && generationData.length > 0) {
      const isValidGeneration = generationData.some(
        (g) => g._id === values.generation
      );
      if (!isValidGeneration) {
        setFieldValue("generation", "");
      }
    }
  }, [generationData]);

  const getVehicles = async (token) => {
    try {
      const response = await authClient.GetFormVehicles(token);
      setVehiclesData(response.data)
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const fetchEcuData = async (engineId) => {
    try {
      const response = await authClient.GetEngineEcu(engineId, accessToken);
      if (response?.data?.data) {
        setEcuData(response.data.data);
      } else {
        setEcuData({});
      }
    } catch (error) {
      console.error("Error fetching ECU data:", error);
      setEcuData({});
    }
  };

  const handleChangeCar = async (event) => {
    const vehicleId = event.target.value;
    setFieldValue("make", vehicleId);
    setFieldValue("model", "");
    setFieldValue("generation", "");
    setFieldValue("engine", "");
    setFieldValue("ecu", "");

    if (vehicleId === "otherwise,namely") {
      setModelData([
        { _id: "otherwise,namely", model_name: "Otherwise,namely" },
      ]);
      setGenerationData([]);
      setEngineData([]);
      setEcuData({});
      return;
    }

    try {
      const response = await authClient.GetFormModels(vehicleId, accessToken);
      setModelData(response.data)
      setGenerationData([]);
      setEngineData([]);
      setEcuData({});
    } catch (error) {
      console.error("Error fetching models:", error);
      setModelData([]);
    }
  };

  const handleChangeCarModal = async (event) => {
    const modelId = event.target.value;
    setFieldValue("model", modelId);
    setFieldValue("generation", "");
    setFieldValue("engine", "");
    setFieldValue("ecu", "");


    try {
      const response = await authClient.GetFormModelGeneration(
        modelId,
        accessToken
      );
      setGenerationData(response.data)
      setEngineData([]);
      setEcuData({});
    } catch (error) {
      console.error("Error fetching generations:", error);
      setGenerationData([]);
    }
  };

  const handleChangeCarGeneration = async (event) => {
    const generationId = event.target.value;
    setFieldValue("generation", generationId);
    setFieldValue("engine", "");
    setFieldValue("ecu", "");

    if (generationId === "otherwise,namely") {
      setEngineData([
        { _id: "otherwise,namely", engine_name: "Otherwise,Namely" },
      ]);
      setEcuData({});
      return;
    }

    try {
      const response = await authClient.GetFormModelGenerationEngine(
        generationId,
        accessToken
      );

      setEngineData(response.data.data);
      setEcuData({});
    } catch (error) {
      console.error("Error fetching engines:", error);
      setEngineData([]);
    }
  };
  
  const handleEngineChange = async (e) => {
    const engineId = e.target.value;
    setFieldValue("engine", engineId);
    setFieldValue("ecu", "");
    
    if (engineData?.petrol?.some(engine => engine._id === engineId)) {
      setIsPetrolSelected(true);
    } else {
      setIsPetrolSelected(false);
      setFieldValue("octane_rating", "");
    }
    
    await fetchEcuData(engineId);
  };


  return (
    <div className="row">
      <form className="col-md-12 col-lg-12">
        <div>
          <span className="fw-semibold">
            <i className="bi bi-pencil-square"></i> Vehicle Information
          </span>
          <div className="row mt-3 mb-5 row-gap-2">
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="make">
                Make
              </Form.Label>
              <Form.Select
                id="make"
                name="make"
                value={values.make}
                onChange={handleChangeCar}
                className={clsx("", {
                  "is-invalid": touched.make && errors.make,
                })}
              >
                <option value="">Choose an option</option>
                {vehiclesData?.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {toTitleCase(vehicle.car_name)}
                  </option>
                ))}
                 <option value="otherwise,namely">Otherwise,Namely</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.make}
              </Form.Control.Feedback>
            </div>

            {values.make === "otherwise,namely" && (
              <>
                <div className="col-md-3 col-lg-3">
                  <Form.Label className="fs14" htmlFor="vehicle_type">
                    Vehicle Type
                  </Form.Label>
                  <Form.Select
                    id="vehicle_type"
                    name="vehicle_type"
                    value={values.vehicle_type}
                    onChange={handleChange}
                    className={clsx("", {
                      "is-invalid": touched.vehicle_type && errors.vehicle_type,
                    })}
                  >
                    <option value="">Choose a vehicle type</option>
                    {vehicleTypeOptions.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.vehicle_type}
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-3 col-lg-3">
                  <Form.Label className="fs14" htmlFor="other_make">
                    Make
                  </Form.Label>
                  <Form.Control
                    id="other_make"
                    name="other_make"
                    value={values.other_make}
                    onChange={handleChange}
                    placeholder="Specify make"
                    className={clsx("", {
                      "is-invalid": touched.other_make && errors.other_make,
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.other_make}
                  </Form.Control.Feedback>
                </div>
              </>
            )}
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="model">
                Model
              </Form.Label>
              <Form.Select
                id="model"
                name="model"
                onChange={handleChangeCarModal}
                value={values.model}
                disabled={!values.make}
                className={clsx("", {
                  "is-invalid": touched.model && errors.model,
                })}
              >

                <option value="">Choose an option</option>
                {modelData?.map((model) => (
                  <option key={model._id} value={model._id}>
                   {toTitleCase(model.model_name)}
                  </option>
                ))}
                
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.model}
              </Form.Control.Feedback>
            </div>

            {values.model === "otherwise,namely" && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className="fs14" htmlFor="other_model">
                  Model
                </Form.Label>
                <Form.Control
                  id="other_model"
                  name="other_model"
                  value={values.other_model}
                  onChange={handleChange}
                  placeholder="Specify model"
                  className={clsx("", {
                    "is-invalid": touched.other_model && errors.other_model,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.other_model}
                </Form.Control.Feedback>
              </div>
            )}

            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="generation">
                Generation
              </Form.Label>
              <Form.Select
                id="generation"
                name="generation"
                onChange={handleChangeCarGeneration}
                value={values.generation}
                disabled={!values.model}
                className={clsx("", {
                  "is-invalid": touched.generation && errors.generation,
                })}
              >
                 
                <option value="">Choose a Generation</option>
              {Array.isArray(generationData) && generationData.map((generation) => (
  <option key={generation._id} value={generation._id}>
    {toTitleCase(generation.generation_name)}
  </option>
))}
                  <option value="otherwise,namely">Otherwise,Namely</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.generation}
              </Form.Control.Feedback>
            </div>

            {values.generation === "otherwise,namely" && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className="fs14" htmlFor="other_generation">
                  Generation
                </Form.Label>
                <Form.Control
                  id="other_generation"
                  name="other_generation"
                  value={values.other_generation}
                  onChange={handleChange}
                  placeholder="Specify generation"
                  className={clsx("", {
                    "is-invalid":
                      touched.other_generation && errors.other_generation,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.other_generation}
                </Form.Control.Feedback>
              </div>
            )}

            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="engine">
                Engine
              </Form.Label>
              <Form.Select
                id="engine"
                name="engine"
                onChange={handleEngineChange}
                value={values?.engine}
                disabled={!values.generation}
                className={clsx("", {
                  "is-invalid": touched.engine && errors.engine,
                })}
              >
                <option value="">Choose an option</option>
                {engineData?.diesel?.length>0 && ( <optgroup label="Diesel">
                      {engineData?.diesel?.map((engine) => {

                          return (
                            <option key={engine._id} value={engine._id}>
                             {toTitleCase(engine.engine_name)} 
                            </option>
                          );
                        
                      })}
                    </optgroup>)}
                  
                {engineData?.petrol?.length>0 && ( <optgroup label="Petrol">
                      {engineData?.petrol?.map((engine) => {

                          return (
                            <option key={engine._id} value={engine._id}>
                             {toTitleCase(engine.engine_name)} 
                            </option>
                          );
                        
                      })}
                    </optgroup>)}
                {engineData?.Petrolmhev?.length>0 && ( <optgroup label="Electic vehicle">
                      {engineData?.Petrolmhev?.map((engine) => {

                          return (
                            <option key={engine._id} value={engine._id}>
                             {toTitleCase(engine.engine_name)} 
                            </option>
                          );
                        
                      })}
                    </optgroup>)}
                    
                <option value="otherwise,namely">Otherwise,Namely</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.engine}
              </Form.Control.Feedback>
            </div>

            {values.engine === "otherwise,namely" && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className="fs14" htmlFor="other_engine">
                  Engine
                </Form.Label>
                <Form.Control
                  id="other_engine"
                  name="other_engine"
                  onChange={handleChange}
                  value={values.other_engine}
                  placeholder="Specify engine"
                  className={clsx("", {
                    "is-invalid": touched.other_engine && errors.other_engine,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.other_engine}
                </Form.Control.Feedback>
              </div>
            )}
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="ecu">
                ECU
              </Form.Label>
              <Form.Select
                id="ecu"
                name="ecu"
                value={values.ecu}
                onChange={handleChange}
                disabled={!values.engine}
                className={clsx("", {
                  "is-invalid": touched.ecu && errors.ecu,
                })}
              >
                <option value="">Choose Your ECU</option>
                {Object.keys(ecuData).length > 0 && 
                  Object.keys(ecuData).map(category => {
                    if (Array.isArray(ecuData[category]) && ecuData[category].length > 0) {
                      return (
                        <optgroup key={category} label={category === "nan" ? "Engine ECU" : toTitleCase(category)}>
                          {ecuData[category].map(ecu => (
                            <option key={ecu._id} value={ecu._id}>
                              {ecu.ecu_name}
                            </option>
                          ))}
                        </optgroup>
                      );
                    }
                    return null;
                  })
                }
                <option value="otherwise,namely">Otherwise,namely</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.ecu}
              </Form.Control.Feedback>
            </div>
            {values?.ecu === "otherwise,namely" && (
              <>
                <div className="col-md-6 col-lg-6">
                  <Form.Label className="fs14" htmlFor="other_ecu">
                    ECU
                  </Form.Label>
                  <Form.Control
                    id="other_ecu"
                    name="other_ecu"
                    value={values.other_ecu}
                    onChange={handleChange}
                    placeholder="Specify ECU"
                    className={clsx("", {
                      "is-invalid": touched.other_ecu && errors.other_ecu,
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.other_ecu}
                  </Form.Control.Feedback>
                </div>
              </>
            )}
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="year">
                Year
              </Form.Label>
              <Form.Select
                id="year"
                name="year"
                value={values.year}
                onChange={handleChange}
                className={clsx("", {
                  "is-invalid": touched.year && errors.year,
                })}
              >
                <option value="">Choose an option</option>
                {[...Array(2025 - 1990 + 1)].map((_, index) => {
                  const year = 2025 - index;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.year}
              </Form.Control.Feedback>
            </div>

            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="gearbox">
                Gearbox
              </Form.Label>
              <Form.Select
                id="gearbox"
                name="gearbox"
                value={values.gearbox}
                onChange={handleChange}
                className={clsx("", {
                  "is-invalid": touched.gearbox && errors.gearbox,
                })}
              >
                <option value="">Choose your gearbox</option>
                {gearboxOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.gearbox}
              </Form.Control.Feedback>
            </div>
            {isPetrolSelected && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className="fs14" htmlFor="octane_rating">
                  Fuel Octane Rating <small className="text-secondary">(optional)</small>
                </Form.Label>
                <Form.Select
                  id="octane_rating"
                  name="octane_rating"
                  value={values.octane_rating || ""}
                  onChange={handleChange}
                  className={clsx("", {
                    "is-invalid": touched.octane_rating && errors.octane_rating,
                  })}
                >
                  <option value="">Choose octane rating</option>
                  {octaneRatingOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.octane_rating}
                </Form.Control.Feedback>
              </div>
            )}
          </div>
         
          <span className="fw-semibold">
            {" "}
            <i className="bi bi-pencil-square"></i> Additional Information
          </span>
          <div className="row mt-3 mb-5 row-gap-2">
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="engine_hp">
                Engine hp <small className="text-secondary">(optional)</small>
              </Form.Label>
              <Form.Control
                id="engine_hp"
                name="engine_hp"
                value={values?.engine_hp}
                onChange={handleChange}
                className={clsx("", {
                  "is-invalid": touched.engine_hp && errors.engine_hp,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.engine_hp}
              </Form.Control.Feedback>
            </div>
            <div className="col-md-6 col-lg-6">
              <Form.Label className="fs14" htmlFor="engine_kw">
                or kW <small className="text-secondary">(optional)</small>
              </Form.Label>
              <Form.Control
                id="engine_kw"
                name="engine_kw"
                onChange={handleChange}
                value={values?.engine_kw}
                className={clsx("", {
                  "is-invalid": touched.engine_kw && errors.engine_kw,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.engine_kw}
              </Form.Control.Feedback>
            </div>
            <div className="col-md-12 col-lg-12">
              <Form.Label className="fs14" htmlFor="vin_number">
                VIN (Vehicle identification number){" "}
                <small className="text-secondary">(optional)</small>
              </Form.Label>
              <Form.Control
                id="vin_number"
                name="vin_number"
                onChange={handleChange}
                className={clsx("", {
                  "is-invalid": touched.vin_number && errors.vin_number,
                })}
                value={values?.vin_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.vin_number}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VehicleInfoForm;