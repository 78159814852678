import axios from "axios";
export const API_URL = "https://api.pro-chiptuningfiles.com"


export const registerUser = async (userData) => {
  try {
      const response = await axios.post(`${API_URL}/users/register`, userData, {
          headers: { "Content-Type": "application/json" },
          timeout: 10000,
      });
      return response.data; 
  } catch (error) {
      throw error.response ? error.response.data : { message: "An unexpected error occurred" };
  }
};



export const sendEngineData = async (engineData, token) => {
    try {
      const response = await axios.post(`${API_URL}/search-details/engine-details`, engineData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error sending engine data:', error);
      return error;
    }
  };
  export const getOriVehicles = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/original/get-ori-vehicle`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return error;
    }
  };

  export const getSeries = async (vehicle_id, token) => {
    try {
      const response = await axios.post(
        `${API_URL}/original/get-ori-series`,
        { vehicle_id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error sending series data:', error);
      return error;
    }
  };
  export const getOriEngine = async (series_id, token) => {
    try {
      const response = await axios.post(`${API_URL}/original/get-ori-engine`, { series_id},{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching engine details:', error);
      return error;
    }
  };
  export const sendCarEngineData = async (carData, token) => {
    try {
      const response = await axios.post(`${API_URL}/original/get-or-files`, carData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      // console.error('Error sending car engine data:', error);
      return error.response.data;
    }
  };

  export const EngineDetails = async (carData, token) => {
    try {
      const response = await axios.post(`${API_URL}/original/original-files-detail/`, carData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error sending car engine data:', error);
      return error;
    }
  };

  export const getProfile = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/users/user-profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Access-Token")}`,
        },
      });
      return response.data;
    } 
    catch (error) {
      console.error("Error fetching profile:", error);
      return error;
    }
     
  };
 
  export const editProfile = async (profileData, token) => {
    try {
      const response = await axios.put(`${API_URL}/users/profile`, profileData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating profile:", error);
      return error;
    }
  };
  export const submitVehicleInfo = async (vehicleData, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/vehicle`, vehicleData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const submitTunningInfo = async (tuningData, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/tuning`, tuningData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('API Response:', response);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
    return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const submitAdditionalTuning = async (additionalTuningData, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/addition-tuning`, additionalTuningData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting additional tuning:', error);
      return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const searchCarEngineData = async (carData, token) => {
    try {
      const response = await axios.post(`${API_URL}/original/original-ecu-files`, carData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error sending car engine data:', error);
      return error;
    }
  };
  export const submitNewService = async (payload, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/file`, {
        id: payload.id,
        files: payload.files || [], 
        optional_attachments: payload.optional_attachments || "",
        optional_attachment_files: payload.optional_attachment_files || [], 
        form_step: "4"
      }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
  
      return response.data;
    } catch (error) {
      console.error("Error submitting new file service:", error);
      throw error.response ? error.response.data : { message: "Unexpected error occurred" };
    }
  };
  
  
  export const updateUserProfile = async (profileData, token) => {
    try {
      const response = await axios.post(`${API_URL}/users/user-update`, profileData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating user profile:", error);
      return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const getFormData = async (token,payload) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/checkout-form/`,payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return error;
    }
  };

  export const uploadFile = async (files, token) => {
    try {
      const formData = new FormData();
      formData.append("files", files);
  
      const response = await axios.post(`${API_URL}/checkoutfile-uploading`, formData, {
        headers: {
        
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },

      });
      console.log("🚀 ~ uploadFile ~ response:", response)
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      return error;
    }
  };
  export const getFormList = async (token,payload) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/checkout-list`,payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return error;
    }
  };
  export const submitModifiedParts = async (payload, token) => {
    try {
      const response =await axios. post(`${API_URL}/checkout/file-service`,payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }, 
      });
      console.log("🚀 ~ submitModifiedParts ~ response:", response)
      return response.data;
    } catch (error) {
      console.error('Error submitting modified parts:', error);
      return error;
    }
  };
  export const submitExtraInformation = async (payload, token) => {
    try {
      const response =await axios. post(`${API_URL}/checkout/extra-information`,payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }, 
      });
      console.log("🚀 ~ submitModifiedParts ~ response:", response)
      return response.data;
    } catch (error) {
      console.error('Error submitting modified parts:', error);
      return error;
    }
  };
  export const getNews = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/news/news-list/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating news:', error);
      return error.response.data;
    }
  };
  export const getNewsItem = async (slug, token) => {
    try {
      const response = await axios.post(`${API_URL}/news/single-news/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching news item with slug ${slug}:`, error);
      return {
        status_code: 500,
        message: error.message || 'Failed to fetch news item'
      };
    }
  };
  export const getAllOriFiles = async (token, page = 1) => {
    try {
      const response = await axios.get(`${API_URL}/original/original-files-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page
        }
      });
      return response.data;
    } 
    catch (error) {
      console.error("Error fetching files:", error);
      return error;
    }
  };
  export const saveTransaction = async (transactionData, token) => {
    console.log("🚀 ~ saveTransaction ~ token:", localStorage.getItem("Access-Token"))
    try {
      const response = await axios.post(`${API_URL}/checkout/item-buy`, transactionData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("Access-Token")}`
        }
     
      });
      return response.data;
    } catch (error) {
      console.error('Error saving transaction data:', error);
      return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const processStripePayment = async (paymentData, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/stripe-payment`, paymentData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error processing Stripe payment:', error);
      return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const directPayment = async (directData, token) => {
    try {
      const response = await axios.post(`${API_URL}/checkout/direct-payment`, directData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error processing Stripe payment:', error);
      return error.response ? error.response.data : { message: "An unexpected error occurred" };
    }
  };
  export const listOrders = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/checkout/buy-items-list`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return error;
    }
  };
  