import React, { useState } from 'react'
import Wrapper from '../Wrapper'
import TopCrumb from '../../header/TopCrumb'
import { Card } from 'react-bootstrap'
import OrderSection from '../Files/OrderSection'
import { Link } from 'react-router-dom'

const Subscriptions = () => {
    const [data, setData] = useState([])
    return (
        <Wrapper>
            <TopCrumb route={"Subscriptions overview"} />
            <div className='mt-4'>
                <p className='subs_desc mb-0'>The tables below show your current subscriptions and their status.</p>
                <p className='subs_desc mb-0'>
                    If you would like to change or remove a subscription, please send an email to
                    <a href='mailto:info@pro-chiptuningfiles.com'> info@pro-chiptuningfiles.com</a>.
                </p>
            </div>

            <div className='mt-4'>
                <h4 className='subs_title'>Subscriptions</h4>
                <Card className='p-4 mt-3'>
                    <OrderSection data={data} title="Subscriptions" />
                </Card>
            </div>

            <div className='mt-4'>
                <h4 className='subs_title'>Other</h4>
                <Card className='p-4 mt-3'>
                    <p className='subs_desc mb-0'>You currently have no other subscriptions.</p>
                    <div className='add_tuning'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-exclamation-circle exclamation_circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    <p className='subs_desc mb-0'>Add our <Link to="#">tuning specs iframe</Link> to any page on your website.</p>
                    </div>
                </Card>
            </div>
        </Wrapper>
    )
}

export default Subscriptions