import React, { useState } from "react";
import newlogo from "../../assets/images/logo-white.png";
import "../../assets/css/resetpassword.css";

const Resetpassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    // Proceed with form submission logic here

  };
  return (
    <>
      <section className="login_page_reset">
        <div className="login_website">
          <a
            className="navbar-brand d-flex align-items-center new-nav"
            href="/"
          >
            <img
              src={newlogo}
              alt="Logo"
              height="60"
              className="me-2 logo_res"
            />
          </a>
          <div className="login_reset gap-2">
            <h6>
              <a href="/" className="back_btn_forget">
                Back to website
              </a>
            </h6>
            <span className="">
              <select name="" id="" className="select_tab_forget">
                <option value="">English</option>
                <option value="">Nederland</option>
              </select>
            </span>
          </div>
        </div>
        <div className="col_form_reset">
          <div className="login_website_reset p-5">
            <form onSubmit={handleSubmit} className="login_page_reset_one">
              <h1 className="login_heading_reset mb-4">Choose Password</h1>

              <label htmlFor="newPassword" className="my-2">New Password</label>
              <div className="icon_div_reset">
                <input
                  type="password"
                  id="newPassword"
                  className="email_tab_reset my-2 shadow-lg"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  minLength={8}
                  placeholder="Enter new password"
                />
                <span>
                  <svg className="icon_mail shadow-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                </span>
              </div>

              <label htmlFor="confirmPassword" className="my-2">Confirm New Password</label>
              <div className="icon_div_forget">
                <input
                  type="password"
                  id="confirmPassword"
                  className="email_tab_reset my-2 shadow-lg"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  minLength={8}
                  placeholder="Confirm new password"
                />
                <span>
                  <svg className="icon_mail shadow-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80l0 48c0 17.7 14.3 32 32 32s32-14.3 32-32l0-48C576 64.5 511.5 0 432 0S288 64.5 288 144l0 48L64 192c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-192c0-35.3-28.7-64-64-64l-32 0 0-48z" /></svg>
                </span>
              </div>

              <button className="login_btn_reset my-4 shadow-lg" type="submit">
                Choose Password
              </button>
            </form>
          </div>
          <div className="login_website_reset_two p-5 shadow-lg">
            <p className="gap-2 account_two">
              60 clients rated us:
              <span className="gap-1">
                {[...Array(5)]?.map((_, i) => (
                  <svg
                    key={i}
                    className="icon_rateing"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    aria-label="Star Rating"
                  >
                    <path d="M287.9 0c9.2..." />
                  </svg>
                ))}
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Resetpassword;
