import React, { useState,useEffect } from "react";
import newlogo from "../../assets/images/logo-white.png";
import "../../assets/css/Register.css";
import fleg from "../../assets/images/argentina.jpg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as API from "../../api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountrySelector from "./countrySelector";

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accountType, setAccountType] = useState("personal");
  const [showAllTools, setShowAllTools] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState("us");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
    setValue("phone_number", value);
  };
  const handleCountryChange = (option) => {
    // Find the matching country code, default to 'us' if not found
    const phoneCountryCode = countryToPhoneCode[option.label] || "us";
    
    setSelectedCountry(option);
    setPhoneCountry(phoneCountryCode);
    setValue("country", option.label);
  };

  // Optional: Reset phone number when country changes
  useEffect(() => {
    setPhoneNumber("");
  }, [phoneCountry]);


  
  const password = watch("hashed_password");
  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
    // Clear company name when switching to personal
    if (e.target.value === "personal") {
      setValue("company_name", "");
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    setMessage("");
    setErrorMessage("");

    try {
      const payload = {
        account_type: data.account_type,
        company_name:
          data.account_type === "business" ? data.company_name : null,
        title: data.title,
        name: data.name,
        zipcode: data.zipcode,
        city: data.city,
        address_po: data.address_po,
        country: data.country,
        skype_address: data.skype_address || null,
        phone_number: data.phone_number,
        email: data.email,
        hashed_password: data.hashed_password,
        evc_status: data.evc_status === "true",
        password_confirmation: data.password_confirmation,
        dyno_status: data.dyno_status === "true",
        tools: data.tools || [],
      };
      const response = await API.registerUser(payload);

      if (response?.data?.status_code && response.data.status_code !== 200) {
        throw new Error(response.data.detail || "Registration failed.");
      }

      setMessage("Registration Successful!");

      setTimeout(() => {
        navigate("/login");
      }, 300);
    } catch (error) {
      console.error("API Error:", error);

      if (error.detail) {
        const status_code = error?.status_code;

        if (status_code === 400) {
          setErrorMessage(
            error?.detail || "Registration failed. Please try again."
          );
        } else if (status_code === 404) {
          setErrorMessage("User not found!");
        } else if (status_code === 401) {
          setErrorMessage("Incorrect credentials!");
        } else {
          setErrorMessage("Something went wrong. Please try again.");
        }
      } else if (error.request) {
        setErrorMessage("No response from the server. Please try again later.");
      } else {
        setErrorMessage(`An unexpected error occurred: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };
  const validateNonWhitespace = (value) => {
    return value && value.trim().length > 0;
  };
  const defaultTools = [
    "Alientech KESSv2",
    "CMDFlash",
    "Alientech K-TAG",
    "New Genius",
    "Alientech KESS3",
    "New Trasdata",
    "Autotuner",
    "Flex",
    "bFlash",
    "PCM-Flash",
  ];
  const countryToPhoneCode = {
    "Afghanistan": "af",
    "Aland Islands": "ax",
    "Albania": "al",
    "Algeria": "dz",
    "Andorra": "ad",
    "Anguilla": "ai",
    "Angola": "ao",
    "Argentina": "ar",
    "Armenia": "am",
    "Australia": "au",
    "Austria": "at",
    "Azerbaijan": "az",
    "Bahamas": "bs",
    "Bahrain": "bh",
    "Bangladesh": "bd",
    "Belarus": "by",
    "Belgium": "be",
    "Belize": "bz",
    "Benin": "bj",
    "Bhutan": "bt",
    "Bolivia": "bo",
    "Bosnia and Herzegovina": "ba",
    "Botswana": "bw",
    "Brazil": "br",
    "Brunei": "bn",
    "Bulgaria": "bg",
    "Burkina Faso": "bf",
    "Burundi": "bi",
    "Cambodia": "kh",
    "Cameroon": "cm",
    "Canada": "ca",
    "Central African Republic": "cf",
    "Chad": "td",
    "Chile": "cl",
    "China": "cn",
    "Colombia": "co",
    "Comoros": "km",
    "Congo (Congo-Brazzaville)": "cg",
    "Costa Rica": "cr",
    "Croatia": "hr",
    "Cuba": "cu",
    "Cyprus": "cy",
    "Czech Republic": "cz",
    "Denmark": "dk",
    "Djibouti": "dj",
    "Dominican Republic": "do",
    "Ecuador": "ec",
    "Egypt": "eg",
    "El Salvador": "sv",
    "Estonia": "ee",
    "Eswatini": "sz",
    "Ethiopia": "et",
    "Fiji": "fj",
    "Finland": "fi",
    "France": "fr",
    "Gabon": "ga",
    "Gambia": "gm",
    "Georgia": "ge",
    "Germany": "de",
    "Ghana": "gh",
    "Greece": "gr",
    "Guatemala": "gt",
    "Guinea": "gn",
    "Haiti": "ht",
    "Honduras": "hn",
    "Hungary": "hu",
    "Iceland": "is",
    "India": "in",
    "Indonesia": "id",
    "Iran": "ir",
    "Iraq": "iq",
    "Ireland": "ie",
    "Israel": "il",
    "Italy": "it",
    "Jamaica": "jm",
    "Japan": "jp",
    "Jordan": "jo",
    "Kazakhstan": "kz",
    "Kenya": "ke",
    "Kuwait": "kw",
    "Laos": "la",
    "Latvia": "lv",
    "Lebanon": "lb",
    "Libya": "ly",
    "Lithuania": "lt",
    "Luxembourg": "lu",
    "Madagascar": "mg",
    "Malaysia": "my",
    "Maldives": "mv",
    "Mali": "ml",
    "Malta": "mt",
    "Mexico": "mx",
    "Monaco": "mc",
    "Mongolia": "mn",
    "Morocco": "ma",
    "Mozambique": "mz",
    "Myanmar": "mm",
    "Namibia": "na",
    "Nepal": "np",
    "Netherlands": "nl",
    "New Zealand": "nz",
    "Nicaragua": "ni",
    "Niger": "ne",
    "Nigeria": "ng",
    "North Korea": "kp",
    "North Macedonia": "mk",
    "Norway": "no",
    "Oman": "om",
    "Pakistan": "pk",
    "Panama": "pa",
    "Papua New Guinea": "pg",
    "Paraguay": "py",
    "Peru": "pe",
    "Philippines": "ph",
    "Poland": "pl",
    "Portugal": "pt",
    "Qatar": "qa",
    "Romania": "ro",
    "Russia": "ru",
    "Rwanda": "rw",
    "Saudi Arabia": "sa",
    "Senegal": "sn",
    "Serbia": "rs",
    "Singapore": "sg",
    "Slovakia": "sk",
    "Slovenia": "si",
    "South Africa": "za",
    "South Korea": "kr",
    "Spain": "es",
    "Sri Lanka": "lk",
    "Sudan": "sd",
    "Sweden": "se",
    "Switzerland": "ch",
    "Syria": "sy",
    "Taiwan": "tw",
    "Tanzania": "tz",
    "Thailand": "th",
    "Togo": "tg",
    "Tunisia": "tn",
    "Turkey": "tr",
    "Uganda": "ug",
    "Ukraine": "ua",
    "United Arab Emirates": "ae",
    "United Kingdom": "gb",
    "United States": "us",
    "Uruguay": "uy",
    "Uzbekistan": "uz",
    "Vatican City": "va",
    "Venezuela": "ve",
    "Vietnam": "vn",
    "Western Sahara": "eh",
    "Yemen": "ye",
    "Zambia": "zm",
    "Zimbabwe": "zw"
};

  const additionalTools = [
    "Bitbox",
    "AMT Cartech Limited MPPS",
    "ByteShooter Toolbox",
    "Piasini Engineering Serial Suite",
    "Green Technology Devices VF2 Flasher",
    "FC-200",
    "EVC Electronic BDM100",
    "Truck Explorer",
    "EVC Electronic BSL100",
    "ABPROG",
    "Femto OBD Flasher",
    "DFOX",
    "FG Technology EOBD2",
    "MAGPro2",
    "Frieling Racing IFLASH",
    "TGFlash",
    "Frieling Racing IBOOT",
    "I/O Terminal",
    "Frieling Racing SPI Wizard",
    "Diamond",
    "HP Tuners MPVI2",
    "Otherwise, namely",
  ];
  const allTools = showAllTools
    ? [...defaultTools, ...additionalTools]
    : defaultTools;
  const getToolPairs = (tools) => {
    const pairs = [];
    for (let i = 0; i < tools.length; i += 2) {
      pairs.push(tools.slice(i, i + 2));
    }
    return pairs;
  };
  return (
    <section className="login_page_main">
      <div className="login_website">
        <a
          className="navbar-brand d-flex align-items-center new-nav"
          href="/home"
        >
          <img src={newlogo} alt="Logo" height="60" className="me-2 logo_res" />
        </a>
        <div className="login_web gap-2">
          <h6>
            <a href="/" className="back_btn">
              Back to website
            </a>
          </h6>
          <span className="">
            <select className="select_tab">
              <option value="">English</option>
              <option value="">Nederland</option>
            </select>
          </span>
        </div>
      </div>
      <div className="col_form_tab">
        <div className="login_website_reg">
          <form
            action=""
            className="login_page_reg p-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <a href=" /login" className="existing_account">
              <svg
                className="icon_arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
              Log in with existing account
            </a>
            <h1 className="login_heading my-4">Register your account</h1>
            <div>
              Account type
              <span className="radio_btn">
                <label htmlFor="" className="ml-2 ">
                  <input
                    type="radio"
                    className="Persnal_radio"
                    name=""
                    value="personal"
                    {...register("account_type", {
                      required: "Account type is required",
                      onChange: handleAccountTypeChange,
                    })}
                  />
                  Personal
                </label>
                <label htmlFor="">
                  <input
                    type="radio"
                    className="Persnal_radio"
                    name=""
                    value="business"
                    {...register("account_type", {
                      required: "Account type is required",
                      onChange: handleAccountTypeChange,
                    })}
                  />
                  Business
                </label>
              </span>
              {errors.account_type && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {errors.account_type.message}
                </p>
              )}
            </div>
            {accountType === "business" && (
              <div className="my-3">
                <div className=""> Company Name </div>
                <input
                  type="text"
                  className="email_tab_choice"
                  {...register("company_name", {
                    required: "Company name is required for business accounts",
                    validate: {
                      nonWhitespace: (value) =>
                        validateNonWhitespace(value) ||
                        "Company Name cannot be only  Whitespaces",
                    },
                  })}
                />
                {errors.company_name && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.company_name.message}
                  </p>
                )}
              </div>
            )}
            <div className="icon_choice">
              <div className="Title_tab">
                <div className=""> Title </div>

                <select
                  name="title"
                  className="email_tab_choice"
                  {...register("title", { required: "Title is required" })}
                >
                  <option value="">Make Your Choice</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                </select>
                {errors.title && (
                  <p
                    className="pt-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {errors.title.message}
                  </p>
                )}
              </div>
              <div className="Name_tab_resg">
                <div>Name</div>

                <input
  type="text"
  className="email_tab_choice_one my-2 shadow-lg"
  {...register("name", {
    required: "Name is required",
    validate: {
      nonWhitespace: (value) =>
        validateNonWhitespace(value) || "Name cannot be only whitespace",
      minLength: (value) =>
        value.trim().length >= 3 || "Name should have at least 3 characters",
    },
  })}
/>


                {errors.name && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.name.message}
                  </p>
                )}
              </div>
            </div>

            <div className="icon_choice">
              <div className="Zipcode_tab">
                <div className=""> Zipcode </div>

                <input
                  type="text"
                  className="email_tab_choice"
                  {...register("zipcode", {
                    required: "Zipcode is required",
                    validate: {
                      nonWhitespace: (value) =>
                        validateNonWhitespace(value) ||
                        "Zipcode cannot be only Whitespaces",
                    },
                  })}
                />
                {errors.zipcode && (
                  <p
                    className="pt-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {errors.zipcode.message}
                  </p>
                )}
              </div>
              <div className="city_tab">
                <div>City</div>

                <input
                  type="text"
                  className="email_tab_choice_one my-2 shadow-lg"
                  {...register("city", {
                    required: "City is required",
                    validate: {
                      nonWhitespace: (value) =>
                        validateNonWhitespace(value) ||
                        "City Cannot be only Whitespaces",
                    },
                  })}
                />
                {errors.city && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.city.message}
                  </p>
                )}
              </div>
            </div>
            <div className="icon_choice">
              <div className="Zipcode_tab">
                <div className=""> Address/PO box </div>

                <input
                  type="text"
                  className="email_tab_choice"
                  {...register("address_po", {
                    required: "Address is required",
                    validate: {
                      nonWhitespace: (value) =>
                        validateNonWhitespace(value) ||
                        "Address Cannot be only Whitespaces",
                    },
                  })}
                />
                {errors.address_po && (
                  <p
                    className="pt-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {errors.address_po.message}
                  </p>
                )}
              </div>
              <div className="Country_tab_resg need_val">
                <div className=""> Country </div>
                <CountrySelector
          value={selectedCountry}
          onChange={handleCountryChange}
          error={errors.country?.message}
          register={register}
        />
              </div>
            </div>

            <div className="icon_choice">
              <div className="Zipcode_tab">
                <div className="pt-3"> Skype address(optional) </div>

                <input
                  type="text"
                  className="email_tab_choice"
                  {...register("skype_address")}
                />
              </div>
              <div className="city_tab pt-3">
                <div className="phone_label">Phone number</div>
                <input
                  type="hidden"
                  {...register("phone_number", {
                    required: "Phone number is required",
                    maxLength: {
                      value: 17,
                      message: "Phone number cannot exceed 17 characters",
                    },
                  })}
                />
        <PhoneInput
          country={phoneCountry}
          value={phoneNumber}
          onChange={handlePhoneChange}
          inputClass="email_tab_choice_Phone my-2 shadow-lg"
          containerClass="phone_input_container"
          buttonClass="phone_input_button"
          dropdownClass="phone_input_dropdown"
          buttonStyle={{ minWidth: "auto" }}
        />
                {errors.phone_number && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.phone_number.message}
                  </p>
                )}
              </div>
            </div>
            <div className="icon_choice need_val">
              <div className="Zipcode_tab">
                <div className="email"> Email address </div>

                <input
                  className="email_tab_choice"
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="Country_tab_resg need_val pass+field">
                <div className="">Password</div>

                <input
                  type="Password"
                  className="email_tab_choice"
                  {...register("hashed_password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                      validate: {
                        nonWhitespace: (value) =>
                          validateNonWhitespace(value) ||
                          "Password Cannot be only Whitespaces",
                      },
                    },
                  })}
                />

                {errors.hashed_password && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.hashed_password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="icon_choice mt-3 need_val">
              <div className="Zipcode_tab">
                <div className=""> Are you registered at EVC.de? </div>

                <select
                  name="evc_status"
                  className="email_tab_choice"
                  {...register("evc_status", {
                    required: "Please select your EVC registration status",
                  })}
                >
                  <option value="">Make Your Choice</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                {errors.evc_status && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.evc_status.message}
                  </p>
                )}
              </div>

              <div className="Country_tab_resg need_val">
                <div className=""> Password confirmation </div>

                <input
                  type="password"
                  className="email_tab_choice"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: "Password confirmation is required",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                    validate: {
                      nonWhitespace: (value) =>
                        validateNonWhitespace(value) ||
                        "Password Cannot be only Whitespaces",
                    },
                  })}
                />
                {errors.password_confirmation && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.password_confirmation.message}
                  </p>
                )}
              </div>
            </div>
            <div className="icon_choice mt-3 need_val">
              <div className="Zipcode_tab">
                <div className=""> Do you have a Pro? </div>

                <select
                  name="dyno_status"
                  className="email_tab_choice"
                  {...register("dyno_status", {
                    required: "Please select your Pro status",
                  })}
                >
                  <option value="">Make Your Choice</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                {errors.dyno_status && (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {errors.dyno_status.message}
                  </p>
                )}
              </div>
            </div>

            <div className="my-3">Tools(optional)</div>

            {getToolPairs(allTools).map((pair, index) => (
              <div key={index} className="icon_check my-3">
                {pair.map((tool, idx) => (
                  <label
                    key={idx}
                    className={idx === 0 ? "check_content" : "label_one"}
                  >
                    <input
                      type="checkbox"
                      value={tool}
                      {...register("tools")}
                      className="checkbox_tab"
                    />
                    {tool}
                  </label>
                ))}
              </div>
            ))}

            <a
              href="#"
              className="all_tools"
              onClick={(e) => {
                e.preventDefault();
                setShowAllTools(!showAllTools);
              }}
            >
              {showAllTools ? "Show less tools" : "Show all tools"}
            </a>

            <button
              className="login_btn mt-3 shadow-lg"
              type="submit"
              disabled={loading}
            >
              {" "}
              {loading ? "Registering..." : "Register"}
            </button>
            {/* {message && <p className="message register_success shadow-lg">{message}</p>} */}

            {message && (
              <p className="message login_success shadow-lg">{message}</p>
            )}
            {errorMessage && (
              <p className="message login_error shadow-lg">{errorMessage}</p>
            )}
          </form>
        </div>

        <div className="login_website_register p-5 ">
          <h5 className="account_yet py-2">Why use Pro-ChiptuningFiles.com</h5>
          <h6 className="account_one">
            As member of Pro-ChiptuningFiles.com enjoy these benefits:
          </h6>
          <div className="d-flex gap-2 ">
            <span>
              <svg
                className="icon_check"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </span>
            <p className="account_two pt-1">
              As member of Pro-ChiptuningFiles.com enjoy these benefits:
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <span>
              <svg
                className="icon_check"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </span>
            <p className="account_two pt-1">
              Upload tuningfiles and receive the modified files in return
            </p>
          </div>
          <div className="d-flex gap-2">
            <span>
              <svg
                className="icon_check"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </span>
            <p className="account_two pt-1">
              Modified files are of high quality, safe and pro-tested
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <span>
              <svg
                className="icon_check"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </span>
            <p className="account_two pt-1">
              Every tuning file is custom made to fit your car, with the best
              perfomance results
            </p>
          </div>
          <p className="gap-2 account_two">
            {" "}
            58 clients rated us:
            <span className="gap-1 ">
              <svg
                className="icon_rateing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
              </svg>
              <svg
                className="icon_rateing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
              </svg>
              <svg
                className="icon_rateing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
              </svg>
              <svg
                className="icon_rateing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
              </svg>
              <svg
                className="icon_rateing"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
              </svg>
            </span>
            9.9/1
          </p>
        </div>
      </div>
    </section>
  );
};

export default Register;
