import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/dashboard.css";
import newlogo from "../../assets/images/logo-white.png";
import smallLogo from "../../assets/images/small-logo.png";
import Navbar from "./navbar";
import { Link, useNavigate } from "react-router-dom";

const Wrapper = ({ children,handleNewFileServiceClick }) => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const logoutRef = useRef(null);
    
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    // Handle click outside logout menu to close it
    useEffect(() => {
        function handleClickOutside(event) {
            if (logoutRef.current && !logoutRef.current.contains(event.target)) {
                setShowLogout(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [logoutRef]);

    const main = [
        { href: "/dashboard", title: "Dashboard", icon: "bi bi-grid" },
        { href: "/file-services", title: "File services", icon: "bi bi-file-earmark" },
        {
            href: "/new-file-services",
            title: "New File Services",
            icon: "bi bi-file-earmark-plus",
            onClick: handleNewFileServiceClick, 
        },
        { href: "/support-tickets", title: "Support tickets", icon: "bi bi-question-circle" },
        { href: "/original-files", title: "Original Files", icon: "bi bi-file-earmark-check-fill" },
        { href: "/demo-files", title: "Demo Files", icon: "bi bi-file-earmark-arrow-up-fill" },
        { href: "/order-history", title: "Order History", icon: "bi bi-clock-history" },
        { href: "/credit-transactions", title: "Credit transactions", icon: "bi bi-credit-card-fill" },
        { href: "/subscriptions", title: "Subscriptions", icon: "bi bi-calendar-plus" },
    ];

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    
    // Toggle logout menu visibility
    const toggleLogout = () => {
        setShowLogout(!showLogout);
    };

    // Direct logout function
    const performLogout = () => {
        localStorage.clear();
        window.location.href = "/login";
    };
      
    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                <div className={`Dashboard_page ${isCollapsed ? "sibarbarcollapsed" : ""}`}>
                    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
                        <button
                            className={`custom-btn ${isCollapsed ? "rotate" : ""}`}
                            onClick={toggleSidebar}
                        >
                            <svg
                                href="/login"
                                className="Dashboard_icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                            >
                                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg>
                        </button>
                        <div className="Dashboard_btn">
                            <Link
                                className="navbar-brand d-flex align-items-center new-nav"
                                to="/"
                            >
                                <img
                                    src={isCollapsed ? smallLogo : newlogo}
                                    alt="Logo"
                                    className="box_image_logo"
                                />
                            </Link>
                        </div>
                        <ul>
                            {main?.map((item, index) => (
                                <li
                                    key={index}
                                    className={`Dashboard_sidebar my-3 d-flex  ${isCollapsed ? "text-hidden" : ""}`}
                                >
                                    <Link
                                        className="text-light text-decoration-none"
                                        to={item.href}
                                        onClick={(e) => {
                                            if (item.onClick) {
                                                e.preventDefault();
                                                item.onClick();
                                            }
                                        }}
                                    >
                                        <div className="d-flex gap-3 align-items-center">
                                            <i className={`${item.icon} fs-5`}></i>
                                            {!isCollapsed && (
                                                <p className="Dashboard_text">{item.title}</p>
                                            )}
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
               
                        {/* Completely revised logout implementation */}
                        <div className={"Dashboard_prifile py-3 gap-3"} ref={logoutRef}>
                            <div 
                                className="Dashboard_prifile_1" 
                                onClick={toggleLogout} 
                                style={{ cursor: 'pointer' }}
                            >
                                <p className="Dashboard_text">KK</p>
                            </div>
                            
                            {showLogout && (
                                <div 
                                    className="logout-section" 
                                    onClick={performLogout} 
                                    style={{ 
                                        cursor: 'pointer', 
                                        color: 'white', 
                                        padding: '10px',
                                        position: 'absolute',
                                        zIndex: 1000,
                                        backgroundColor: '#333',
                                        borderRadius: '4px',
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                                    }}
                                >
                                    Logout
                                </div>
                            )}
                
                            {showPopup && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <div className="my-3 popup-content_heading">
                                            <h6 className="">Mandeep</h6>
                                            <p>mandeepsharma@codenomad.net</p>
                                        </div>
                                        <div className="my-3 popup-content_heading">
                                            <h6>Client ID: 65655</h6>
                                            <p>0 active subscriptions</p>
                                        </div>
                                        <div className="my-3 popup-content_heading">
                                            <h6>
                                                <a className="link_popup" href="/editprofile">
                                                    Edit profile
                                                </a>
                                            </h6>
                                            <p>
                                                <button 
                                                    className="link_popup" 
                                                    onClick={performLogout}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: 0,
                                                        color: 'inherit',
                                                        textDecoration: 'none',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    Log out
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isCollapsed && (
                                <p className="Dashboard_text_profile">
                                    <span className="Dashboard_profile_tab">
                                        <a href="/login">
                                            <svg
                                                className="Dashboard_icon_page"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 320 512"
                                            >
                                                <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                                            </svg>
                                        </a>
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <div className={`content-area ${isCollapsed ? "fullwidth" : ""}`}>
                    <div className="w-100">
                        <Navbar />
                        <div className="page-wrapper container-fluid">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wrapper;