import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../../cartSlice";
import Wrapper from "../../Wrapper";
import TopCrumb from "../../../header/TopCrumb";
import { Card } from "react-bootstrap";
import VehicleInfoForm from "./VehicleInfo";
import TuningInfoForm from "./TuningInfo";
import AdditionalTuningForm from "./AdditionalTuning";
import FileModifyingForm from "./FileModify";
import { useFormik } from "formik";
import * as Yup from "yup";
import VehicleSummary from "./CardDisplay";
import NewServiceForm from "./NewService";
import ExtraInfoForm from "./ExtraInfo";
import { toast } from "react-toastify";
import RightSideCard from "./RightsideCard";
import {
  submitTunningInfo,
  getFormData,
  submitModifiedParts,
  submitExtraInformation,
} from "../../../../api";
import {
  submitVehicleInfo,
  submitAdditionalTuning,
  submitNewService,
} from "../../../../api";

const NewFileServices = () => {
  const [active, setActive] = useState(1);
  const [fileId, setFileId] = useState(null);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [additional_tuning, setAdditionalTuning] = useState([]);
  const [showFileUploadError, setShowFileUploadError] = useState(false);

  const [modification, setModification] = useState({
    file: [],
    additional: [],
  });
  const [modifyservice, setModifiService] = useState({});
  const [extrainfo, setExtraInfo] = useState();
  
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [rightSideData, setRightSideData] = useState(null);

  useEffect(() => {
    const fetchRightSideData = async () => {
      if (fileId) {
        try {
          const token = localStorage.getItem("Access-Token");
          if (!token) {
            window.location.href = "/login";
            return;
          }

          const payload = { id: fileId };
          const response = await getFormData(token, payload);

          if (response && response.status_code === 200 && response.data) {
            const data = response.data[0];

            const rightSideData = {

              make:
                data.make?.name == null ? "otherwise,namely" : data.make?.name,
              other_make: data.all_fields?.other_make || "",
              vehicle_type: data.all_fields?.other_vehicle_type || "",
              model:
                data.model?.name == null
                  ? "otherwise,namely"
                  : data.model?.name,
              other_model: data.all_fields?.other_model || "",
              generation:
                data.genration?.name == null
                  ? "otherwise,namely"
                  : data.genration?.name,
              other_generation: data.all_fields?.other_generation || "",
              engine:
                data.engine?.name == null
                  ? "otherwise,namely"
                  : data.engine?.name,
              other_engine: data.all_fields?.other_engine || "",
              ecu:
                data.all_fields?.ecu == null
                  ? "otherwise,namely"
                  : data.all_fields?.ecu,
              other_ecu: data.all_fields?.other_ecu || "",
              year: data.all_fields?.year || "",
              gearbox: data.all_fields?.garebox || "",
              engine_hp: data.all_fields?.engine_hp || "",
              engine_kw: data.all_fields?.kw || "",
              vin_number: data.all_fields?.vin || "",
              tuning_type: data.all_fields?.tuning_type || "",
              software_no: data.all_fields?.software_no || "",
              hardware_no: data.all_fields?.hardware_no || "",
              read_method:
                data.all_fields?.read_method == null
                  ? "otherwise,namely"
                  : data.all_fields?.read_method,
              other_read: data.all_fields?.other_read || "",
              email: data.all_fields?.email || "",
              other_email: data.all_fields?.other_email || "",

              additional_tuning: data.all_fields?.addition_tuning || [],

              // File Modifications
              files: data.all_fields?.files || [],
              optional_attachment_files:
                data.all_fields?.optional_attachment_files || [],

              // Modified Parts
              modified_parts: data.all_fields?.modified_parts || [],

              // Extra Info
              time_frame: data.all_fields?.time_frame || "",
              car_dyno: data.all_fields?.car_dyno || "",
              altitude_modify: data.all_fields?.altitude_modify || "",
              addition_information_car:
                data.all_fields?.addition_information_car || "",
              tac: data.all_fields?.tac || "",
            };

            setRightSideData(rightSideData);
          }
        } catch (error) {
          console.error("Error fetching right side data:", error);
          toast.error("Failed to load summary data");
        }
      }
    };

    fetchRightSideData();
  }, [fileId, active]);
  const vehicle_info = useFormik({
    initialValues: {
      make: "",
      other_make: "",
      vehicle_type: "",
      model: "",
      other_model: "",
      generation: "",
      other_generation: "",
      engine: "",
      other_engine: "",
      ecu: "",
      other_ecu: "",
      year: "",
      gearbox: "",
      fuel_octane_rating: "",
      engine_hp: "",
      engine_kw: "",
      vin_number: "",
    },
    validationSchema: Yup.object()
      .shape({
        make: Yup.string().required("Make is required"),
        vehicle_type: Yup.string().when("make", {
          is: "otherwise,namely",
          then: () => Yup.string().required("Vehicle type is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        other_make: Yup.string().when("make", {
          is: "otherwise,namely",
          then: () => Yup.string().required("Make specification is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        model: Yup.string().required("Model is required"),
        other_model: Yup.string().when("model", {
          is: "otherwise,namely",
          then: () => Yup.string().required("Model specification is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        generation: Yup.string().required("Generation is required"),
        other_generation: Yup.string().when("generation", {
          is: "otherwise,namely",
          then: () =>
            Yup.string().required("Generation specification is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        engine: Yup.string().required("Engine is required"),
        other_engine: Yup.string().when("engine", {
          is: "otherwise,namely",
          then: () => Yup.string().required("Engine specification is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        ecu: Yup.string().required("ECU is required"),
        other_ecu: Yup.string().when("ecu", {
          is: "otherwise,namely",
          then: () => Yup.string().required("ECU specification is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        year: Yup.string().required("Year is required"),
        gearbox: Yup.string().required("Gearbox is required"),
        fuel_octane_rating: Yup.string().nullable(),
        engine_hp: Yup.string().nullable().matches(/^\d+$/, "Must be a number"),
        engine_kw: Yup.string().nullable().matches(/^\d+$/, "Must be a number"),
        vin_number: Yup.string()
          .nullable()
          .min(17, "VIN must be at least 17 characters"),
      })
      
      .test("at-least-one-make", null, function (values) {
        if (values.make === "otherwise,namely") {
          if (!values.vehicle_type || !values.other_make) {
            return this.createError({
              path: "make",
              message:
                "Please fill in both vehicle type and make specification",
            });
          }
        }
        return true;
      })
      .test("at-least-one-model", null, function (values) {
        if (values.model === "otherwise,namely" && !values.other_model) {
          return this.createError({
            path: "model",
            message: "Please fill in model specification",
          });
        }
        return true;
      })
      .test("at-least-one-generation", null, function (values) {
        if (
          values.generation === "otherwise,namely" &&
          !values.other_generation
        ) {
          return this.createError({
            path: "generation",
            message: "Please fill in generation specification",
          });
        }
        return true;
      })
      .test("at-least-one-engine", null, function (values) {
        if (values.engine === "otherwise,namely" && !values.other_engine) {
          return this.createError({
            path: "engine",
            message: "Please fill in engine specification",
          });
        }
        return true;
      })
      .test("at-least-one-ecu", null, function (values) {
        if (values.ecu === "otherwise,namely" && !values.other_ecu) {
          return this.createError({
            path: "ecu",
            message: "Please fill in ECU specification",
          });
        }
        return true;
      }),
      validateOnChange: true,
      validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem("Access-Token");
        if (!token) {
          window.location.href = "/login";
          return;
        }

        const vehiclePayload = {
          id: fileId,
          form_step: "1",
          make: values.make === "otherwise,namely" ? null : values.make,
          model: values.model === "otherwise,namely" ? null : values.model,
          other_vehicle_type:
            values.make === "otherwise,namely" ? values.vehicle_type : "",
          genration:
            values.generation === "otherwise,namely" ? null : values.generation,
          engine: values.engine === "otherwise,namely" ? null : values.engine,
          engine_hp: values.engine_hp || "",
          ecu: values.ecu === "otherwise,namely" ? null : values.ecu,
          year: values.year,
          garebox: values.gearbox,
          kw: values.engine_kw || "",
          vin: values.vin_number || "",
          other_make:
            values.make === "otherwise,namely" ? values.other_make : "",
          other_model:
            values.model === "otherwise,namely" ? values.other_model : "",
          other_generation:
            values.generation === "otherwise,namely"
              ? values.other_generation
              : "",
          other_engine:
            values.engine === "otherwise,namely" ? values.other_engine : "",
          other_ecu: values.ecu === "otherwise,namely" ? values.other_ecu : "",
          fuel_octane_rating: values.fuel_octane_rating || "",
        
        };

        const response = await submitVehicleInfo(vehiclePayload, token);
        if (response && response.id) {
          setFileId(response.id);
        } else {
          console.error("No ID returned in response:", response);
        }

        setActive(active + 1);
      } catch (error) {
        console.error("Error submitting vehicle info:", error);
      }
    },
  });

  const tuning_info = useFormik({
    initialValues: {
      read_method: "",
      other_read: "",
      hardware_no: "",
      software_no: "",
      email: "",
      other_email: "",
      tuning_type: "",
    },
    validationSchema: Yup.object().shape({
      read_method: Yup.string().required("Read method is required"),
      other_read: Yup.string().when("read_method", {
        is: "otherwise,namely",
        then: () =>
          Yup.string()
            .required("Read method specification is required")
            .min(3, "Must be at least 3 characters")
            .max(50, "Must be 50 characters or less"),
        otherwise: () => Yup.string().notRequired(),
      }),
      software_no: Yup.string().required("Software number is required"),
      other_email: Yup.string().when("email", {
        is: "otherwise,namely",
        then: () =>
          Yup.string()
            .email("Invalid email format")
            .required("Email specification is required"),
        otherwise: () => Yup.string().notRequired(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const token = localStorage.getItem("Access-Token");
        if (!token) {
          window.location.href = "/login";
          return;
        }

        const tuningPayload = {
          id: fileId,
          form_step: "2",
          read_method:
            values.read_method === "otherwise,namely"
              ? null
              : values.read_method,
          other_read:
            values.read_method === "otherwise,namely" ? values.other_read : "",
          hardware_no: values.hardware_no,
          software_no: values.software_no,
          email: values.email === "otherwise,namely" ? null : values.email,
          other_email:
            values.email === "otherwise,namely" ? values.other_email : "",
          tuning_type: values.tuning_type,
        };

        const response = await submitTunningInfo(tuningPayload, token);
        if (response && response.id) {
          setFileId(response.id);
        } else {
          console.error("No ID returned in response:", response);
        }

        setActive(active + 1);
      } catch (error) {
        console.error("Error submitting tuning info:", error);
      }
    },
  });
  useEffect(() => {
    const savedFileId = localStorage.getItem("fileId");

    if (savedFileId) {
      setFileId(savedFileId);

      const fetchFormData = async () => {
        try {
          const token = localStorage.getItem("Access-Token");
          if (!token) {
            window.location.href = "/login";
            return;
          }

          const payload = { id: savedFileId };
          const response = await getFormData(token, payload);

          if (
            response &&
            response.status_code === 200 &&
            response.data &&
            response.data[0]
          ) {
            const data = response.data[0];
            const mainFiles = data.all_fields?.files || [];
            const optionalFiles =
              data.all_fields?.optional_attachment_files || [];
            const formattedMainFiles = mainFiles.map((file) => ({
              filename: file.filename,
              url: file.url,
            }));
            const extraInfoData = {
              time_frame: data.all_fields?.time_frame || "",
              car_dyno: data.all_fields?.car_dyno || "",
              altitude_modify: data.all_fields?.altitude_modify || "",
              addition_information_car:
                data.all_fields?.addition_information_car || "",
              tac: data.all_fields?.tac || "",
              refund_policy: data.all_fields?.refund_policy || "",
            };
            setExtraInfo(extraInfoData);

            const formattedOptionalFiles = optionalFiles.map((file) => ({
              filename: file.filename,
              url: file.url,
            }));
            setModification({
              file: formattedMainFiles,
              additional: formattedOptionalFiles,
            });
            if (data.all_fields?.modified_parts) {
              setModifiService({
                file: {
                  isModified: data.all_fields.modified_parts.length > 0,
                },
                additional: {
                  modifications: data.all_fields.modified_parts,
                },
              });
            }

            const vehicleData = {
              make: data.make?.id == null ? "otherwise,namely" : data.make?.id,
              other_make: data.all_fields?.other_make || "",
              vehicle_type: data.all_fields?.other_vehicle_type || "",
              model:
                data.model?.id == null ? "otherwise,namely" : data.model?.id,
              other_model: data.all_fields?.other_model || "",
              generation:
                data.genration?.id == null
                  ? "otherwise,namely"
                  : data.genration?.id,
              other_generation: data.all_fields?.other_generation || "",
              engine:
                data.engine?.id == null ? "otherwise,namely" : data.engine?.id,
              other_engine: data.all_fields?.other_engine || "",
              ecu:
                data.all_fields?.ecu == null
                  ? "otherwise,namely"
                  : data.all_fields?.ecu,
              other_ecu: data.all_fields?.other_ecu || "",
              year: data.all_fields?.year || "",
              gearbox: data.all_fields?.garebox || "",
              engine_hp: data.all_fields?.engine_hp || "",
              engine_kw: data.all_fields?.kw || "",
              vin_number: data.all_fields?.vin || "",
            };
            vehicle_info.setValues(vehicleData);

            const tuningData = {
              tuning_type: data.all_fields?.tuning_type || "",
              software_no: data.all_fields?.software_no || "",
              hardware_no: data.all_fields?.hardware_no || "",
              read_method:
                data.all_fields?.read_method == null
                  ? "otherwise,namely"
                  : data.all_fields?.read_method,
              other_read: data.all_fields?.other_read || "",
              email: data.email
                ? "otherwise,namely"
                : data.all_fields?.email || "",
              other_email: data.all_fields?.other_email || "",
            };
            tuning_info.setValues(tuningData);

            if (
              data.all_fields?.addition_tuning &&
              Array.isArray(data.all_fields?.addition_tuning)
            ) {
              setAdditionalTuning(data.all_fields?.addition_tuning);
            }

            const newCompletedSteps = [1];
            if (data.make) newCompletedSteps.push(2);
            if (data.all_fields?.tuning_type || data.all_fields?.software_no)
              newCompletedSteps.push(3);
            if (
              data.all_fields?.addition_tuning &&
              Array.isArray(data.all_fields?.addition_tuning) &&
              data.all_fields?.addition_tuning.length > 0
            ) {
              newCompletedSteps.push(4);
            }

            if (mainFiles.length > 0 || optionalFiles.length > 0) {
              if (!newCompletedSteps.includes(4)) newCompletedSteps.push(4);
              if (!newCompletedSteps.includes(5)) newCompletedSteps.push(5);
            }
            if (mainFiles.length > 0 || optionalFiles.length > 0) {
              if (!newCompletedSteps.includes(4)) newCompletedSteps.push(4);
              if (!newCompletedSteps.includes(5)) newCompletedSteps.push(5);
            }
            if (
              newCompletedSteps.includes(5) &&
              data.all_fields?.time_frame &&
              data.all_fields?.tac &&
              data.all_fields?.refund_policy
            ) {
              if (!newCompletedSteps.includes(6)) {
                newCompletedSteps.push(6);
              }
            }

            setCompletedSteps(newCompletedSteps);
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(true);
          }
        } catch (error) {
          console.error("Error fetching form data:", error);
          setIsDataLoaded(true);
          if (error.message !== "Cannot read properties of undefined") {
          }
        }
      };

      fetchFormData();
    } else {
      setIsDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (fileId) {
      localStorage.setItem("fileId", fileId);
    }
  }, [fileId]);

  const submitAdditionalTuningInfo = async () => {
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }

      const additionalTuningPayload = {
        id: fileId,
        form_step: "3",
        addition_tuning: additional_tuning,
      };

      const response = await submitAdditionalTuning(
        additionalTuningPayload,
        token
      );

      if (response && response.id) {
        setFileId(response.id);
      } else {
        console.error("No ID returned in response:", response);
      }

      return response;
    } catch (error) {
      console.error("Error submitting additional tuning info:", error);
    }
  };
  const resetFormData = () => {
    localStorage.removeItem("fileId");
    setFileId(null);
    setCompletedSteps([1]);
    setAdditionalTuning([]);
    setModification({
      file: [],
      additional: [],
    });
    setModifiService({});
    setExtraInfo(undefined);
    setRightSideData(null);
    setShowFileUploadError(false);
    vehicle_info.resetForm();
    tuning_info.resetForm();
    setActive(1);
    setIsDataLoaded(true);
  };

  const handleNewFileServiceClick = () => {
    resetFormData();
    if (window.location.pathname !== "/new-file-services") {
      window.location.href = "/new-file-services/new=true";
    }
  };
  const handleNext = async () => {
    if (active === 1) {
      try {

        const errors = await vehicle_info.validateForm();

        if (Object.keys(errors).length > 0) {
          Object.keys(vehicle_info.values).forEach((field) => {
            vehicle_info.setFieldTouched(field, true);
          });

          return;
        }
        await vehicle_info.submitForm();
        if (!completedSteps.includes(2)) {
          setCompletedSteps([...completedSteps, 2]);
        }
        setActive(active + 1);
      } catch (submitError) {
        console.error("Validation or submission error:", submitError);
        toast.error("Error processing vehicle information");
      }
    } else if (active === 2) {
      try {
        const errors = await tuning_info.validateForm();
        
        if (Object.keys(errors).length > 0) {
          Object.keys(tuning_info.values).forEach((field) => {
            tuning_info.setFieldTouched(field, true);
          });
          
          toast.error("Please fill in all required fields correctly");
          return;
        }

        await tuning_info.submitForm();
        
        if (!completedSteps.includes(3)) {
          setCompletedSteps([...completedSteps, 3]);
        }
        
        setActive(active + 1);
      } catch (submitError) {
        console.error("Validation or submission error:", submitError);
        toast.error("Error processing tuning information");
      }
    } else if (active === 3) {
      if (additional_tuning.length === 0) {
        toast.error("Please add at least one additional tuning option");
        return;
      }
      await submitAdditionalTuningInfo();
      if (!completedSteps.includes(4)) {
        setCompletedSteps([...completedSteps, 4]);
      }
      setActive(4);
    } else if (active === 4) {
      if (!modification.file || modification.file.length === 0) {
        setShowFileUploadError(true);
        toast.error("Please upload at least one file");
        return;
      }
      await handleModifyServiceSubmit();
      if (!completedSteps.includes(5)) {
        setCompletedSteps([...completedSteps, 5]);
      }
      setActive(5);
    } else if (active === 5) {
      await handleModifyParts();
      if (!completedSteps.includes(6)) {
        setCompletedSteps([...completedSteps, 6]);
      }
      setActive(6);
    }
  };

  const handleExtraInfoSubmit = async () => {
    if (
      !extrainfo?.time_frame ||
      !extrainfo?.tac ||
      !extrainfo?.refund_policy
    ) {
      return false;
    }
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }

      const payload = {
        id: fileId,
        status_form: true,
        time_frame: extrainfo.time_frame,
        car_dyno: extrainfo.car_dyno,
        altitude_modify: extrainfo.altitude_modify,
        addition_information_car: extrainfo.addition_information_car,
        tac: extrainfo.tac,
        refund_policy: extrainfo.refund_policy,
        form_step: "5",
      };
    

      const response = await submitExtraInformation(payload, token);


      if (response && response.id) {
        setFileId(response.id);
        if (!completedSteps.includes(6)) {
          setCompletedSteps([...completedSteps, 6]);
        }
        setActive(6);
      } else {
        return new Error("Failed to submit extra information");
      }
    } catch (error) {
      console.error("Error submitting extra information:", error);
      toast.error(error.message || "Failed to submit extra information");
    }
  };

  const handleModifyParts = async () => {
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }

      const payload = {
        id: fileId,
        form_step: "5",
        modified_parts:
          modifyservice.file.isModified &&
          Array.isArray(modifyservice.additional?.modifications)
            ? modifyservice.additional.modifications.map((mod) => ({
                type: mod.type || "Unknown",
                remarks: mod.remarks || "",
              }))
            : [],
      };

      const response = await submitModifiedParts(payload, token);
     

      if (response && response.id) {
        setFileId(response.id);
        if (!completedSteps.includes(6)) {
          setCompletedSteps([...completedSteps, 6]);
        }
        setActive(6);
      } else {
        toast.error("Failed to submit service modifications");
      }
    } catch (error) {
      console.error("Error submitting service modifications:", error);
      toast.error(error.message || "Failed to submit service modifications");
    }
  };
  const handleModifyServiceSubmit = async () => {
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }
      const payload = {
        id: fileId,
        files: modification.file,
        optional_attachments: modification.additional.length > 0 ? "yes" : "no",
        optional_attachment_files: modification.additional || [],
        form_step: "4",
      };


      const response = await submitNewService(payload, token);


      if (response && response.id) {
        setFileId(response.id);
        if (!completedSteps.includes(5)) {
          setCompletedSteps([...completedSteps, 5]);
        }
        setActive(5);
      } else {
        toast.error("Failed to submit file modifications");
      }
    } catch (error) {
      console.error("Error submitting file modifications:", error);
      toast.error(error.message || "Failed to submit file modifications");
    }
  };

  if (!isDataLoaded) {
    return (
      <Wrapper>
        <TopCrumb route={"New File Services"} />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper handleNewFileServiceClick={handleNewFileServiceClick}>
      <TopCrumb route={"New File Services"} />

      <div className="row my-1 p-3">
        <div className="d-flex gap-3 align-items-center clock_section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-clock"
            viewBox="0 0 16 16"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
          </svg>
          <div>
            <p className="mb-1">
              The estimated delivery time for your tuning files is
            </p>
            <h6 className="mb-0">15 - 30 min</h6>
          </div>
        </div>
        <div className="mt-3">
          <div className="row">
            <div className="col-md-8">
              <Card>
                <TabBar
                  setActive={setActive}
                  active={active}
                  handleNext={handleNext}
                  completedSteps={completedSteps}
                  fileId={fileId}
                  vehicle_info={vehicle_info}
                  tuning_info={tuning_info}
                  setAdditionalTuning={setAdditionalTuning}
                  setModification={setModification}
                  setModifiService={setModifiService}
                  handleExtraInfoSubmit={handleExtraInfoSubmit}
                  setExtraInfo={setExtraInfo}
                  extrainfo={extrainfo}
                  resetFormData={resetFormData}
                />
                <div className="p-4">
                  {active === 1 && (
                    <VehicleInfoForm
                      values={vehicle_info.values}
                      errors={vehicle_info.errors}
                      touched={vehicle_info.touched}
                      handleChange={vehicle_info.handleChange}
                      setFieldValue={vehicle_info.setFieldValue}
                    />
                  )}
                  {active === 2 && (
                    <TuningInfoForm
                      data={tuning_info.values}
                      setData={tuning_info.getFieldProps}
                      errors={tuning_info.errors}
                      touched={tuning_info.touched}
                      customSet={tuning_info.setValues}
                      setFieldValue={tuning_info.setFieldValue}
                    />
                  )}
                  {active === 3 && (
                    <AdditionalTuningForm
                      data={additional_tuning}
                      setData={setAdditionalTuning}
                    />
                  )}
                  {active === 4 && (
                    <FileModifyingForm
                      data={modification}
                      setData={(newData) => {
                        setModification(newData);
                      }}
                      showError={showFileUploadError}
                    />
                  )}

                  {active === 5 && (
                    <NewServiceForm
                      data={modifyservice}
                      setData={setModifiService}
                      fileId={fileId}
                    />
                  )}
                  {active === 6 && (
                    <ExtraInfoForm data={extrainfo} setData={setExtraInfo} />
                  )}
                </div>
                <TabBar
                  setActive={setActive}
                  active={active}
                  handleNext={handleNext}
                  completedSteps={completedSteps}
                  fileId={fileId}
                  vehicle_info={vehicle_info}
                  tuning_info={tuning_info}
                  setAdditionalTuning={setAdditionalTuning}
                  setModification={setModification}
                  setModifiService={setModifiService}
                  setExtraInfo={setExtraInfo}
                  handleExtraInfoSubmit={handleExtraInfoSubmit}
                  extrainfo={extrainfo}
                  resetFormData={resetFormData}
                />
              </Card>
            </div>

            <div className="col-md-4">
              <RightSideCard
                active={active}
                vehicleInfoData={rightSideData}
                additionalTuning={additional_tuning}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const TabBar = ({
  active,
  setActive,
  handleNext,
  completedSteps,
  fileId,
  vehicle_info,
  tuning_info,
  setAdditionalTuning,
  setModification,
  setModifiService,
  handleExtraInfoSubmit,
  setExtraInfo,
  extrainfo,
  resetFormData,
}) => {
  const tabs = [
    { id: 1, icon: "bi bi-car-front-fill" },
    { id: 2, icon: "bi bi-sliders" },
    { id: 3, icon: "bi bi-plus-circle" },
    { id: 4, icon: "bi bi-file-earmark" },
    { id: 5, icon: "bi bi-wrench" },
    { id: 6, icon: "bi bi-info-lg" },
  ]; 
  const dispatch = useDispatch(); 
  const handleComplete = async () => {
  try {
    if (
      !extrainfo?.time_frame ||
      !extrainfo?.tac ||
      !extrainfo?.refund_policy
    ) {
      let newErrors = {};
      if (!extrainfo?.time_frame) {
        newErrors.time_frame = "Please select a time frame";
      }
      if (!extrainfo?.tac) {
        newErrors.tac = "You must agree to the terms and conditions";
      }
      if (!extrainfo?.refund_policy) {
        newErrors.refund_policy = "You must agree to the refund policy";
      }
    setExtraInfo((prev) => ({
        ...prev,
        errors: newErrors,
      }));
      return;
    }
    const token = localStorage.getItem("Access-Token");
    if (!token) {
      window.location.href = "/login";
      return;
    }   
    await handleExtraInfoSubmit();   
    const response = await getFormData(token, { id: fileId });
    let vehicleMake, vehicleModel, tuningType, vehicleYear;
    const tuningTypes = [
      { id: "1", title: "Car Tuning (Stage 1)", credits: 1.0 },
      { id: "2", title: "Car Tuning (Stage 2)", credits: 1.5 },
      { id: "3", title: "Car Tuning (Stage 3)", credits: 3.0},
      { id: "4", title: "Only options (Car)", credits: 0 },
      { id: "7", title: "TCU Tuning", credits: 1.0 },
      { id: "8", title: "Only options (TCU)", credits: 0 },
      { id: "9", title: "E85 Conversion", credits: 2 },
      { id: "10", title: "Back to stock", credits: 0.5 },
      { id: "11", title: "Immo off (If possible)", credits: 1 },
      { id: "12", title: "Tuning file review", credits: 0.2 },
    ];
    const tuningOptionsMap = {
      1: { label: "AdBlue", credits: 1 },
      2: { label: "Anti Lag", credits: 1 },
      3: { label: "Cat Heating", credits: 0.2 },
      4: { label: "Cylinder on Demand off", credits: 0.2 },
      5: { label: "Decat / O2", credits: 0 },
      6: { label: "DPF off", credits: 0 },
      7: { label: "DSG Farts", credits: 0.5 },
      8: { label: "DTC off", credits: 0.2 },
      9: { label: "E85 Conversion", credits: 2 },
      10: { label: "EGR off", credits: 0 },
      11: { label: "Evaporative Emission Control System", credits: 0.2 },
      12: { label: "Exhaust Flaps", credits: 0.2 },
      13: { label: "Hard Cut limiter (diesel)", credits: 0.5 },
      14: { label: "Hot Start", credits: 0.2 },
      15: { label: "Idle RPM", credits: 0.2 },
      16: { label: "Immo off", credits: 1 },
      17: { label: "Injector scaling", credits: 0.7 },
      18: { label: "Launch Control", credits: 0.5 },
      19: { label: "Limited Vmax to specific speed", credits: 0.5 },
      20: { label: "MAF off (if possible)", credits: 0.2 },
      21: { label: "Neutral RPM", credits: 0.2 },
      22: { label: "NOx off", credits: 0.5 },
      23: { label: "OPF off", credits: 0 },
      24: { label: "Performance Gauge BMW/Mini/VAG", credits: 0 },
      25: { label: "Pop & Bang / Crackle map", credits: 1 },
      26: { label: "Readiness Monitor", credits: 0.5 },
      27: { label: "Rev Limiter", credits: 0 },
      28: { label: "Secundairy Air Pump", credits: 0.2 },
      29: { label: "Start / Stop system off", credits: 0.2 },
      30: { label: "Swirl Flaps off", credits: 0.2 },
      31: { label: "Torque Monitoring off", credits: 0 },
      32: { label: "Vmax off", credits: 0 },
      33: { label: "Warranty Patch (BMW/Mini/VAG)", credits: 0.5 },
    };  
    let selectedTuningTypeId;
    let additionalTuningOptions = [];  
    if (response && response.status_code === 200 && response.data) {
      const data = response.data[0];
      vehicleMake = data.make?.name || (vehicle_info.values.make === "otherwise,namely" ? vehicle_info.values.other_make : vehicle_info.values.make);
      vehicleModel = data.model?.name || (vehicle_info.values.model === "otherwise,namely" ? vehicle_info.values.other_model : vehicle_info.values.model);
      selectedTuningTypeId = data.all_fields?.tuning_type || "";
      additionalTuningOptions = data.all_fields?.addition_tuning || [];
    } else {
      vehicleMake = vehicle_info.values.make === "otherwise,namely" ? vehicle_info.values.other_make : vehicle_info.values.make;
      vehicleModel = vehicle_info.values.model === "otherwise,namely" ? vehicle_info.values.other_model : vehicle_info.values.model;
      selectedTuningTypeId = tuning_info?.values?.tuning_type || "";
      additionalTuningOptions = Array.isArray(window.additionalTuning) ? window.additionalTuning : [];
    }
    const tuningTypeCredits = selectedTuningTypeId 
      ? tuningTypes.find(type => type.id === selectedTuningTypeId)?.credits || 0
      : 0;  
    const additionalTuningCredits = additionalTuningOptions.reduce((total, id) => {
      return total + (tuningOptionsMap[id]?.credits || 0);
    }, 0);
    const totalCredits = tuningTypeCredits + additionalTuningCredits; 
    const cartItem = {
      id: fileId,
      type: 'file_service',
      quantity: 1,
      name: `Tuning Service - ${vehicleMake} ${vehicleModel} ${totalCredits}`,
      details: {
        make: vehicleMake,
        model: vehicleModel,
        totalCredits: totalCredits
      },
    };  
    dispatch(addToCart(cartItem)); 
    resetFormData();
    toast.success("Added to cart successfully!");
    setTimeout(() => {
      window.location.href = "/cart";
    }, 100);
  } catch (error) {
    console.error("Error completing form:", error);
    toast.error("Failed to complete form submission");
  }
};
  const handleTabClick = async (tabId) => {
    if (completedSteps.includes(tabId)) {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        console.warn("No token found, redirecting to login");
        window.location.href = "/login";
        return;
      }
      try {
        const payload = { id: fileId };
        const response = await getFormData(token, payload);
        if (!response || response.status_code !== 200 || !response.data) {
          console.error("Invalid API response:", response);
          toast.error("Invalid response from the server");
          return;
        }
        const data = response.data[0];
        const mainFiles = data.all_fields?.files || [];
        const optionalFiles = data.all_fields?.optional_attachment_files || [];
        const formattedMainFiles = mainFiles.map((file) => ({
          filename: file.filename,
          url: file.url,
        }));
        const formattedOptionalFiles = optionalFiles.map((file) => ({
          filename: file.filename,
          url: file.url,
        }));
        if (data?.all_fields) {
          const extraInfoData = {
            time_frame: data.all_fields?.time_frame || "",
            car_dyno: data.all_fields?.car_dyno || "",
            altitude_modify: data.all_fields?.altitude_modify || "",
            addition_information_car:
              data.all_fields?.addition_information_car || "",
            tac: data.all_fields?.tac || "",
            refund_policy: data.all_fields?.refund_policy,
          };

          setExtraInfo(extraInfoData);
        }
        if (tabId === 1) {
          const vehicleData = {
            make: data.make?.id == null ? "otherwise,namely" : data.make?.id,
            other_make: data.all_fields?.other_make || "",
            vehicle_type: data.all_fields?.other_vehicle_type || "",
            model: data.model?.id == null ? "otherwise,namely" : data.model?.id,
            other_model: data.all_fields?.other_model || "",
            generation:
              data.genration?.id == null
                ? "otherwise,namely"
                : data.genration?.id,
            other_generation: data.all_fields?.other_generation || "",
            engine:
              data.engine?.id == null ? "otherwise,namely" : data.engine?.id,
            other_engine: data.all_fields?.other_engine || "",
            ecu:
              data.all_fields?.ecu == null
                ? "otherwise,namely"
                : data.all_fields?.ecu,
            other_ecu: data.all_fields?.other_ecu || "",
            year: data.all_fields?.year || "",
            gearbox: data.all_fields?.garebox || "",
            engine_hp: data.all_fields?.engine_hp || "",
            engine_kw: data.all_fields?.kw || "",
            vin_number: data.all_fields?.vin || "",
          };
          if (vehicle_info) {
            vehicle_info.setValues(vehicleData);
          }
        } else if (tabId === 2) {
          const tuningData = {
            tuning_type: data.all_fields?.tuning_type || "",
            software_no: data.all_fields?.software_no || "",
            hardware_no: data.all_fields?.hardware_no || "",
            read_method:
              data.all_fields?.read_method == null
                ? "otherwise,namely"
                : data.all_fields?.read_method,
            other_read: data.all_fields?.other_read || "",
            email: data.email
              ? "otherwise,namely"
              : data.all_fields?.email || "",
            other_email: data.all_fields?.other_email || "",
          };
          if (tuning_info) {
            tuning_info.setValues(tuningData);
          }
        } else if (tabId === 3 && data?.all_fields?.addition_tuning) {
          if (Array.isArray(data.all_fields?.addition_tuning)) {
            setAdditionalTuning(data.all_fields?.addition_tuning);
          }
        }
        setActive(tabId);
        if (tabId === 4) {
          setModification({
            file: formattedMainFiles,
            additional: formattedOptionalFiles,
          });
        }
        if (tabId === 5) {
          setModifiService({
            file: {
              isModified: data.all_fields?.modified_parts?.length > 0,
            },
            additional: {
              modifications: data.all_fields?.modified_parts || [],
            },
          });
        }
      } catch (error) {
        console.error(`Error fetching data for step ${tabId}:`, error);
        toast.error("Failed to load form data. Please try again.");
      }
      return;
    }

    if (tabId > Math.max(...completedSteps)) {
      toast.warning("Please complete the current step first");
      return;
    }
  };
  return (
    <div className="p-3 step_section d-flex align-items-center justify-content-between">
      <div className="d-flex gap-3">
        {tabs?.map((tab) => (
          <button
            key={tab.id}
            className={`${active > tab.id ? "complete_step_icon" : ""} ${
              active === tab.id ? "activeform_icon" : "stepform_icon"
            } px-2`}
            onClick={() => handleTabClick(tab.id)}
            disabled={!completedSteps.includes(tab.id) && tab.id !== active}
          >
            <i className={`${tab.icon} fs-4`}></i>
          </button>
        ))}
      </div>
      <div>
        {active === 6 ? (
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleComplete}
          >
            Complete
          </button>
        ) : (
          <button
            className="btn btn-light-info"
            type="button"
            onClick={handleNext}
          >
            Go to next step
          </button>
        )}
      </div>
    </div>
  );
};
export default NewFileServices;
