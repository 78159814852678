import React from "react";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import { Badge, Card } from "react-bootstrap";
import Datatable from "../datatable";

const CreditTransaction = () => {
  const data = [
    {
      date: "14-01-2025",
      description: "Master credit purchase",
      type: "Master Credits",
      credits: 1,
      status: "Pending",
    },
  ];

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Credits",
      selector: (row) => `➜ ${row.credits}`,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          className="badge_design"
          bg={row.status === "Pending" ? "warning" : "success"}
          text={row.status === "Pending" ? "dark" : "light"}
        >
          {row.status}
        </Badge>
      ),
    },
  ];

  return (
    <Wrapper>
      <TopCrumb route={"Credit transactions"} />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <div className="row">
            <div
              className="col-lg-6 col-md-5 col-12"
              style={{ height: "auto" }}
            >
              <Card className="credit_cards">
                <div className="Dashboard_heading_2">
                  <h4 className="Dashboard_heading_1">
                    {" "}
                    <i className="bi bi-credit-card"></i> Master credits
                  </h4>
                  <svg
                    className="icon_arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                  </svg>
                </div>
                <h3 className="mb-3">0.00</h3>
                <p className="mb-0 text-muted">0 reserved</p>
                <p className="mb-0 text-muted">1.00 waiting for payment</p>
              </Card>
            </div>
            <div
              className="col-lg-6 col-md-5 col-12"
              style={{ height: "auto" }}
            >
              <Card className="credit_cards">
                {" "}
                <div className="Dashboard_heading_2">
                  <h4 className="Dashboard_heading_1">
                    <i className="bi bi-credit-card"></i> EVC credits
                  </h4>
                  <svg
                    className="icon_arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                  </svg>
                </div>
                <p className="mb-0 text-muted">
                  EVC is not enabled for your account. You can enable it when
                  editing your profile.
                </p>
              </Card>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <Card className="mt-3 p-3">
            <Datatable title="Transactions" columns={columns} data={data} />
          </Card>
        </div>
      </div>
    </Wrapper>
  );
};

export default CreditTransaction;
