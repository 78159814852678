import React from "react";
import MainHeader from "../header/Header";
import MainFooter from "../footer/Footer";
import "../../assets/css/chiptuningfile.css";
import alientectlogo from "../../assets/images/alientechlogo.jpg";
import autotuner from "../../assets/images/autotuner.jpg";
import cmdlogo from "../../assets/images/cmdlogo.jpg";
import magicmotorsportlogo from "../../assets/images/magicmotorsportlogo.jpg";
import credit from "../../assets/images/credit.png";
import cars from "../../assets/images/cars.jpg";
import car from "../../assets/images/car.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ModelSearchFormSecond from "./ModelSearchFormSecond";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Chiptuningfile = () => {
  const location = useLocation();

  const engineData = location.state?.engineData || {};
  const powerData = engineData.power_object || {};
  const torqueData = engineData.torque_object || {};
  const PrographData = engineData.dynograph_data || {};
  const engineDetails = engineData.engine_details || {}
  const powerChartData = {
    labels: ["Original", "Stage 1"],
    datasets: [
      {
        label: "Power (HP)",
        data: [
          parseFloat(powerData.original) || 0,
          parseFloat(powerData.dyn_chiptuning) || 0
        ],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };
  const torqueChartData = {
    labels: ["Original", "Stage 1"],
    datasets: [
      {
        label: "Torque (Nm)",
        data: [
          parseFloat(torqueData.original) || 0,
          parseFloat(torqueData.dyn_chiptuning) || 0
        ],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 50,
        },
      },
    },
  }
  const data1 = {
    labels: ["Original", "Stage1"],
    datasets: [
      {
        label: "Sales ($)",
        data: [50, 150, 200, 250, 300], // Adjusted to fit the range
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };


  return (
    <>
      <section>
        <MainHeader />
      </section>

      <section className="chiptuningfile_page">
        <section className="chiptuningfile_image">
          <div className="container">
            <div className="row">
              <div className="col-12 chiptuningfile_heading">
                <h2 className="AUDI_heading">CHIPTUNING FILE</h2>
                <h1 className="AUDI_heading">AUDI A1 1.4 TDI 90HP</h1>
              </div>
            </div>
          </div>
        </section>

        <div class="section chiptuning-files-results connect-to-next inview">
          <div class="container container--large">
            <div class="section_title chiptuning-files-results-header mb-5">
              <h2>Chiptuning file results</h2>
            </div>
            <div class="row chiptuning-files-results-row">
              <div class="col-4 chiptuning-files-results-col">
                <div class="chiptuning-files-results-graph">
                  <div class="chiptuning-files-results-graph-chart">
                    <div class="chart-wrapper">
                    <Bar data={powerChartData} options={chartOptions} />
                    </div>
                  </div>
                  <div class="chiptuning-files-results-graph-data">
                    <h3>Power hp</h3>
                    <div class="row chiptuning-files-results-graph-row">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Original
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col">
                        {powerData.original || "N/A"}
                      </div>
                    </div>
                    <div class="row chiptuning-files-results-graph-row">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Pro Chiptuning
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col highlighted">
                        {powerData.dyn_chiptuning || "N/A"}
                      </div>
                    </div>
                    <div class="row chiptuning-files-results-graph-row difference">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Difference{" "}
                        <span data-tippy-content="Results are based on low mileage, well maintained cars under ideal circumstances"></span>
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col">
                        {powerData.difference || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 chiptuning-files-results-col">
                <div class="chiptuning-files-results-graph">
                  <div class="chiptuning-files-results-graph-chart">
                    <div class="chart-wrapper">
                    <Bar data={torqueChartData} options={chartOptions} />
                    </div>
                  </div>
                  <div class="chiptuning-files-results-graph-data">
                    <h3>Torque Nm</h3>
                    <div class="row chiptuning-files-results-graph-row">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Original
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col">
                        {torqueData.original || "N/A"}
                      </div>
                    </div>
                    <div class="row chiptuning-files-results-graph-row">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Pro Chiptuning
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col highlighted">
                        {torqueData.dyn_chiptuning || "N/A"}
                      </div>
                    </div>
                    <div class="row chiptuning-files-results-graph-row difference">
                      <div class="col-8 chiptuning-files-results-graph-col">
                        Difference
                      </div>
                      <div class="col-4 chiptuning-files-results-graph-col">
                        {torqueData.difference || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 chiptuning-files-results-col">
                <div class="chiptuning-files-results-dyno-graph-image">
                  <a
                    href="https://api.dyno-chiptuningfiles.com/assets/968860f7-f2fa-41f3-b7a1-85b1b18808c6/dynograph/12292/lightbox?_gl=1*1hvuz5t*_gcl_au*ODA0NTUyMTQyLjE3Mzc2MTQ4NDY."
                    data-fancybox="Dynograph"
                  >
                    {/* <img
                      src="https://api.dyno-chiptuningfiles.com/assets/968860f7-f2fa-41f3-b7a1-85b1b18808c6/dynograph/12292/big"
                      alt="Dynograph"
                    /> */}
                  </a>
                </div>
                {/* <div class="chiptuning-files-results-cta">
                  <div class="chiptuning-files-results-cta-inner">
                    <h3>Need access to this file?</h3>
                    <p>
                      Create your account and start using our Acura tuning files
                      today
                    </p>
                    <p>
                      <a
                        class="btn btn--primary"
                        href="https://www.dyno-chiptuningfiles.com/account/en/login"
                        title="Click here to create your own account"
                      >
                        Register now
                      </a>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row p-4 chiptuningfile_responisive">
            <div className="col-3 my-5 chiptuningfile_responisive_1">
              <h1 className="FILE_GUARANTEE">
                <span className="FILE_GUARANTEE_1">100% CUSTOM</span> MADE
                TUNING FILE GUARANTEE
              </h1>
            </div>
            <div className="col-3 my-5 chiptuningfile_responisive_1">
              <h1 className="FILE_GUARANTEE">
                TESTED AND DEVELOPED ON A{" "}
                <span className="FILE_GUARANTEE_1"> 4X4 DYNAMOMETER</span>
              </h1>
            </div>
            <div className="col-3 my-5 chiptuningfile_responisive_1">
              <h1 className="FILE_GUARANTEE">
                <span className="FILE_GUARANTEE_1">BEST RESULTS</span>WITHIN THE
                SAFETY MARGINS
              </h1>
            </div>
            <div className="col-3 my-5 chiptuningfile_responisive_1">
              <h1 className="FILE_GUARANTEE">
                A DECREASE IN{" "}
                <span className="FILE_GUARANTEE_1">FUEL CONSUMPTION</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 my-5">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      ENGINE SPECIFICATIONS
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body chiptuningfile_responisive">
                      {engineDetails &&
                      Object.keys(engineDetails)?.length > 0 ? (
                        <>
                          <ul className="accordion_list chiptuningfile_responisive_1">
                            <li>Type Of Fuel</li>
                            <li>Method</li>
                            <li>Tuning Type</li>
                            <li>Cylinder Content</li>
                            <li>Engine ECU</li>
                          </ul>
                          <ul className="accordion_list chiptuningfile_responisive_1">
                            <li>{engineDetails["Type of fuel"] || "N/A"}</li>
                            <li>{engineDetails["Method"] || "N/A"}</li>
                            <li>{engineDetails["Tuning type"] || "N/A"}</li>
                            <li>
                              {engineDetails["Cylinder content"] || "N/A"}
                            </li>
                            <li>{engineDetails["Engine ECU"] || "N/A"}</li>
                          </ul>

                          <ul className="accordion_list chiptuningfile_responisive_1">
                            <li>Compression Ratio</li>
                            <li>Bore X Stroke</li>
                            <li>Engine Number</li>
                          </ul>
                          <ul className="accordion_list chiptuningfile_responisive_1">
                            <li>
                              {engineDetails["Compression ratio"] || "N/A"}
                            </li>
                            <li>{engineDetails["Bore X stroke"] || "N/A"}</li>
                            <li>{engineDetails["Engine number"] || "N/A"}</li>
                          </ul>
                        </>
                      ) : (
                        <p>No engine specifications available</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      READ METHODS
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body chiptuningfile_responisive">
                      <div className="logo_background">
                        <a href="">
                          <img
                            src={alientectlogo}
                            className="mt-2"
                            alt="alientectlogo"
                            width={100}
                            height={40}
                          />
                        </a>
                        <div className="accordion_logo">
                          <p className="">
                            <span className="accordion_logo_span">
                              Alientech
                            </span>{" "}
                            kessv2
                          </p>
                        </div>
                      </div>
                      <div className="logo_background">
                        <img
                          src={autotuner}
                          className="mt-2"
                          alt="alientectlogo"
                          width={100}
                          height={40}
                        />
                        <div className="accordion_logo">
                          <p className="">
                            <span className="accordion_logo_span">
                              Autotuner
                            </span>{" "}
                            Autotuner{" "}
                          </p>
                        </div>
                      </div>
                      <div className="logo_background">
                        <img
                          src={cmdlogo}
                          className="mt-2"
                          alt="alientectlogo"
                          width={100}
                          height={40}
                        />
                        <div className="accordion_logo">
                          <p className="">
                            <span className="accordion_logo_span">cmd</span> cmd
                            flash
                          </p>
                        </div>
                      </div>
                      <div className="logo_background">
                        <img
                          src={magicmotorsportlogo}
                          className="mt-2"
                          alt="alientectlogo"
                          width={40}
                          height={40}
                        />
                        <div className="accordion_logo_magic">
                          <p className="magic_flex">
                            <span className="accordion_logo_span">
                              MagicMotorsport
                            </span>{" "}
                            flex
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      ADDITIONAL OPTIONS
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body chiptuningfile_responisive">
                      <div className="accordion_card">
                        <div className="icon_logo">i</div>
                        <img src={credit} alt="credit" width={60} height={60} />
                        <h2 className="my-2 accordion_card_heading">EGR OFF</h2>
                      </div>
                      <div className="accordion_card">
                        <div className="icon_logo">i</div>
                        <img src={credit} alt="credit" width={60} height={60} />
                        <h2 className="my-2 accordion_card_heading">EGR OFF</h2>
                      </div>
                      <div className="accordion_card">
                        <div className="icon_logo">i</div>
                        <img src={credit} alt="credit" width={60} height={60} />
                        <h2 className="my-2 accordion_card_heading">EGR OFF</h2>
                      </div>
                      <div className="accordion_card">
                        <div className="icon_logo">i</div>
                        <img src={credit} alt="credit" width={60} height={60} />
                        <h2 className="my-2 accordion_card_heading">EGR OFF</h2>
                      </div>
                    </div>
                    <div className="accordion-body">
                      <div className="accordion_card">
                        <div className="icon_logo">i</div>
                        <img src={credit} alt="credit" width={60} height={60} />
                        <h2 className="my-2 accordion_card_heading">EGR OFF</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row chiptuningfile_responisive">
            <div className="col-6 my-5 chiptuningfile_responisive_1">
              <h1 className="magic_CHIPTUNING">
                CHIPTUNING FILE FOR AUDI A1 1.4 TDI 90HP
              </h1>
              <p className="magic_CHIPTUNING_pra">
                Pro-ChiptuningFiles.com leads the field in developing
                high-quality tuning files for Audi A1 1.4 TDI 90hp
                configurations. Each Audi A1 1.4 TDI 90hp tuning file is
                custom-made and thoroughly tested on a 4x4 state-of-the-art
                dynamometer to ensure maximum engine performance and
                reliability. Our experienced programmers leverage the latest
                advancements in ECU remapping software, creating each Audi A1
                1.4 TDI 90hp tuning file with precision and dedication.
              </p>
              <p className="magic_CHIPTUNING_pra">
                Trusted by chiptuning companies worldwide,
                Pro-ChiptuningFiles.com provides Audi A1 1.4 TDI 90hp ECU
                tuning files designed for top-tier results within safe operating
                limits. Our tuning files are engineered to enhance power, fuel
                efficiency, and overall performance for Audi A1 1.4 TDI 90hp
                vehicles, meeting the highest standards in custom ECU tuning.
              </p>
            </div>
            <div className="col-6 my-5 chiptuningfile_responisive_1">
              <img
                src={cars}
                alt="cars"
                width={500}
                height={600}
                className="box_image_audi"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="pragraph_access my-5 chiptuningfile_responisive_1">
                NEED ACCESS TO OUR AUDI A1 TUNING FILES?
              </h2>
              <h1 className="pragraph_access_1 chiptuningfile_responisive_1">
                <span className="pragraph_access_2">CREATE YOUR ACCOUNT</span>{" "}
                AND START USING OUR AUDI A1 TUNING FILES TODAY
              </h1>
              <button className="Register_Now my-5 Larger shadow">
                <a href="/register" className="Register_Now_1">
                  Register Now
                </a>
              </button>
              <p className="pragraph_access_3 my-5 chiptuningfile_responisive_1">
                Please find also our complete chiptuning tools / tuning tools
                packages. Contact us for the best possible deals! Please feel
                free to contact us for additional information about Audi A1
                tuning files via the{" "}
                <span className="pragraph_access_4">contact form.</span>
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row chiptuningfile_responisive">
            <h1 className="pragraph_access_6 ">BACK TO</h1>
            <div className="col-3 pragraph_access_5 my-5 chiptuningfile_responisive_1">
              Custom remapped files
            </div>
            <div className="col-3 pragraph_access_5  my-5 chiptuningfile_responisive_1">
              Audi
            </div>
            <div className="col-3 pragraph_access_5  my-5 chiptuningfile_responisive_1">
              A1
            </div>
            <div className="col-3 pragraph_access_5  my-5 chiptuningfile_responisive_1">
              8X - 2015 - 2017
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row responsive_alientech">
            <div className="col-4 mt-5 responsive_tab">
              <img
                src={car}
                alt="car"
                width={500}
                height={300}
                className="box_image"
              />
            </div>
            <div className="col-8 my-5 responsive_tab_1">
              {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg ' href="/chiptuningfile">Start Seaching</button>
                            </form> */}
              <ModelSearchFormSecond />
            </div>
          </div>
        </div>
      </section>
      <section>
        <MainFooter />
      </section>
    </>
  );
};

export default Chiptuningfile;
