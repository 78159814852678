import React from "react";
import FileUploader from "../../../ui/FileUploader";

const FileModifyForm = ({ data, setData,showError=false }) => {
  const handler = (value) => {
    setData(value);
  };

  return (
    <div className="row">
      <form>
        <div>
          <span className="fw-semibold">Files to Modify</span>
          <div className="my-3">
            <FileUploader attachments={data} handleFiles={handler} showError={showError} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FileModifyForm;
