import React from "react";
import Wrapper from "./Wrapper";
import "../../assets/css/supportTicket.css";
import TopCrumb from "../header/TopCrumb";
import { Card } from "react-bootstrap";
import SearchInput from "../ui/SearchInput";
import { useNavigate } from "react-router-dom";
const SupportTickets = () => {
  const [query, setQuery] = React.useState("");
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TopCrumb route={"Support tickets"} />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <Card className="p-4">
            <div className="Dashboard_heading_2">
              <h4 className="Dashboard_heading_1">Create Support Ticket</h4>
              <svg
                className="icon_arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
              </svg>
            </div>
            <div>
              <div className="row">
                <div className="col-md-10">
                  <SearchInput query={query} setQuery={setQuery} />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={() => {
                      navigate("../create-ticket");
                    }}
                    className="w-100 btn btn-light-info"
                  >
                    Create New Ticket
                  </button>
                </div>
              </div>
              <div className="mt-3">
                <span className="fs-6 no-data">No Data Found</span>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Wrapper>
  );
};

export default SupportTickets;
