import React from 'react'
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/alientechtools.css"
import box from "../../assets/images/box.png"
import tools from "../../assets/images/tools.jpg"
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';
const AlienTechTools = () => {

    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='alientechtools_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='alientech_content'>
                                <h1 className='heading_alientech'>ALIENTECH TOOLS</h1>
                                <p className='pragraph_alientech'>ALIENTECH IS AN ITALIAN COMPANY THAT SINCE 1991 IS CARRYING OUT IMPROVEMENT IN THE FIELD OF ENGINE TUNING, BOTH FOR UTILITY AND RACING CARS.</p>
                                <p className='pragraph_alientech_1'>Thanks to tools and software developed by a highly skilled team of engineers, today Alientech is one of the landmarks in the chiptuning industry. Alientech has a goal, clear and well-defined: "What we want is easy to explain: to study, design and develop the best tools able to satisfy what the market demands. For this reason, every single day we invest time and resource to find out the best solutions for our customers of today and tomorrow." From a small local Italian company, Alientech has always kept on growing and today has five international branches, hundreds of retailers and thousands of customers worldwide, becoming a real landmark in the field of engine tuning. Their goal is to keep working that way: to develop and improve their tools and software at their best, either from a professional or competitive point of view.</p>
                                <p className='pragraph_alientech_2'>Curious about Alientech's assortment? Click the blue button below or <a href="" className='Alientech_link'>click here.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 my-5 responsive_tab'>
                            <img src={box} alt="" width={400} height={500} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab'>
                            <h1 className='heading_alientech_1'>ALIENTECH HAS RECENTLY INTRODUCED KESS3.</h1>
                            <p className='pragraph_alientech_3'>KESS3 is the successor of Alientech’s popular KESSv2 and K-tag tools. With the KESS3, Alientech has combined the KESSv2 and K-TAG tool in one tool, so you don’t have to purchase two separate tools to be able to use multiple read methods. The new tuning tool KESS3 is now available!</p>
                            <h1 className='heading_alientech_2'>KESS3 New Features</h1>
                            <p className='pragraph_alientech_3'>The KESS3 tool comes with awesome new features, and we honestly can say that Alientech did a great job! Ok, so what are the new features:</p>
                            <ul className='pragraph_alientech_3'>
                                <li >OBD, BOOT / Bench all in one tool</li>
                                <li>ECU cloning option</li>
                                <li>Bluetooth features</li>
                                <li>GPS features</li>
                                <li>Brand new Alientechsuite Interface</li>
                                <li>Interface customization options</li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-8 my-5 responsive_tab'>
                            <h1 className='heading_alientech_1'>SUBSCRIPTION & PROTOCOLS </h1>
                            <p className='pragraph_alientech_3'>Alientech has made changes in their subscription structure because they wanted to simplify the structure in order to meet customers needs in a better way. Therefore, they decided to separate the subscriptions, so customers now are able to choose one particular category of vehicle and still are able to combine them.</p>
                            <p className='pragraph_alientech_3'>For example, if you had the car – bike protocol before, you need to purchase the Car – LCV protocol but also have to purchase the Bike – ATV & UTV protocol. Be aware of these changes! If you have doubts which subscription to choose, please contact us, and we will assist you!</p>
                            <p className='pragraph_alientech_3'>New protocols are structured as following:</p>
                            <ul className='pragraph_alientech_3'>
                                <li >Car – LCV</li>
                                <li>Bike – ATV & UTV</li>
                                <li>Bluetooth features</li>
                                <li>Marine & PWC</li>
                            </ul>
                        </div>
                        <div className='col-4 my-5 responsive_tab'>
                            <img src={tools} alt="" width={400} height={530} className='box_image' />
                        </div>
                    </div>
                </div>
                <div className='container my-3'>
                    <div className='row'>
                        <div className='col-12 '>
                            <p className='pragraph_alientech_4'>Curious about Alientech's assortment? Click the blue button below or <a href="" className='Alientech_link_1'>click here.</a></p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-8 my-5 speedometer_image'>
                            <div className='p-5 pragraph_alientech_7'>
                                <p className='pragraph_alientech_6 '>CURIOUS ABOUT ALIENTECH'S ASSORTMENT?</p>
                                <p className='pragraph_alientech_6 '>SUBSCRIPTION & PROTOCOLS </p>
                                <p className='pragraph_alientech_6 '>the best and fast webshop provider by the </p>
                                <p className='pragraph_alientech_6 '>best experience and technology</p>
                                <button className='Shop_alientech   shadow-lg '>Shop alientech</button>
                                <div className='worldwide_shiping my-4'>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>worldwide shiping</h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>premium brands </h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'> high quality chiptuning tools</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default AlienTechTools