import React, { useState, useEffect } from 'react';

const ModifiedService = ({ initialData, onChange }) => {
  const options = [
    "Aftermarket",
    "Exhaust",
    "Intake Manifold",
    "Pistons",
    "Suspension",
    "Turbocharger",
    "Fuel Injectors",
  ];

  const [carModified, setCarModified] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [remarks, setRemarks] = useState({});
  useEffect(() => {
    if (initialData) {
     
      const isModified = initialData.file?.isModified;
      setCarModified(isModified ? 'yes' : 'no');
      if (initialData.additional?.modifications) {
        const newSelectedOptions = {};
        const newRemarks = {};

        initialData.additional.modifications.forEach(mod => {
          if (mod.type) {
            newSelectedOptions[mod.type] = true;
            if (mod.remarks) newRemarks[mod.type] = mod.remarks;
          }
        });

        setSelectedOptions(newSelectedOptions);
        setRemarks(newRemarks);
      }
    }
  }, []);

  useEffect(() => {
    const modificationData = {
      file: {
        isModified: carModified === 'yes',
      },
      additional: {
        modifications: Object.entries(selectedOptions)
          .filter(([_, checked]) => checked)
          .map(([option]) => ({
            type: option,
            remarks: remarks[option] || '',
          })),
      },
    };
  
    onChange(modificationData);
  }, [carModified, selectedOptions, remarks, onChange]); 

  const handleSelectChange = (e) => {
    const value = e.target.value;

    setCarModified(value);
    
    if (value === 'no') {
      setSelectedOptions({});
      setRemarks({});
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedOptions((prev) => ({
      ...prev,
      [value]: checked,
    }));

    if (!checked) {
      setRemarks((prev) => {
        const newRemarks = { ...prev };
        delete newRemarks[value];
        return newRemarks;
      });
    }
  };

  const handleRemarksChange = (e, option) => {
    const { value } = e.target;
    setRemarks((prev) => ({
      ...prev,
      [option]: value,
    }));
  };

  return (
    <div className="mx-auto p-6">
      <div className="mb-6">
        <label htmlFor="car-modified" className="text-lg font-semibold text-gray-700">
          Does the car have modified parts?
        </label>
        <select
          id="car-modified"
          value={carModified}
          onChange={handleSelectChange}
          className="form-select my-2 p-2 border rounded-md w-full"
        >
          <option value="">Make your choice</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      {carModified === 'yes' && (
        <div className="space-y-4">
          {options?.map((option) => (
            <div key={option} className="flex flex-col space-y-3">
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions[option] || false}
                  onChange={handleCheckboxChange}
                  className="FancyCheckbox"
                />
                <label className="form-check-label text-gray-700 text-base">{option}</label>
              </div>
              
              {selectedOptions[option] && (
                <div className="ml-4 space-y-3">
                  <div>
                    <label htmlFor={`remarks-${option}`} className="text-sm font-semibold text-gray-600">
                      Remarks (optional)
                    </label>
                    <textarea
                      id={`remarks-${option}`}
                      value={remarks[option] || ''}
                      onChange={(e) => handleRemarksChange(e, option)}
                      className="form-control mt-2 p-2 border rounded-md w-full"
                      placeholder="Enter remarks here..."
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


export default ModifiedService;
