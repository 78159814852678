import React, { useState, useEffect } from "react";
import Wrapper from "./Wrapper";
import TopCrumb from "../header/TopCrumb";
import { Badge, Button, Card, Form } from "react-bootstrap";
import SearchInput from "../ui/SearchInput";
import Datatable from "./datatable";
import { useNavigate } from "react-router-dom";
import { listOrders } from "../../api";

const OrderHistory = () => {
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    category: "",
    status: "",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('Access-Token');
        
        if (!token) {
          return new Error('No authentication token found');
        }

        const response = await listOrders(token);
        if (response.status_code === 200 && response.data) {
          setOrders(response.data);
          setLoading(false);
        } else {
          return new Error(response.message || 'Failed to retrieve orders');
        }
      } catch (err) {
        console.error('Failed to fetch orders:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filteredOrders = orders.filter((order) => {
    const matchesQuery = query === '' || 
      order.transaction_id.toLowerCase().includes(query.toLowerCase());
    const matchesStatus = 
      filters.status === '' || 
      filters.status === 'Order status: all statuses' || 
      order.status.toLowerCase() === filters.status.replace('Order status: ', '').toLowerCase()
    const orderDate = new Date(order.updated_at * 1000);
    const matchesDateRange = 
      (!startDate || orderDate >= new Date(startDate)) &&
      (!endDate || orderDate <= new Date(endDate));

    return matchesQuery && matchesStatus && matchesDateRange;
  });

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.updated_at),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Provider",
      selector: (row) => row.payment_type === "direct" ? "Direct File-Service" : row.payment_type,
      sortable: true,
    },    
    {
      name: "Description",
      selector: (row) => {
        const creditInfo = row.credit && row.credit.length > 0 
          ? `${row.credit[0].value}, ${row.credit[0].type}`
          : '';
    
        const plusCredit = row.plus_credit ? row.plus_credit : '';
        const Type = row.pay_mode ? row.pay_mode.replace("file_service", "file-service") : '';
    
        return `${creditInfo} ${plusCredit} ${Type}`;
      },
      sortable: true,
    }
       
  ];

  if (loading) {
    return (
      <Wrapper>
        <div className="text-center mt-5">Loading orders...</div>
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <div className="text-center mt-5 text-danger">
          Error loading orders: {error}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TopCrumb route="Order history" />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <Card className="p-4">
            <div className="row">
              <div className="col-md-4 col-lg-4">
                <SearchInput 
                  query={query} 
                  setQuery={setQuery} 
                  placeholder="Search by Transaction ID"
                />
              </div>
              <div className="col-md-2 col-lg-2">
                <Form.Select
                  name="status"
                  onChange={(e) =>
                    setFilters({ ...filters, status: e.target.value })
                  }
                  value={filters.status}
                >
                  <option value="Order status: all statuses">
                    All Statuses
                  </option>
                  <option value="Order status: completed">Completed</option>
                  <option value="Order status: pending">Pending</option>
                </Form.Select>
              </div>
              <div className="col-md-2 col-lg-2">
                <div className="position-relative">
                  <div
                    className="form-select"
                    style={{ cursor: "pointer" }}
                    onClick={toggleDropdown}
                  >
                    Select Dates
                  </div>
                  {isDropdownOpen && (
                    <div
                      className="drop-menu p-3 shadow"
                      style={{
                        display: "block",
                        position: "absolute",
                        width: "100%",
                        zIndex: 1050,
                        background: "white",
                      }}
                    >
                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="endDate" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-2 col-lg-2">
                <Button
                  onClick={() => {
                    navigate("/export-invoice");
                  }}
                  className="w-100 btn btn-light-info"
                >
                  Export invoice
                </Button>
              </div>
            </div>
          </Card>

          <div className="col-md-12">
            <Card className="p-4">
              <Datatable 
                title="Transactions" 
                columns={columns} 
                data={filteredOrders} 
              />
            </Card>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default OrderHistory;