import React, { useState, useEffect } from "react";
import Wrapper from "./Wrapper";
import { Tab } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { LineChart } from "@mui/x-charts/LineChart";
import { getNews, getProfile } from "../../api";
import { Link } from "react-router-dom";
const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("GROWING_NUMBERS");
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [credits, setCredits] = useState();
  const[email,setEmail]=useState([]);
  const [name,setName]=useState([]);
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await getNews(token);

        if (response.success && response.data) {
          setNews(response.data);
        } else {
          throw new Error(response.message || "Failed to retrieve news");
        }
      } catch (err) {
        setError(err);
      }
    };

    const fetchCredits = async () => {
      setLoading(true);

      try {
        const response = await getProfile();
        console.log("🚀 ~ fetchCredits ~ response:", response)

        if (response?.status_code === 200 && response?.detail) {
          const name=response?.detail?.name
          const email=response?.detail?.email
          console.log("🚀 ~ fetchCredits ~ email:", email)
          const userCredits = response.detail.credit || [];
          const masterCredits = userCredits.filter(
            (credit) => credit.type?.includes("master-credits")
          );
          setName(name)
  setEmail(email)
          setCredits(masterCredits);
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching credits:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchNews();
    fetchCredits();
  }, []);
  const filterNewsByType = (type) => {
    return news.filter((newsItem) => newsItem.news_type === type);
  };
  const getContentBeforeSeparator = (description) => {
    if (!description) return "";
    const parts = description.split("********************");
    return parts[0] || "";
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      return dateString;
    }
  };

  return (
    <Wrapper>
      <div className="row my-1 p-3">
        <div className="user-name">
          <h1 className="Dashboard_heading ">Welcome Back {name}</h1>
        </div>
        <div className="col-12 Dashboard_tab m-auto">
          <div className="row">
            <div className="col-8">
              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">File services</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </div>
                      <div className="icon_arrow_heading_main my-3">
                        <div className=" icon_arrow_heading_1">
                          <svg
                            className="icon_arrow_file"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" />
                          </svg>
                          <div className="icon_arrow_heading_zero">
                            <p>Total</p>
                            <p>0</p>
                          </div>
                        </div>
                        <div className=" icon_arrow_heading_1">
                          <svg
                            className="icon_arrow_file"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" />
                          </svg>
                          <div className="icon_arrow_heading_zero">
                            <p>Total</p>
                            <p>0</p>
                          </div>
                        </div>
                      </div>
                      <div className="chart_box my-4">
                        <LineChart
                          xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                          series={[
                            {
                              data: [2, 5.5, 2, 8.5, 1.5, 5],
                            },
                          ]}
                          width={500}
                          height={220}
                        />
                      </div>
                      <button className="chart_btn btn btn-light-info">
                        New file service
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_4">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">Opening hours</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </div>
                      <div className="icon_arrow_heading_one my-3">
                        <div className=" table-responsive">
                          <table className="table table-modern table-hover">
                            <thead>
                              <tr>
                                <th>&nbsp;</th>
                                <th>
                                  This week{" "}
                                  <i
                                    className="bi bi-info-circle"
                                    data-tooltip="20 January 2025 - 26 January 2025"
                                  ></i>
                                </th>
                                <th>
                                  Next week{" "}
                                  <i
                                    className="bi bi-info-circle"
                                    data-tooltip="27 January 2025 - 2 February 2025"
                                  ></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-muted">
                                <td>Monday</td>
                                <td>08:00 - 18:00</td>
                                <td>08:00 - 18:00</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Tuesday</td>
                                <td>08:00 - 18:00</td>
                                <td>08:00 - 18:00</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Wednesday</td>
                                <td>08:00 - 18:00</td>
                                <td>08:00 - 18:00</td>
                              </tr>
                              <tr className="">
                                <td>Thursday</td>
                                <td>08:00 - 18:00</td>
                                <td>08:00 - 18:00</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Friday</td>
                                <td>08:00 - 18:00</td>
                                <td>08:00 - 18:00</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Saturday</td>
                                <td>09:00 - 16:00</td>
                                <td>09:00 - 16:00</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Sunday</td>
                                <td>
                                  <em>Closed</em>
                                </td>
                                <td>
                                  <em>Closed</em>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_1 ">
                      <div className="Dashboard_heading_2 ">
                        <h4 className="Dashboard_heading_1">
                          Welcome Back {name}
                        </h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                      </div>
                      <div className="edit_profile my-3">
                        <div className="Dashboard_prifile_edit">kk</div>
                        <div className="user_details">
                          <p>
                            <b>E-mail:</b> {email}
                          </p>
                          <p>
                            <b>Client ID: </b>65655
                          </p>
                          <button className="Edit_Profile_btn btn btn-light-info">
                            <a
                              href="/editprofile"
                              className="Edit_Profile_dash"
                            >
                              Edit Profile
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_3">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">My credits</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </div>
                      <div className="icon_arrow_heading_main my-3">
                        <div className=" icon_arrow_heading_cridit">
                          Master credits
                          <div className="zero_tab_icon my-2">
                            {credits && credits.length > 0 ? (
                              credits.map((credit, index) => (
                                <p key={index}>{credit.value}</p>
                              ))
                            ) : (
                              <p>0</p>
                            )}
                            <span className="exclamation_icon_credit">
                              <svg
                                className="exclamation_icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 128 512"
                              >
                                <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <Link
                        to="/buy-credits"
                        className="chart_btn btn btn-light-info"
                      >
                        Buy Credits
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_3">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">Support tickets</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </div>
                      <div className="icon_arrow_heading_main my-3">
                        <div className=" icon_arrow_heading_1">
                          <svg
                            className="icon_arrow_file"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" />
                          </svg>
                          <div className="icon_arrow_heading_zero">
                            <p>Total tickets</p>
                            <p>0</p>
                          </div>
                        </div>
                        <div className=" icon_arrow_heading_1">
                          <svg
                            className="icon_arrow_file"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" />
                          </svg>
                          <div className="icon_arrow_heading_zero">
                            <p>Open tickets</p>
                            <p>0</p>
                          </div>
                        </div>
                      </div>

                      <button className="chart_btn btn btn-light-info">
                        New support ticket
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_6">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">Useful links</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                      </div>
                      <ul className="">
                        <li>
                          <a className="Useful_links_1" href="">
                            https://tuning-shop.com
                          </a>
                        </li>
                        <li>
                          <a className="Useful_links_1" href="">
                            https://tuning-shop.com
                          </a>
                        </li>
                        <li>
                          <a className="Useful_links_1" href="">
                            https://tuning-shop.com
                          </a>
                        </li>
                        <li>
                          <a className="Useful_links_1" href="">
                            https://tuning-shop.com
                          </a>
                        </li>
                        <li>
                          <a className="Useful_links_1" href="">
                            https://tuning-shop.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_4">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">
                          Current time in the Netherlands
                        </h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </div>
                      <div className="chart_btn_circle">
                        <div className="chart_btn_timer my-3">
                          <div className="chart_timer">1</div>
                          <div className="chart_timer">1</div>:
                          <div className="chart_timer">5</div>
                          <div className="chart_timer">4</div>:
                          <div className="chart_timer">5</div>
                          <div className="chart_timer">1</div>
                        </div>
                        <div className="chart_btn_circle_borber"></div>
                      </div>
                      <div className="my-4 chart_btn_two">
                        <button className="chart_btn_1 btn btn-light-danger">
                          Closed
                        </button>
                        <button className="chart_btn_2 btn btn-light-success">
                          Open
                        </button>
                      </div>

                      <p className="Contact_us">
                        <strong>Contact us for support: +31 35 820 0967</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="col-12">
                <div className="card card-stretch-full border border-1">
                  <div className="card-body d-flex align-items-center">
                    <div className="File_services_7">
                      <div className="Dashboard_heading_2">
                        <h4 className="Dashboard_heading_1">News</h4>
                        <svg
                          className="icon_arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                      </div>

                      {loading ? (
                        <div>Loading news...</div>
                      ) : error ? (
                        <div>Error loading news: {error.message}</div>
                      ) : (
                        <Tabs
                          defaultActiveKey="all"
                          id="justify-tab-example"
                          className="mb-3 news_tabs"
                          justify
                        >
                          <Tab eventKey="all" title="All">
                            <div className="deshborad_pragraph_euro">
                              {news.slice(0, 3).map((newsItem) => (
                                <div
                                  key={newsItem._id}
                                  className="Tuningfile_silder"
                                >
                                  <p className="text-muted">
                                    {newsItem.news_type}
                                  </p>
                                  <h5>{newsItem.title}</h5>
                                  <strong>
                                    <small className="text-muted">
                                      {formatDate(newsItem.create_date)}
                                    </small>
                                  </strong>
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      getContentBeforeSeparator(
                                        newsItem.description
                                      )
                                    )}
                                  />
                                  <Link
                                    to={`/car-overview/${newsItem.slug}`}
                                    className="btn btn-primary"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </Tab>
                          <Tab eventKey="tuningfile" title="Tuningfile">
                            <div className="deshborad_pragraph_euro">
                              {filterNewsByType("tuning")
                                .slice(0, 3)
                                .map((newsItem) => (
                                  <div
                                    key={newsItem._id}
                                    className="Tuningfile_silder"
                                  >
                                    <p className="text-muted">Tuningfile</p>
                                    <h5>{newsItem.title}</h5>
                                    <strong>
                                      <small className="text-muted">
                                        {formatDate(newsItem.create_date)}
                                      </small>
                                    </strong>
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        getContentBeforeSeparator(
                                          newsItem.description
                                        )
                                      )}
                                    />
                                    <Link
                                      to={`/car-overview/${newsItem.slug}`}
                                      className="btn btn-primary"
                                    >
                                      Read More
                                    </Link>
                                  </div>
                                ))}
                            </div>
                          </Tab>
                          <Tab eventKey="news" title="News">
                            <div className="deshborad_pragraph_euro">
                              {filterNewsByType("news")
                                .slice(0, 3)
                                .map((newsItem) => (
                                  <div
                                    key={newsItem._id}
                                    className="Tuningfile_silder"
                                  >
                                    <p className="text-muted">Tuningfile</p>
                                    <h5>{newsItem.title}</h5>
                                    <strong>
                                      <small className="text-muted">
                                        {formatDate(newsItem.create_date)}
                                      </small>
                                    </strong>
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        getContentBeforeSeparator(
                                          newsItem.description
                                        )
                                      )}
                                    />
                                    <Link
                                      to={`/car-overview/${newsItem.slug}`}
                                      className="btn btn-primary"
                                    >
                                      Read More
                                    </Link>
                                  </div>
                                ))}
                            </div>
                          </Tab>
                        </Tabs>
                      )}

                      <div className="silder_btn">
                        <div className="silder_btn_veiw_2">
                          <button className="silder_btn_veiw_1 btn btn-light-info">
                            <svg
                              className="silder_icon_tab"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 256 512"
                            >
                              <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                            </svg>
                          </button>
                          <button className="silder_btn_veiw_1 btn btn-light-info">
                            <svg
                              className="silder_icon_tab"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 256 512"
                            >
                              <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                            </svg>
                          </button>
                        </div>
                        <Link  to="/all-news" className="chart_btn btn btn-light-info">
                          View all
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
