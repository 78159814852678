import React from 'react'
import "../../assets/css/magicmotorsport.css"
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import car from "../../assets/images/car.png"
import toolpreview from "../../assets/images/toolpreview.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';

const autotuner = () => {
  return (
    <>
            <section>
                <MainHeader />
            </section>
            <section className='alientechtools_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='magicmotorsport_content'>
                                <h1 className='heading_alientech'>AUTOTUNER TOOLS</h1>
                                <p className='pragraph_fleshtool'>Autotuner is one of the latest automotive OBD flasher tuning tool generation designed for chiptuning professionals. Whether you already possess a diagnostic tool or not Autotuner design quality and ease of use is made for you. The Autotuner OBD Flasher tuning tool is available in Master and Slave version.</p>
                                <p className='pragraph_fleshtool my-5'>Curious about Autotuners's assortment? Click the blue button below or<a href="" className='Alientech_link'>  click here.</a></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-12 responsive_tab'>
                            <div className='magicmotorsport_1'>
                                <img src={toolpreview} alt="" width={750} height={400} className='box_image' />
                                <div className='responsive_tab'>
                                    <p className='pragraph_alientech_3'>Use your FLEX to easily tune, reprogram, upgrade or modify the software of most control units directly through the OBD socket of the vehicle. An internet connection gives you access to all the potential that the tool offers you.</p>
                                    <p className='pragraph_alientech_3'>For advanced and repairing jobs, use the Flexbox module to program your control unit in BDM, Bootloader, JTAG and AUD modes. The device detects eventual connection or communication errors thanks to the concept of its state-of-the -art design.</p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                        <div className='magicmotorsport_content'>
                                <h1 className='heading_alientech'>PRODUCT INFORMATION</h1>
                                <p className='pragraph_fleshtool'>Autotuner is the latest automotive OBD flasher tool generation designed for chiptuning professionals. Whether you already possess a diagnostic tool or not, Autotuner design quality and ease of use are made for you. Autotuner is a universal tool able to read information from most ECUs and microcontrollers available on the market in boot tricore Infineon (BSL) or via the OBD diagnostic socket.</p>

                            </div>
                            <div className='magicmotorsport_2'>
                                <h1 className='heading_alientech_1'>Autotuner Flasher tool Slave version includes: </h1>
                                <ul className='Software_tab mt-5'>
                                    <li>Autotuner Flasher Slave (Tool) </li>
                                    <li>Datalogging function</li>
                                    <li>Carrying case </li>
                                    <li>OBD cable</li>
                                    <li>USB cable</li>
                                    <li>Universal cable + universal box</li>
                                    <li>Universal cable + universal box</li>
                                    <li>Power adapter</li>
                                    <li>Probe Positioner + testprobe and cable</li>
                                    <li>Free updates</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                            <p className='pragraph_alientech_4'>Curious about Autotuners's assortment? Click the blue button below or <a href="" className='Alientech_link_1'> click here.!</a></p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-8 my-5 speedometer_image_1'>
                            <div className='p-5 pragraph_alientech_7'>
                                <p className='pragraph_alientech_6 '>CURIOUS ABOUT ALIENTECH'S ASSORTMENT?</p>
                                <p className='pragraph_alientech_6 '>SUBSCRIPTION & PROTOCOLS </p>
                                <p className='pragraph_alientech_6 '>The best and fast webshop provider by the </p>
                                <p className='pragraph_alientech_6 '>Best experience and technology and engineers</p>
                                <button className='Shop_alientech   shadow-lg '>Shop Magicmotorsport</button>
                                <div className='worldwide_shiping my-4'>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>worldwide shiping</h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>premium brands </h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'> high quality chiptuning tools</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg '>Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
  )
}

export default autotuner