import { BrowserRouter } from "react-router-dom";
import RoutesUrl from "./routes/routes";
import "./index.css";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { VehicleProvider } from "./Components/home/vehicleContext";

function App() {
  useEffect(() => {
    let color = localStorage.getItem("themeColor");
    if (color) {
      document.documentElement.style.setProperty("--theme-color", color);
    } else {
      document.documentElement.style.setProperty("--theme-color", "#19A4E0");
    }
  }, []);

  return (
    <>
      <VehicleProvider>
        <BrowserRouter>
          <RoutesUrl />
        </BrowserRouter>
      </VehicleProvider>
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

export default App;
