import React from "react";
import { Link } from "react-router-dom";

const TopCrumb = ({ route }) => {
  return (
    <div className="row mb-1 p-3 page_heading_row">
      <div className="col-9">
        <h3>{route}</h3>
      </div>
      <div className="col-3 top-crumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item" aria-label="Home">
            <a aria-label="Home" className="" href="/">
              <i className="bi bi-houses"></i>
            </a>
          </li>
          <li className="breadcrumb-item" aria-label="Component">
            <i className="bi bi-chevron-right"></i>
            <Link to="/dashboard" target="_self">
              Dashboard
            </Link>
          </li>
          <li
            className="breadcrumb-item active"
            aria-current="page"
            aria-label="Breadcrumb"
          >
            <i className="bi bi-chevron-right"></i>
            {route}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopCrumb;
