import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/caroverveiw.css";
import car1 from "../../assets/images/car1.png";
import { getNewsItem } from "../../api"; 

const CarOverview = () => {
    const [newsItem, setNewsItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { slug } = useParams();

    useEffect(() => {
        const fetchNewsItem = async () => {
            try {
                const token = localStorage.getItem("Access-Token");
                const response = await getNewsItem(slug, token);
                
                if (response.status_code === 200 && response.data) {
                    setNewsItem(response.data);
                } else {
                    throw new Error(response.message || "Failed to retrieve news item");
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching news item:", err);
                setError(err.message || "An error occurred while fetching the news item");
                setLoading(false);
            }
        };

        fetchNewsItem();
    }, [slug]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        } catch (error) {
            return dateString;
        }
    };

    const getImages = () => {
        if (newsItem?.images && newsItem.images.length > 0) {
            return newsItem.images.map(image => image.url);
        }
      
    };

    const handleNextImage = () => {
        const images = getImages();
        setCurrentImageIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevImage = () => {
        const images = getImages();
        setCurrentImageIndex((prevIndex) => 
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const parseDescription = (htmlDescription) => {
        if (!htmlDescription) return { mainContent: "", specs: [] };
       
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlDescription;
        const paragraphs = Array.from(tempDiv.querySelectorAll('p'));
        const separatorIndex = paragraphs.findIndex(p => 
            p.textContent.includes('*******************') || 
            p.textContent.includes('*****************')
        );
        
        const mainContent = separatorIndex !== -1 
            ? paragraphs.slice(0, separatorIndex).map(p => p.textContent).join('\n') 
            : paragraphs.map(p => p.textContent).join('\n');
        let specs = [];
        if (separatorIndex !== -1 && separatorIndex < paragraphs.length - 1) {
            const specsText = paragraphs.slice(separatorIndex + 1).map(p => p.innerHTML).join('');
            specs = specsText.split('<br>').map(item => item.trim()).filter(item => item);
        }
        
        return { mainContent, specs };
    };

    if (loading) {
        return (
            <>
                <section>
                    <MainHeader />
                </section>
                <section className="carover_veiw">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center my-5">
                                <h2>Loading...</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <MainFooter />
                </section>
            </>
        );
    }

    if (error) {
        return (
            <>
                <section>
                    <MainHeader />
                </section>
                <section className="carover_veiw">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center my-5">
                                <h2>Error loading news item</h2>
                                <p>{error}</p>
                                <div className="mt-4 text-center">
                                    <Link to="/projects" className="overveiw_btn shadow-lg">RETURN TO OVERVIEW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <MainFooter />
                </section>
            </>
        );
    }

    const { mainContent, specs } = parseDescription(newsItem?.description);
    const images = getImages();

    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className="carover_veiw">
                <div className="content-container">
                    <div className="car_overveiw mt-5">
                        <p>{formatDate(newsItem?.create_date) || ""}</p>
                        <h1 className="mt-4 mb-4">{newsItem?.title || ""}</h1>
                    </div>
                    
                    <div className="image-slider-container my-4">
                        <div className="slider-image-container">
                            <img 
                                src={images[currentImageIndex]} 
                                alt={`${newsItem?.title || "Car"} - Image ${currentImageIndex + 1}`} 
                                className="box_image_overveiw clickable-image" 
                            />
                            
                            <button className="slider-button slider-button-prev" onClick={handlePrevImage}>
                                &#10094;
                            </button>
                            <button className="slider-button slider-button-next" onClick={handleNextImage}>
                                &#10095;
                            </button>
                            

                            {/* <div className="image-counter">
                                {currentImageIndex + 1} / {images.length}
                            </div> */}
                        </div>
                    </div>
                    

                    {images.length > 1 && (
                        <div className="thumbnails-container">
                            {images.map((image, index) => (
                                <div 
                                    key={index} 
                                    className={`thumbnail ${index === currentImageIndex ? 'active-thumbnail' : ''}`}
                                    onClick={() => handleThumbnailClick(index)}
                                >
                                    <img 
                                        src={image} 
                                        alt={`${newsItem?.title || "Car"} - Thumbnail ${index + 1}`} 
                                        className="thumbnail-image" 
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    
                    <div className="overveiw_tab my-5 shadow-lg">
                        <p className="overveiw_tab_1">{mainContent}</p>
                        {specs.length > 0 && (
                            <>
                                <span className="overveiw_tab_1">*****************</span>
                                <ul className="overveiw_tab_1 mt-4">
                                    {specs.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                    
                    <div className="return-button-container">
                        <button className="overveiw_btn shadow-lg">
                            <Link to="/projects" className="overveiw_btn_link">RETURN TO OVERVIEW</Link>
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
    );
};

export default CarOverview;