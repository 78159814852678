import React from 'react'
import "../../assets/css/cmdflashtool.css"
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import black from "../../assets/images/black.png"
import tool from "../../assets/images/tool.jpg"
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';


const cmdflashtool = () => {
    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='alientechtools_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='fleshtool_content'>
                                <h1 className='heading_alientech'>CMD-FLASH TOOLS</h1>
                                <p className='pragraph_fleshtool'>CMD Flash chip tuning tool is manufactured by CMD Technologies - Flashtec. CMD Flash is one of the best and most stable tools in the World today. Large vehicle coverage and excellent software makes it a must-have tool for all car tuners.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-6 my-5 responsive_tab'>
                            <img src={black} alt="" width={400} height={500} className='box_image' />
                        </div>
                        <div className='col-6 my-5 responsive_tab'>
                            <h1 className='heading_alientech_1'>CMD-FLASH OBD TOOL</h1>
                            <p className='pragraph_alientech_3'>The Flash tool CMD is on of the best on the market. Particularly suitable for diesel vehicles and for the user easy and safe to handle. The CMD-Flash is a tool which allows you to have the OBD plug on the vehicle data from the control device to read and to write. The big advantage is that the control device doesn't have to be opened and no soldering is needed. All data exchange happens on the OBD connector.</p>
                            <p className='pragraph_alientech_3'>With this tool you are able to flash the most of all European and Korean OBD diesel vehicles. We distribute all products from CMD and can support you. The CMD flash tool is available in the versions master and slave.</p>

                        </div>

                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-6  responsive_tab'>
                            <h1 className='heading_alientech_1'>CMD-FLASH BDM TOOL </h1>
                            <p className='pragraph_alientech_3'>For ECUs with Motorola processors from 2002 to today's date. This series of Motorola processors has 448KB to 2MB flash memory. This memory can not be read or written with a EPROM programmer. This is just about the BDM interface. Usually there are 10 solder pads on the bottom of the board. The BDM flash tool connects the solder points with the BDM interface via USB to the PC. The BDM flash tool is supported via USB with the operating voltage. The control unit needs its own 12V supply voltage.</p>
                        </div>
                        <div className='col-6 m responsive_tab p-5'>
                            <img src={tool} alt="" width={350} height={250} className='box_image' />
                        </div>
                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                            <p className='pragraph_alientech_4'>Curious about Alientech's assortment? Click the blue button below or <a href="" className='Alientech_link_1'>click here.</a></p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-8 my-5 speedometer_image_2'>
                            <div className='p-5 pragraph_alientech_7'>
                                <p className='pragraph_alientech_6 '>CURIOUS ABOUT ALIENTECH'S ASSORTMENT?</p>
                                <p className='pragraph_alientech_6 '>SUBSCRIPTION & PROTOCOLS </p>
                                <p className='pragraph_alientech_6 '>The best and fast webshop provider by the </p>
                                <p className='pragraph_alientech_6 '>Best experience and technology and engineers</p>
                                <button className='Shop_alientech   shadow-lg '>Shop CMD-FLASH TOOLS</button>
                                <div className='worldwide_shiping my-4'>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>worldwide shiping</h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>premium brands </h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'> high quality chiptuning tools</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg '>Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default cmdflashtool