import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { sendEngineData } from '../../api';
import { authClient } from '../../lib/client';
import { useNavigate } from 'react-router-dom';

const MakeSelect = ({ value, handleChange, vehiclesData }) => {
  const toTitleCase = (str) => {
      return str
        .toLowerCase()
        .split(/[\s-]/) 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
    };
  return (
    <Form.Select name='vehicle_id' onChange={handleChange} value={value}>
      <option value="">Choose a make</option>
      {vehiclesData?.map((vehicle) => (
        <option key={vehicle._id} value={vehicle._id}>
          {toTitleCase(vehicle.car_name)}
        </option>
      ))}
    </Form.Select>
  );
};
export default MakeSelect