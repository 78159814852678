import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authClient } from "../../lib/client";
import { sendEngineData } from "../../api";

const ModelsearchSec = () => {
  const navigate = useNavigate();
  const [AccessToken, setAccessToken] = useState("");
  const [vehiclesData, setVehiclesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [generationData, setGenerationData] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [engineCategories, setEngineCategories] = useState({});
  const [selectedIds, setSelectedIds] = useState({
    vehicle_id: '',
    model_id: '',
    generation_id: '',
    engine_id: ''
  });

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/[\s-]/) 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("Access-Token");
    setAccessToken(access_token);
    if (vehiclesData?.length === 0) {
      getVehicles(access_token);
    }
  }, []); 
  
  const getVehicles = async (token) => {
    const response = await authClient.GetVehicles(token);
    setVehiclesData(response.data);
  };

  const handleChangeCar = async (event) => {
    const vehicleId = event.target.value;
    setSelectedIds(prev => ({ ...prev, vehicle_id: vehicleId }));
    const response = await authClient.GetModels(vehicleId, AccessToken);
    setModelData(response.data);
    setGenerationData([]);
    setEngineData([]);
    setEngineCategories({});
  };

  const handleChangeCarModal = async (event) => {
    const modelId = event.target.value;
    setSelectedIds(prev => ({ ...prev, model_id: modelId }));
    const response = await authClient.GetModelGeneration(modelId, AccessToken);
    setGenerationData(response.data);
    setEngineData([]);
    setEngineCategories({});
  };

  const handleChangeCarGeneration = async (event) => {
    const generationId = event.target.value;
    setSelectedIds(prev => ({ ...prev, generation_id: generationId }));
    const response = await authClient.GetModelGenerationEngine(generationId, AccessToken);
 
    if (response.data && response.data.data) {
      setEngineCategories(response.data.data);
      
   
      const allEngines = [];
      Object.keys(response.data.data).forEach(category => {
        if (Array.isArray(response.data.data[category])) {
          response.data.data[category].forEach(engine => {
            allEngines.push({
              ...engine,
              category 
            });
          });
        }
      });
      
      setEngineData(allEngines);
    } else {
      setEngineData([]);
      setEngineCategories({});
    }
  };

  const handleEngineChange = (event) => {
    const engineId = event.target.value;
    setSelectedIds(prev => ({ ...prev, engine_id: engineId }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!selectedIds.generation_id || !selectedIds.engine_id) {
        window.location.reload();
        return;
      }
  
      const response = await sendEngineData(selectedIds, AccessToken);
      if (
        !response?.power_object || Object.keys(response.power_object)?.length === 0 ||
        !response?.torque_object || Object.keys(response.torque_object)?.length === 0 ||
        !response?.engine_details || Object.keys(response.engine_details)?.length === 0
      ) {
        window.location.reload();
        return;
      }
  
      navigate("/chiptuningfile", {
        state: { engineData: response },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  

  const renderEngineOptions = () => {
  
    if (engineData.length === 0) {
      return <option value="">Choose an engine</option>;
    }

    return [
      <option key="default" value="">Choose an engine</option>,
      ...Object.keys(engineCategories).map(category => {
        if (!engineCategories[category] || !engineCategories[category].length) return null;
        
        return (
          <optgroup key={category} label={toTitleCase(category)}>
            {engineCategories[category].map(engine => (
              <option key={engine._id} value={engine._id}>
                {toTitleCase(engine.engine_name)}
              </option>
            ))}
          </optgroup>
        );
      })
    ];
  };

  return (
    <div className="container">
     
      <div className="row justify-content-center mb-4">
        <div className="col-lg-10">
          <div className="input-group">
            <select
              className="form-select"
              onChange={handleChangeCar}
              value={selectedIds.vehicle_id}
            >
              <option value="">Choose a make</option>
              {vehiclesData?.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {toTitleCase(vehicle.car_name)}
                </option>
              ))}
            </select>
            <select
              className="form-select"
              onChange={handleChangeCarModal}
              value={selectedIds.model_id}
              disabled={!selectedIds.vehicle_id}
            >
              <option value="">Choose a model</option>
              {modelData?.map((model) => (
                <option key={model._id} value={model._id}>
                  {toTitleCase(model.model_name)}
                </option>
              ))}
            </select>
            <select
              className="form-select"
              onChange={handleChangeCarGeneration}
              value={selectedIds.generation_id}
              disabled={!selectedIds.model_id}
            >
              <option value="">Choose a generation</option>
              {generationData?.map((generation) => (
                <option key={generation._id} value={generation._id}>
                  {toTitleCase(generation.generation_name)}
                </option>
              ))}
            </select>
            <select
              className="form-select"
              onChange={handleEngineChange}
              value={selectedIds.engine_id}
              disabled={!selectedIds.generation_id}
            >
              {renderEngineOptions()}
            </select>
            <button
              className="btn btn-primary p-3"
              type="button"
              onClick={handleSubmit}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {/* Statistics */}
      <div className="row text-center">
        <div className="col-md-3">
          <h3 className="fw-bold">14</h3>
          <p>Years of experience</p>
        </div>
        <div className="col-md-3">
          <h3 className="fw-bold">500</h3>
          <p>File transfers a month</p>
        </div>
        <div className="col-md-3">
          <h3 className="fw-bold">470.000</h3>
          <p>Files in our database</p>
        </div>
        <div className="col-md-3">
          <h3 className="fw-bold">3</h3>
          <p>Engineers</p>
        </div>
      </div>
    </div>
  );
};

export default ModelsearchSec;