import React, { useState } from 'react'
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import "../../assets/css/aboutus.css";
import carmechanic from "../../assets/images/carmechanic.jpg"
import businessman from "../../assets/images/businessman.jpg"
import car from "../../assets/images/car.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';

const Aboutus = () => {

    const [activeTab, setActiveTab] = useState('GROWING_NUMBERS');

    const toggleTab = (tabName) => {
        setActiveTab((prevTab) => (prevTab === tabName ? null : tabName));
    };
    return (
        <>
            <section>
                <MainHeader />
            </section>

            <section className='about_us_page'>

                <section className='aboutus_image'>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-12 my-5 about_us'>
                                <h2 className='aboutus_heading'>Pro-CHIPTUNINGFILES.COM</h2>
                                <h1 className='aboutus_heading'>SUPPLIER OF HIGH QUALITY TUNING FILES. </h1>
                                <p className='aboutus_pragraph'><strong>Pro-Chiptuningfiles</strong> was founded in 2010 by two experienced tuners, with the idea to share their knowledge and expertise in adjusting motor management systems and tuning software, because of the lack of good tuning software engineers in the industry at the time.</p>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-4 my-5 about_us_responsive'>
                            <h1 className='aboutus_heading'>OUR STORY</h1>
                            <p className='aboutus_pragraph'>AN IDEA FOR AN</p>
                            <h2 className='aboutus_heading'>ONLINE B2B <span className='aboutus_heading_span'>CHIPTUNING PLATFORM</span></h2>
                        </div>
                        <div className='col-8 my-5 about_us_responsive'>
                            <h2 className='aboutus_heading'>REQUEST TUNING FILES BY UPLOADING STOCK DATA FILES
                            </h2>
                            <p className='aboutus_pragraph'>IN THIS PLATFORM TUNING COMPANIES CAN REQUEST TUNING FILES BY UPLOADING STOCK DATA FILES FROM VEHICLES AND HAVING THE DATA FILES ADJUSTED TO THEIR NEEDS. IT WAS AN INSTANT AND BIG SUCCESS BECAUSE OF THE HIGH  DEMAND FOR QUALITATIVE TUNING FILES.</p>
                            <div className='tab_growing'>

                                <div className='tab_growing_1'>
                                    <h2
                                        className='aboutus_pragraph'
                                        onClick={() => toggleTab('GROWING_NUMBERS')}
                                    >
                                        GROWING NUMBERS
                                    </h2>
                                    <h2
                                        className='aboutus_pragraph'
                                        onClick={() => toggleTab('CONTINUOUS_IMPROVEMENT')}
                                    >
                                        CONTINUOUS IMPROVEMENT
                                    </h2>
                                    <h2
                                        className='aboutus_pragraph'
                                        onClick={() => toggleTab('WE_KEEP_DEVELOPING')}
                                    >
                                        WE KEEP DEVELOPING
                                    </h2>
                                    <h2
                                        className='aboutus_pragraph'
                                        onClick={() => toggleTab('ONE_OF_THE_BIGGEST')}
                                    >
                                        ONE OF THE BIGGEST
                                    </h2>
                                    <h2
                                        className='aboutus_pragraph'
                                        onClick={() => toggleTab('BIG_INVESTMENTS')}
                                    >
                                        BIG INVESTMENTS
                                    </h2>
                                </div>

                                {activeTab === 'GROWING_NUMBERS' && (
                                    <div className='tab_growing_2'>
                                        <p className='aboutus_pragraph'>
                                            Over the years the company and the number of customers kept growing. By adding new software engineers to the team, Pro-ChiptuningFiles could keep up the ever-rising demand for tuning software and could develop and introduce the support system.
                                        </p>
                                    </div>
                                )}

                                {activeTab === 'CONTINUOUS_IMPROVEMENT' && (
                                    <div className='tab_growing_2'>
                                        <p className='aboutus_pragraph'>
                                            We have continuously improved the file service platform over the years and made sure that it became more and more user-friendly for customers. At the same time, we had to make sure that the system was ready for the future by making new, upcoming features possible and keep expanding our database by developing new tuning files for new cars and vehicles.
                                        </p>
                                    </div>
                                )}

                                {activeTab === 'WE_KEEP_DEVELOPING' && (
                                    <div className='tab_growing_2'>
                                        <p className='aboutus_pragraph'>
                                            Now, eight years further, we still keep developing the file service platform and it has become bigger and bigger. We still keep exploring new grounds by expanding our business and our eagerness to keep improving ourselves. We do this by carefully listening to the demands and expectations of our customers.
                                        </p>
                                    </div>
                                )}
                                {activeTab === 'ONE_OF_THE_BIGGEST' && (
                                    <div className='tab_growing_2'>
                                        <p className='aboutus_pragraph'>
                                            With more than 24000 dealers in more than 160 countries, we can safely call ourself one of the biggest tuning companies in the world. We have a huge database of tuning files and find solutions for almost all problems modern day tuners are facing.                                    </p>
                                    </div>
                                )}
                                {activeTab === 'BIG INVESTMENTES' && (
                                    <div className='tab_growing_2'>
                                        <p className='aboutus_pragraph'>
                                            Our core business is to develop software for vehicles so it’s really important for us to have good test facilities. In the last couple of years we have made some big investmentes in our test facilities to make sure that we can constantly respond to new technologies, and keep developing software for all kind of vehicles. We develop software for cars,vehicles, bikes and even boats. </p>
                                    </div>
                                )}

                            </div>

                        </div>

                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-12 text-center my-5'>
                            <h1 className='aboutus_heading_countries '>175</h1>
                            <h1 className='aboutus_countries '>COUNTRIES</h1>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-4 text-center my-2 country_responsive'>
                            <h2 className='aboutus_heading_countries'>6367</h2>
                            <p className='aboutus_countries'>ACTIVE CUSTOMERS</p>
                        </div>
                        <div className='col-4 text-center my-2 country_responsive'>
                            <h2 className='aboutus_heading_countries'>13</h2>
                            <p className='aboutus_countries'>MINUTES TURNAROUND TIME</p>
                        </div>
                        <div className='col-4 text-center my-2 country_responsive'>
                            <h2 className='aboutus_heading_countries'>3500+</h2>
                            <p className='aboutus_countries'>FILES A MONTH</p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-12 text-center my-5'>
                            <h1 className='aboutus_mission'>"IT IS OUR MISSION TO PROVIDE TUNING COMPANIES WITH HIGH QUALITY TUNING SOFTWARE"</h1>

                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <h3 className='aboutus_countries my-4 text-center '>WHY CHOOSE US?</h3>
                        <div className='col-4 text-center my-5 country_responsive'>
                            <p className='aboutus_countries'>AVERAGE TURNAROUND TIME OF <span className='aboutus_heading_countries'>20 MINUTES</span></p>
                        </div>
                        <div className='col-4 text-center my-5 country_responsive'>
                            <p className='aboutus_countries'>MORE THAN <span className='aboutus_heading_countries'>3500 </span>WINOLS RESELLER DEALERS</p>
                        </div>
                        <div className='col-4 text-center my-5 country_responsive'>
                            <p className='aboutus_countries'>SUPPLIER OF <span className='aboutus_heading_countries'>PREMIUM</span> CHIPTUNING TOOLS</p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-6 my-5 about_us_responsive'>
                            <img src={carmechanic} alt="carmechanic" width={400} height={300} className='my-5 box_image_business' />
                        </div>
                        <div className='col-6 my-5 about_us_responsive country_responsive'>
                            <h3 className='practical_experience_1 my-3'>PRACTICAL EXPERIENCE
                            </h3>
                            <h4 className='practical_experience_2 my-3'>WE KNOW AND UNDERSTAND OUR CUSTOMERS BECAUSE WE HAVE EXPERIENCE AS A TUNING COMPANY AS WELL
                            </h4>
                            <p className='practical_experience my-3'>Therefore we can resonate with our customers in a practical way. We understand the problems and difficulties tuning companies are facing when carrying out their daily activities. Another distinctive factor is our user experience with tuning tools. We are familiar with all the tools and equipment available on the market and therefore can help our customers very well, even if they work with lesser known tools. We can even assist our customers with their daily activities by advising which tools to use in particular cases.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row bussiness_responsive'>
                        <div className='col-6 my-5 country_responsive'>
                            <h3 className='practical_experience_1 my-3'>EXCELLENT SUPPORT
                            </h3>
                            <h4 className='practical_experience_2 my-3'>WE ALSO ASSIST OUR CUSTOMERS WITH THEIR TUNING PROCES BY PROVIDING AN EXCELLENT SUPPORT SYSTEM.
                            </h4>
                            <p className='practical_experience my-3'>The support system makes it possible for customers to request custom adjustments when needed as not every vehicle reacts the same to adjustments and vehicles usually are not in the same condition. It is hard for us to check a vehicle’s condition on a distance and that is why the support system is so useful. It really helps to serve customers in a better way, whether it concerns consumers or businesses.
                            </p>
                        </div> 
                        <div className='col-6 my-5 country_responsive'>
                            <img src={businessman} alt="carmechanic" width={500} height={300} className='my-5 box_image_business' />
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech bussiness_responsive'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg ' href="/chiptuningfile">Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default Aboutus