import React, { useEffect, useState } from "react";
import { uploadFile } from "../../api";
import { toast } from "react-toastify";

const FileUploadContainer = ({
  attachments = { file: [], additional: [] },
  handleFiles,
  showError=false,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [optionalDragActive, setOptionalDragActive] = useState(false);
  const [showOptionalField, setShowOptionalField] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [optionalUploadedFiles, setOptionalUploadedFiles] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setUploadedFiles(Array.isArray(attachments.file) ? attachments.file : []);
    setOptionalUploadedFiles(Array.isArray(attachments.additional) ? attachments.additional : []);
    setShowOptionalField(attachments.additional?.length > 0);
    if (showError && attachments.file?.length === 0) {
      setError("This field is  required");
    } else {
      setError("");
    }
  }, [attachments, showError]);
  const handleFileUpload = async (file, isOptional) => {
    try {
      const token = localStorage.getItem("Access-Token");
      if (!token) {
        window.location.href = "/login";
        return;
      }

      const response = await uploadFile(file, token);

      if (response && response?.[0]?.url) {
        const uploadedFile = {
          filename: file.name,
          url: response[0].url,
        };

        if (isOptional) {
          handleFiles({
            file: [...attachments.file],
            additional: [...attachments.additional, response?.[0]],
          });
        } else {
          handleFiles({
            file: [...attachments.file, response?.[0]],
            additional: [...attachments.additional],
          });
          setError("");
        }
        toast.success(`File ${file.name} uploaded successfully`);
        return {
          success: true,
          file: uploadedFile,
          url: response[0].url,
        };
      } else {
        toast.error(`Failed to upload file ${file.filename}`);
        return { success: false };
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(`Failed to upload file ${file.filename}: ${error.message}`);
      return { success: false };
    }
  };

  const handleFileChange = async (event, isOptional = false) => {
    const files = Array.from(event.target.files);
    const successfulUploads = [];
    const uploadUrls = [];

    for (const file of files) {
      if(isOptional) {
        let existing = Array.isArray(attachments.file)
          ? attachments.file.filter((f) => f.filename === file.name)
          : [];
      
        if(existing.length > 0) {
          toast.error(`File ${file.name} already exists`);
          return;
        }
      } else {
        let existing = Array.isArray(attachments.additional)
          ? attachments.additional.filter((f) => f.filename === file.name)
          : [];
      
        if(existing.length > 0) {
          toast.error(`File ${file.name} already exists`);
          return;
        }
      }
      const result = await handleFileUpload(file, isOptional);
      if (result.success) {
        successfulUploads.push(result.file);
        uploadUrls.push(result.url);
      }
    }

    if (isOptional) {
      setOptionalUploadedFiles([...optionalUploadedFiles, ...successfulUploads]);
    } else {
      setUploadedFiles([...uploadedFiles, ...successfulUploads]);
    }
  };

  const handleDrop = async (event, isOptional = false) => {
    event.preventDefault();
    isOptional ? setOptionalDragActive(false) : setDragActive(false);

    const files = Array.from(event.dataTransfer.files);
    const successfulUploads = [];

    for (const file of files) {
      if(isOptional) {
        let existing = attachments.additional.filter((f) => f.filename === file.name);
        if(existing.length > 0) {
          toast.error(`File ${file.name} already exists`);
          return;
        }
      } else {
        let existing = attachments.file.filter((f) => f.filename === file.name);
        if(existing.length > 0) {
          toast.error(`File ${file.name} already exists`);
          return;
        }
      }
      const success = await handleFileUpload(file, isOptional);
      if (success) {
        successfulUploads.push(file);
      }
    }

    if (isOptional) {
      setOptionalUploadedFiles([...optionalUploadedFiles, ...successfulUploads]);
    } else {
      setUploadedFiles([...uploadedFiles, ...successfulUploads]);
    }
  };

  const handleFileDelete = (fileName, isOptional = false) => {
    if (isOptional) {
      const filteredFiles = attachments.additional.filter(
        (file) => file.filename !== fileName
      );
      setOptionalUploadedFiles(filteredFiles);

      handleFiles({
        file: [...attachments.file],
        additional: [...filteredFiles],
      });
    } else {
      const filteredFiles = attachments.file.filter(
        (file) => file.filename !== fileName
      );
      setUploadedFiles(filteredFiles);
      handleFiles({
        file: [...filteredFiles],
        additional: [...attachments.additional],
      });
  
    }
    toast.success(`File ${fileName} removed`);
  };

  const FilePreview = ({ file, isOptional }) => (
    <div
      className="file-preview"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#e3f2fd",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="bi bi-file-earmark text-primary"
          style={{ fontSize: "24px", marginRight: "10px" }}
        ></i>
        <span style={{ fontSize: "16px", color: "#0d6efd" }}>{file.filename}</span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <a
            href={file.url}
            download={file.filename}
            style={{ color: "#0d6efd", cursor: "pointer" }}
          >
            <i className="bi bi-download" title="Download"></i>
          </a>
        </div>
        <div>
          <i
            className="bi bi-x text-danger"
            title="Remove"
            style={{ cursor: "pointer" }}
            onClick={() => handleFileDelete(file.filename, isOptional)}
          ></i>
        </div>
      </div>
    </div>
  );

  const DropZone = ({ isOptional }) => (
    <div className="mb-3">
      <div
        className={`file-container ${
          isOptional ? optionalDragActive : dragActive ? "drag-active" : ""
        }`}
        onDragOver={(e) => {
          e.preventDefault();
          isOptional ? setOptionalDragActive(true) : setDragActive(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          isOptional ? setOptionalDragActive(false) : setDragActive(false);
        }}
        onDrop={(e) => handleDrop(e, isOptional)}
        style={{
          border: (isOptional ? optionalDragActive : dragActive)
            ? "2px dashed #007bff"
            : error && !isOptional 
              ? "2px dashed #dc3545"
              : "2px dashed #d9d9d9",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          color: "#6c757d",
          cursor: "pointer",
          backgroundColor: (isOptional ? optionalDragActive : dragActive)
            ? "#f0f8ff"
            : "transparent",
        }}
      >
        <i
          className={`bi bi-file-earmark-plus ${isOptional ? 'text-primary' : error ? 'text-danger' : 'text-primary'}`}
          style={{ fontSize: "50px" }}
        ></i>
        <p>
          Drag your {isOptional ? "optional " : ""}file here, or
          {!isOptional && <span className="text-danger">*</span>}
        </p>
        <label
          className={`btn btn-outline-${isOptional ? "secondary" : error ? "danger" : "primary"}`}
          style={{ marginTop: "10px" }}
        >
          <input
            type="file"
            hidden
            multiple
            onChange={(e) => handleFileChange(e, isOptional)}
          />
          Browse files on your device
        </label>
      </div>
      {!isOptional && error && showError && (
        <div  style={{ fontSize: "0.875rem",color:"red",marginTop:"2px" }}>
          {error}
        </div>
      )}
    </div>
  );


  return (
    <div>
      <DropZone isOptional={false} />

      {/* Display Uploaded Primary Files */}
      <div className="mt-3">
        {uploadedFiles.map((file, index) => (
          <FilePreview key={index} file={file} isOptional={false} />
        ))}
      </div>

      {/* Optional File Upload */}
      <div className="mt-3">
        <p>
          Do you have any optional attachments?{" "}
          <span className="text-muted">(optional)</span>
        </p>
        <select
          className="form-select"
          onChange={(e) => setShowOptionalField(e.target.value === "yes")}
          value={showOptionalField ? "yes" : "no"}
        >
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>
      </div>

      {showOptionalField && (
        <div className="mt-3">
          <p className="">
            Optional attachments <span className="text-muted">(optional)</span>{" "}
            <br />
            <small>Only files smaller than 20MB</small>
          </p>
          <DropZone isOptional={true} />

          {/* Display Uploaded Optional Files */}
          {optionalUploadedFiles.map((file, index) => (
            <FilePreview key={index} file={file} isOptional={true} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploadContainer;