import React from 'react'

const SearchInput = ({ query, setQuery }) => {
    return (
        <div className='d-flex flex-inline align-items-center search-input gap-2 px-2'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height={24} width={24} strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input type="text" className='w-100' value={query} onChange={(e) => setQuery(e.target.value)} placeholder='Type here to search..' />
        </div>
    )
}

export default SearchInput