import { createSlice } from '@reduxjs/toolkit';
const loadCartFromStorage = () => {
  try {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : { items: [], total: 0 };
  } catch (error) {
    console.error('Failed to load cart from storage', error);
    return { items: [], total: 0 };
  }
};
const saveCartToStorage = (cart) => {
  try {
    localStorage.setItem('cart', JSON.stringify(cart));
  } catch (error) {
    console.error('Failed to save cart to storage', error);
  }
};
// In your cartSlice.js
const cartSlice = createSlice({
  name: 'cart',
  initialState: loadCartFromStorage(),
  reducers: {
    addToCart: (state, action) => {
      state.items.push(action.payload);
    },
    removeFromCart: (state, action) => {
      const itemId = action.payload;
      state.items = state.items.filter(item => item.id !== itemId);
    },
    updateQuantity: (state, action) => {
      const item = state.items.find(item => item.id === action.payload.id);
      
      if (item) {
        if (action.payload.changes) {
          Object.keys(action.payload.changes).forEach(key => {
            item[key] = action.payload.changes[key];
          });
        } else {
          item.quantity = action.payload.quantity;
        }
      } else {
        console.log("❌ Item not found for update");
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
    }
  }
});
export const { addToCart, removeFromCart, updateQuantity, clearCart } = cartSlice.actions;
export const cartMiddleware = store => next => action => {
  const result = next(action);
  if (action.type?.startsWith('cart/')) {
    saveCartToStorage(store.getState().cart);
  }
  return result;
};
export default cartSlice.reducer;