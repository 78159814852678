import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { getProfile } from '../../../../api' 

const TuningInfoForm = ({ data, setData, errors, touched, customSet, setFieldValue, values }) => {
  const [showEngineTypes, setShowEngineTypes] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileResponse = await getProfile();
             if (profileResponse && profileResponse.detail) {
          setUserProfile(profileResponse.detail);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    
    fetchUserProfile();
  }, []);
  
  const engineTypeOptions = [
    "MED9.1", "MED17.1.62", "MED17.1.1", "MED17.5", "MED17.7.5", 
    "MED17.3.5", "MED17.1.11", "MED17.5.21", "MED17.1.27", 
    "MG1CS002", "MG1CS163", "MG1CP007", "MG1CS008", "MG1CS047",
    "SIMOS18.1", "SIMOS18.10", "SIMOS18.6",
    "EDC17CP44", "EDC17CP14", "EDC17C46", "EDC17CP24", 
    "EDC17C64", "EDC17C54", "EDC17CP54"
  ];

  const tuningOptions = [
    { id: "1", icon: "car-front", title: "Car Tuning (Stage 1)", credits: "1.0 credit", disabled: false },
    { id: "2", icon: "car-front", title: "Car Tuning (Stage 2)", credits: "1.5 credits", disabled: false },
    { id: "3", icon: "car-front", title: "Car Tuning (Stage 3)", credits: "3.0 credits", disabled: false },
    { id: "4", icon: "sliders", title: "Only options (Car)", credits: "0 credits", disabled: false },
    { id: "7", icon: "sliders", title: "TCU Tuning", credits: "1.0 credit", disabled: false},
    { id: "8", icon: "sliders", title: "Only options (TCU)", credits: "0 credits", disabled: false },
    { id: "9", icon: "sliders", title: "Multimap Switch", credits: "5 credits", disabled: false, hasDropdown: true },
    { id: "10", icon: "arrow-counterclockwise", title: "Back to stock", credits: "0.5 credits", disabled: false },
    { id: "12", icon: "sliders", title: "Tuning file review", credits: "0.2 credits", disabled: false },
  ];

  const options = [
    "Alientech K-TAG Bench",
    "Alientech K-TAG Bootmode",
    "Alientech KESS3 Bench",
    "Alientech KESS3 Bootmode",
    "Alientech KESS3 OBD",
    "Alientech KESSv2 OBD",
    "AMT Cartech Limited MPPS Bench",
    "AMT Cartech Limited MPPS Bootmode",
    "AMT Cartech Limited MPPS OBD",
    "Autotuner Bench",
    "Autotuner Bootmode",
    "Autotuner OBD",
    "bFlash Bench",
    "bFlash Bootmode",
    "bFlash OBD",
    "Bitbox Bench",
    "Bitbox Bootmode",
    "Bitbox OBD",
    "ByteShooter Toolbox Bench",
    "ByteShooter Toolbox Bootmode",
    "ByteShooter Toolbox OBD",
    "CMDFlash Bench",
    "CMDFlash Bootmode",
    "CMDFlash OBD",
    "DFB Technology DFOX Bench",
    "DFB Technology DFOX Bootmode",
    "DFB Technology DFOX OBD",
    "Dimtronic Diamond Bench",
    "Dimtronic Diamond Bootmode",
    "Dimtronic Diamond OBD",
    "EVC Electronic BDM100 Bootmode",
    "EVC Electronic BSL100 Bootmode",
    "Femto OBD Flasher OBD",
    "FG Technology EOBD2 Bench",
    "FG Technology EOBD2 Bootmode",
    "FG Technology EOBD2 OBD",
    "Frieling Racing IBOOT Bootmode",
    "Frieling Racing IFLASH OBD",
    "Frieling Racing SPI Wizard Bench",
    "Frieling Racing SPI Wizard Bootmode",
    "Frieling Racing SPI Wizard OBD",
    "Green Technology Devices VF2 Flasher Bench",
    "Green Technology Devices VF2 Flasher Bootmode",
    "Green Technology Devices VF2 Flasher OBD",
    "HP Tuners MPVI2 Bench",
    "HP Tuners MPVI2 OBD",
    "I/O Terminal Bench",
    "I/O Terminal Bootmode",
    "I/O Terminal OBD",
    "MagicMotorsport Flex Bench",
    "MagicMotorsport Flex Bootmode",
    "MagicMotorsport Flex OBD",
    "MagicMotorsport MAGPro2 Bootmode",
    "MagicMotorsport MAGPro2 OBD",
    "New Genius OBD",
    "New Trasdata Bench",
    "New Trasdata Bootmode",
    "PCM-Flash Bench",
    "PCM-Flash Bootmode",
    "PCM-Flash OBD",
    "Piasini Engineering Serial Suite Bench",
    "Piasini Engineering Serial Suite Bootmode",
    "Piasini Engineering Serial Suite OBD",
    "TGFlash Bench",
    "TGFlash Bootmode",
    "TGFlash OBD",
    "Otherwise, namely"
  ];

  const handleSelect = (id, disabled) => {
    if (!disabled) {
      setFieldValue('tuning_type', id);
    }
  };

  const toggleEngineTypesList = (e) => {
    e.stopPropagation();
    setShowEngineTypes(!showEngineTypes);
  };

  const userEmail = userProfile?.email || "";

  const userName = userProfile?.name || "";

  return (
    <div className='row'>
      <div>
        <span className='fw-semibold'>Tuning Information</span>
        <div className='mt-3'>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <Form.Label className='fs14' htmlFor='read_method'>Read method</Form.Label>
              <Form.Select
                id="read_method"
                name="read_method"
                value={data.read_method}
                {...setData("read_method")}
                className={clsx('', { 'is-invalid': touched.read_method && errors.read_method })}
              >
                <option value="">Choose an option</option>
                {options.map((option, index) => (
                  <option key={index} value={option === "Otherwise, namely" ? "otherwise,namely" : option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.read_method}</Form.Control.Feedback>
            </div>
            {data.read_method === "otherwise,namely" && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className='fs14' htmlFor='other_read'>Read method specification</Form.Label>
                <Form.Control 
                  type="text" 
                  id='other_read' 
                  name="other_read" 
                  value={data.other_read}  
                  {...setData("other_read")} 
                  className={clsx('', { 'is-invalid': touched.other_read && errors.other_read })} 
                />
                <Form.Control.Feedback type="invalid">{errors.other_read}</Form.Control.Feedback>
              </div>
            )}
          </div>

          <div className="row my-2">
            <div className="col-md-6 col-lg-6">
              <Form.Label className='fs14' htmlFor='hardware_no'>
                Hardware number <i className='bi bi-info-circle-fill'></i> 
                <small className='text-secondary'>(optional)</small>
              </Form.Label>
              <Form.Control 
                id='hardware_no' 
                name="hardware_no" 
                value={data.hardware_no} 
                {...setData('hardware_no')} 
                className={clsx('', { 'is-invalid': touched.hardware_no && errors.hardware_no })} 
              />
              <Form.Control.Feedback type="invalid">{errors.hardware_no}</Form.Control.Feedback>
            </div>
            <div className="col-md-6 col-lg-6">
              <Form.Label className='fs14' htmlFor='software_no'>Software number</Form.Label>
              <Form.Control 
                id='software_no' 
                name="software_no" 
                value={data.software_no} 
                {...setData('software_no')} 
                className={clsx('', { 'is-invalid': touched.software_no && errors.software_no })} 
              />
              <Form.Control.Feedback type="invalid">{errors.software_no}</Form.Control.Feedback>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <Form.Label className='fs14' htmlFor='email'>
                E-mail address <small className='text-secondary'>(optional)</small>
              </Form.Label>
              <Form.Select 
                id='email' 
                name="email" 
                value={data.email} 
                {...setData("email")} 
                className={clsx('', { 'is-invalid': touched.email && errors.email })}
              >
                <option value="">Choose an option</option>
                {userEmail && (
                  <option value="user_email">{userEmail}</option>
                )}
                <option value="otherwise,namely">Otherwise, namely</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </div>
            {data.email === "otherwise,namely" && (
              <div className="col-md-6 col-lg-6">
                <Form.Label className='fs14' htmlFor='other_email'>
                  E-mail address specification
                </Form.Label>
                <Form.Control 
                  type="text" 
                  id='other_email' 
                  name="other_email" 
                  value={data.other_email} 
                  {...setData('other_email')} 
                  className={clsx('', { 'is-invalid': touched.other_email && errors.other_email })} 
                />
                <Form.Control.Feedback type="invalid">{errors.other_email}</Form.Control.Feedback>
              </div>
            )}
          </div>

          <div className='row mt-4'>
            <Form.Label className='fs14'>Tuning type</Form.Label>
            <div className="row">
              {tuningOptions.map(({ id, icon, title, credits, disabled, hasDropdown }) => (
                <div className="col-md-3" key={id}>
                  <div
                    className={clsx(
                      "card text-center border",
                      data.tuning_type === id ? "border-primary border-2" : "b-disabled border-dashed",
                      hasDropdown ? "h-auto" : "h-75" 
                    )}
                    onClick={() => handleSelect(id, disabled)}
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      opacity: disabled ? "0.5" : "1",
                    }}
                  >
                    <div className='py-2 h-100 d-flex flex-column'>
                      <i className={`bi bi-${icon} fs-3 text-primary`}></i>
                      <p className="mt-2 fs12">{title}</p>
                      {hasDropdown && id === "9" && (
                        <div className="px-2 mb-2">
                          <div className="d-flex align-items-center justify-content-center">
                            <i 
                              className="bi bi-eye fs-5 text-primary" 
                              style={{ cursor: 'pointer' }}
                              onClick={toggleEngineTypesList}
                            ></i>
                          </div>
                          
                          {/* Engine types list */}
                          {showEngineTypes && (
                            <div 
                              className="border rounded mt-2 p-2 text-start" 
                              style={{ 
                                maxHeight: '150px', 
                                overflowY: 'auto',
                                position: 'absolute',
                                zIndex: 1000,
                                background: 'white',
                                width: '90%'
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <ul className="list-unstyled mb-0">
                                {engineTypeOptions.map((type, idx) => (
                                  <li key={idx} className="fs12 py-1">{type}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                      
                      <p className="fs12 mt-auto">{credits}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TuningInfoForm