import React, { useEffect, useState } from "react";
import axios from "axios"; 
import newlogo from "../../assets/images/logo-white.png";
import "../../assets/css/login.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as API from "../../api"
import { useNavigate } from "react-router-dom";
const Login = () => {
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    
    const onSubmit = async (data) => {
        setLoading(true);
        setMessage("");
    
        try {
            const formdata = new FormData();
            formdata.append("username", data.email);
            formdata.append("password", data.password);
    
            const response = await axios.post(API.API_URL + "/users/login", formdata);
    
            if (response.data.status_code === 200) {
                const accessToken = response.data.access_token;
                localStorage.setItem("Access-Token", accessToken);
                setMessage("Login successful!");
    
                setTimeout(() => {
                    navigate('/');
                }, 300);
            }
           
        } catch (error) {

            if (error.response) {
             
                setErrorMessage(
                    error.response.status === 404
                        ? "User not found!"
                        : error.response.status === 401
                        ? "Incorrect password!"
                        : error.response.status === 403
                        ? "Your account is inactive, please contact the admin."
                        : error.response.data?.message || "An error occurred. Please try again."
                );
            } else {
                setErrorMessage("Network error. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };
    
    
    return (
        <>
            <section className="login_page_main">
                <div className="login_website">
                    <a
                        className="navbar-brand d-flex align-items-center new-nav"
                        href="/"
                    >
                        <img
                            src={newlogo}
                            alt="Logo"
                            height="60"
                            className="me-2 logo_res"
                        />
                    </a>
                    <div className="login_web gap-2">
                        <h6>
                            <a href="/" className="back_btn">
                                Back to website
                            </a>
                        </h6>
                        <span className="">
                            <select name="" id="" className="select_tab">
                                <option value="">English</option>
                                <option value="">Nederland</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div className='col_form_login'>
                    <div className='login_website_one'>
                        <form action="" className="login_page p-5 " onSubmit={handleSubmit(onSubmit)}>
                            <h1 className="login_heading mb-4">Log in</h1>
                            <div className="my-2">E-mail address</div>
                            <div className="icon_div">
                                <input type="text" className="email_tab my-2 shadow-lg email_tab_type"
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: 'Enter a valid email address'
                                        }
                                    })}
                                />
                                {errors.email && <p>{errors.email.message}</p>}

                                <span><svg className="icon_mail shadow-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg></span>
                            </div>
                            <div className="my-2">Password</div>
                            <div className="icon_div">
                                <input type="password" className="email_tab my-2 shadow-lg email_tab_type"

                                    {...register('password', {
                                        required: 'Password is required'
                                    })}
                                />
                                {errors.password && <p>{errors.password.message}</p>}

                                <span><svg className="icon_password shadow-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z" /></svg></span>
                            </div>
                            <button
                                className="login_btn my-4 shadow-lg"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? "Logging in..." : "Login"}
                            </button>

                            {message && <p className="message login_success shadow-lg">{message}</p>}
                            {errorMessage && <p className="errorMessage login_error shadow-lg">{errorMessage}</p>}

                            <div className="my-3">
                                <h6>
                                    <a href="/forgotpassword" className="Forgot_password ">
                                        Forgot password?
                                    </a>
                                </h6>
                                <h6>
                                    <a href="/register" className=" Forgot_password ">
                                        Register for an account
                                    </a>
                                </h6>
                            </div>
                        </form>
                    </div>
                    <div className="login_website_second p-5 shadow-lg">
                        <h5 className="account_yet">Don't have an account yet?</h5>
                        <h6 className="account_one">
                            As member of Pro-ChiptuningFiles.com enjoy these benefits:
                        </h6>
                        <div className="d-flex gap-2 ">
                            <span>
                                <svg
                                    className="icon_check"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </span>
                            <p className="account_two pt-1">
                                Buy credits using iDEAL, Mister Cash or PayPal
                            </p>
                        </div>
                        <div className="d-flex gap-2 ">
                            <span>
                                <svg
                                    className="icon_check"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </span>
                            <p className="account_two pt-1">
                                Upload tuningfiles and receive the modified files in return
                            </p>
                        </div>
                        <div className="d-flex gap-2">
                            <span>
                                <svg
                                    className="icon_check"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </span>
                            <p className="account_two pt-1">
                                Modified files are of high quality, safe and Pro-tested
                            </p>
                        </div>
                        <div className="d-flex gap-2">
                            <span>
                                <svg
                                    className="icon_check"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </span>
                            <p className="account_two pt-1">
                                Every tuning file is custom made to fit your car, with the best
                                perfomance results
                            </p>
                        </div>
                        <Link to="/register">
  <button className="login_regsiter my-5 shadow-lg">Register</button>
</Link>
                        <p className="gap-2 account_two">
                            {" "}
                            58 clients rated us:
                            <span className="gap-1 ">
                                <svg
                                    className="icon_rateing"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                                </svg>
                                <svg
                                    className="icon_rateing"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                                </svg>
                                <svg
                                    className="icon_rateing"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                                </svg>
                                <svg
                                    className="icon_rateing"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                                </svg>
                                <svg
                                    className="icon_rateing"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                                </svg>
                            </span>
                            9.9/1
                        </p>
                    </div>
                </div>
            </section >
        </>
    );
};
export default Login
