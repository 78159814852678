import axios from "axios";
import * as API from "../api"


class AuthClient {

      GetVehicles = async (token) => {
            try {
                  const response = await axios.get(API.API_URL+'/search-details/get-vehicles', {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetFormVehicles = async (token) => {
            try {
                  const response = await axios.get(API.API_URL+'/pro-chiptuning/get-vehicles', {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      
      GetModels = async (_vehicle_id, accessToken) => {

            try {
                  const response = await axios.post(API.API_URL+'/search-details/get-models', {'vehicle_id': _vehicle_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetFormModels = async (_vehicle_id, accessToken) => {

            try {
                  const response = await axios.post(API.API_URL+'/pro-chiptuning/get-models', {'vehicle_id': _vehicle_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetModelGeneration = async (_model_id, accessToken) => {

            try {
                  const response = await axios.post(API.API_URL+'/search-details/get-generation', {'model_id': _model_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetFormModelGeneration = async (_model_id, accessToken) => {

            try {
                  const response = await axios.post(API.API_URL+'/pro-chiptuning/get-generation', {'model_id': _model_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }


      GetModelGenerationEngine = async (_generation_id, accessToken) => {
            try {
                  const response = await axios.post(API.API_URL+'/search-details/get-engine', {'generation_id': _generation_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetFormModelGenerationEngine = async (_generation_id, accessToken) => {
            try {
                  const response = await axios.post(API.API_URL+'/pro-chiptuning/get-engine', {'generation_id': _generation_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
      GetEngineEcu = async (_engine_id, accessToken) => {
            try {
                  const response = await axios.post(API.API_URL+'/pro-chiptuning/get-ecu', {'engine_id': _engine_id}, {
                        headers: {
                              Authorization: `Bearer ${accessToken}`,
                        },
                  })
                  if (response.status === 200) {
                        return {'data': response.data};
                  }else {
                        return {error: "Something went wrong!!"}
                  }
            } catch (error) {
                  return {error: "Something went wrong!!"}
            }
      }
  
      
}

export const authClient = new AuthClient();