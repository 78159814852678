import React, { useState, useEffect } from 'react';
import "../../assets/css/Data.css"
const Data = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Replace with your API endpoint
        const fetchData = async () => {
            try {
                const response = await fetch('https://jsonplaceholder.typicode.com/posts');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <div className="api-container">
                <h1 className="title">API Data</h1>
                <ul className="data-list">
                    {data?.map((item) => (
                        <li key={item.id} className="data-item">
                            <h2 className="item-title">{item.title}</h2>
                            <p className="item-body">{item.body}</p>
                        </li>
                    ))}
                </ul>
            </div>

        </>
    )
}

export default Data