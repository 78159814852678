import React, { useState } from 'react'
import Wrapper from '../Wrapper'
import TopCrumb from '../../header/TopCrumb'
import { Card } from 'react-bootstrap'
import SearchModel from './SearchModel'
import OrderSection from './OrderSection'

const DemoFiles = () => {

    const [filters, setFilters] = useState({
        make: '',
        model: '',
        engine: ''
    })

    const [data, setData] = useState([])
    return (
        <Wrapper>
            <TopCrumb route={"Demo files"} />
            <div className='my-4'>
                <Card className='p-4'>
                    <span className='fs-5'>Browse our Demo files database</span>
                    <div className='my-4'>
                        <SearchModel filters={filters} setFilters={setFilters} />
                    </div>
                </Card>
            </div>
            <div className='my-4'>
                <span className='fs-5'>My Orders</span>
                <Card className='p-4 mt-3'>
                   <OrderSection data={data}/>
                </Card>
            </div>
        </Wrapper>
    )
}

export default DemoFiles