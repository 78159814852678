import React, { useState, useEffect } from 'react';
import { authClient } from '../../lib/client';
import { CButton } from '@coreui/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { sendEngineData } from '../../api';
const steps = ['Custom Remapped Tuning Files', 'Choose Model', 'Choose Gen', 'Choose Engine'];

const CarTableWithModal = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [selectedCar, setSelectedCar] = useState({});
  const [cars, setCars] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stepData, setStepData] = useState({
    0: [], 
    1: [], 
    2: [], 
    3: [] 
  });
  const [selectedIds, setSelectedIds] = useState({
    vehicle_id: '',
    model_id: '',
    generation_id: '',
    engine_id: ''
  });

  const toTitleCase = (str) => {
    if (!str) return '';
    return str
      .toLowerCase()
      .split(/[\s-]/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const token = localStorage.getItem('Access-Token');
        const result = await authClient. GetVehicles(token);
        if (result.data) {
          setCars(result.data);
          setStepData(prev => ({ ...prev, 0: result.data }));
        }
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };
    fetchCars();
  }, []);

  const resetState = () => {
    setStep(0);
    setCurrentData([]);
    setSelectedCar({});
    setSelectedEngine(null);
    setShowPreview(false);
    setStepData(prev => ({ ...prev, 1: [], 2: [], 3: [] }));
    setSelectedIds({
      vehicle_id: '',
      model_id: '',
      generation_id: '',
      engine_id: ''
    });
  };

  const goBack = () => {
    const prevStep = Math.max(0, step - 1);
    setStep(prevStep);
    setCurrentData(stepData[prevStep]);
    
    if (prevStep === 0) {
      setSelectedCar({ make: selectedCar.make });
      setSelectedIds(prev => ({ ...prev, model_id: '', generation_id: '', engine_id: '' }));
      setSelectedEngine(null);
    } else if (prevStep === 1) {
      setSelectedCar(prev => ({ make: prev.make, model: prev.model }));
      setSelectedIds(prev => ({ ...prev, generation_id: '', engine_id: '' }));
      setSelectedEngine(null);
    } else if (prevStep === 2) {
      setSelectedCar(prev => ({ make: prev.make, model: prev.model, generation: prev.generation }));
      setSelectedIds(prev => ({ ...prev, engine_id: '' }));
      setSelectedEngine(null);
    }
  };

  const handleView = async (car) => {
    try {
      setSelectedCar({ make: car.car_name });
      setSelectedIds(prev => ({ ...prev, vehicle_id: car._id }));
      
      const token = localStorage.getItem('Access-Token');
      const modelsResult = await  authClient.GetModels(car._id, token);
      if (modelsResult.data) {
        setCurrentData(modelsResult.data);
        setStep(1);
        setStepData(prev => ({ ...prev, 1: modelsResult.data }));
      }
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  const handleViewGeneration = async (model) => {
    try {
      setSelectedCar(prev => ({ ...prev, model: model.model_name }));
      setSelectedIds(prev => ({ ...prev, model_id: model._id }));
      
      const token = localStorage.getItem('Access-Token');
      const generationResult = await authClient.GetModelGeneration(model._id, token);
      if (generationResult.data) {
        setCurrentData(generationResult.data);
        setStep(2);
        setStepData(prev => ({ ...prev, 2: generationResult.data }));
      }
    } catch (error) {
      console.error("Error fetching generations:", error);
    }
  };

  const handleViewEngine = async (generation) => {
    try {
      setSelectedCar(prev => ({ ...prev, generation: generation.generation_name }));
      setSelectedIds(prev => ({ ...prev, generation_id: generation._id }));
      
      const token = localStorage.getItem('Access-Token');
      const engineResult = await authClient. GetModelGenerationEngine(generation._id, token);
      if (engineResult.data) {
        const engineData = engineResult.data.data || [];
        setCurrentData(engineData);
        setStep(3);
        setStepData(prev => ({ ...prev, 3: engineData }));
      }
    } catch (error) {
      console.error("Error fetching engines:", error);
    }
  };

  const handleSelectEngine = (engine, type) => {
    setSelectedEngine({ ...engine, type });
    setSelectedCar(prev => ({ ...prev, engine: engine.engine_name, engineType: type }));
    setSelectedIds(prev => ({ ...prev, engine_id: engine._id }));
  };

  const handlePreviewDetails = async () => {
    try {
      setLoading(true);
      
      if (!selectedIds.vehicle_id || !selectedIds.model_id || !selectedIds.generation_id || !selectedIds.engine_id) {
        toast.error("Please select all vehicle options");
        setLoading(false);
        return;
      }

      const token = localStorage.getItem('Access-Token');
      const response = await sendEngineData(selectedIds, token);
      
      if (
        !response?.power_object || Object.keys(response.power_object)?.length === 0 ||
        !response?.torque_object || Object.keys(response.torque_object)?.length === 0 ||
        !response?.engine_details || Object.keys(response.engine_details)?.length === 0
      ) {
        toast.error("No data found for the selected vehicle configuration");
        setLoading(false);
        return;
      }
      
      navigate("/chiptuningfile", {
        state: { engineData: response },
      });
    } catch (error) {
      console.error("Error fetching preview data:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getStepDisplay = () => {
    const displayObj = {};
    if (selectedCar.make) displayObj.make = selectedCar.make;
    if (selectedCar.model) displayObj.model = selectedCar.model;
    if (selectedCar.generation) displayObj.generation = selectedCar.generation;
    if (selectedCar.engine) displayObj.engine = selectedCar.engine;
    
    return displayObj;
  };

  const stepDisplay = getStepDisplay();

  return (
    <div className="p-4 space-y-6">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{steps[step]}</h2>
     
      </div>

      <div className="d-flex mb-3">
        {step > 0 && (
          <CButton color="secondary" onClick={goBack} className="me-2">Back</CButton>
        )}
        {step === 3 && selectedEngine && (
          <CButton 
            color="primary" 
            onClick={handlePreviewDetails} 
            disabled={loading}
          >
            {loading ? "Loading..." : "Preview Details"}
          </CButton>
        )}
      </div>

      <div className="d-flex flex-column mb-4">
        <div className="d-flex align-items-center">
          {steps.map((label, index) => (
            <React.Fragment key={index}>
              <div
                className={`step-circle ${index <= step ? 'bg-info' : 'bg-secondary'}`}
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  margin: '0 8px',
                  display: 'inline-block'
                }}
              />
              {index < steps.length - 1 && (
                <div
                  className={index < step ? 'bg-info' : 'bg-secondary'}
                  style={{ width: '80px', height: '2px' }}
                />
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="d-flex mt-2 gap-4" style={{ fontSize: '15px' }}>
          {steps.map((label, index) => {
            const propNames = ['make', 'model', 'generation', 'engine'];
            const propName = propNames[index];
            
            return (
              <div key={index}>
                <div>{label}:</div>
                <div className="text-info">
                  {stepDisplay[propName] ? toTitleCase(stepDisplay[propName]) : ''}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="d-grid gap-3" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {step === 0 && cars.map((car) => (
          <CButton 
            key={car._id} 
            onClick={() => handleView(car)} 
            color="secondary" 
            style={{ 
              borderRadius: '13px', 
              backgroundColor: selectedIds.vehicle_id === car._id ? '#1E88E5' : '#323A49'
            }}
          >
            {toTitleCase(car.car_name)}
          </CButton>
        ))}

        {step === 1 && currentData.map((model) => (
          <CButton 
            key={model._id} 
            onClick={() => handleViewGeneration(model)} 
            color="secondary" 
            style={{ 
              borderRadius: '13px', 
              backgroundColor: selectedIds.model_id === model._id ? '#1E88E5' : '#323A49'
            }}
          >
            {toTitleCase(model.model_name)}
          </CButton>
        ))}

        {step === 2 && currentData.map((gen) => (
          <CButton 
            key={gen._id} 
            onClick={() => handleViewEngine(gen)} 
            color="secondary" 
            style={{ 
              borderRadius: '13px', 
              backgroundColor: selectedIds.generation_id === gen._id ? '#1E88E5' : '#323A49'
            }}
          >
            {toTitleCase(gen.generation_name)}
          </CButton>
        ))}

        {step === 3 && Object.entries(currentData).map(([type, engineList]) => (
          <div key={type} className="mb-3" style={{ gridColumn: '1 / -1' }}>
            <h5>{type.toUpperCase()} Engines:</h5>
            <div className="d-grid gap-3" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {engineList && engineList.length > 0 ? engineList.map((engine) => (
                <CButton 
                  key={engine._id} 
                  color="secondary" 
                  style={{ 
                    borderRadius: '13px', 
                    backgroundColor: selectedEngine && selectedEngine._id === engine._id ? '#1E88E5' : '#323A49',
                  }}
                  onClick={() => handleSelectEngine(engine, type)}
                >
                  {toTitleCase(engine.engine_name)}
                </CButton>
              )) : (
                <div>No engines available</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarTableWithModal;