import React from 'react'
import Wrapper from '../Wrapper'
import TopCrumb from '../../header/TopCrumb'
import { Form, Button } from "react-bootstrap";

const AddSubscription = () => {

    return (
        <Wrapper>
            <TopCrumb route={"Add subscription"} />
            <div className='my-4 row'>
                <div className='col-md-8 col-12'>
                    <div className="container">
                        <Form className="p-4 border rounded shadow-sm">
                            <Form.Group className="mb-2" controlId="formTool">
                                <Form.Label className='subs_desc'>Tool</Form.Label>
                                <Form.Select>
                                    <option>Make your choice</option>
                                    <optgroup label="Master">
                                        <option value="544">Autotuner tool - Master</option>
                                        <option value="600">FLEX tool - Master</option>
                                        <option value="628">bFlash tool - Master</option>
                                        <option value="1552">Autoflasher tool - Master</option>
                                        <option value="724">CMD tool - Master</option>
                                        <option value="1657">KESS3 Master Winter Deal 2!</option>
                                        <option value="1502">KESS3 tool - Master</option>
                                        <option value="101">ECM Titanium - Full version</option>
                                    </optgroup>
                                    <optgroup label="Slave">
                                        <option value="622">New Genius tool - Slave</option>
                                        <option value="629">bFlash tool - Slave</option>
                                        <option value="726">CMD tool - Full Slave</option>
                                        <option value="1225">FLEX tool - Slave</option>
                                        <option value="1550">Autoflasher tool - Slave</option>
                                        <option value="1658">KESS3 Slave Winter Deal!</option>
                                        <option value="623">New Trasdata tool - Slave</option>
                                        <option value="725">CMD tool - Slave unrestricted</option>
                                        <option value="1659">KESS3 Slave Winter Deal 2!</option>
                                        <option value="1516">KESS3 Hardware replacement</option>
                                    </optgroup>
                                </Form.Select>
                            </Form.Group>

                            {/* Subscription Dropdown */}
                            <Form.Group className="mb-2" controlId="formSubscription">
                                <Form.Label className='subs_desc'>Subscription</Form.Label>
                                <Form.Select>
                                    <option>Make your choice</option>
                                </Form.Select>
                            </Form.Group>

                            {/* Serial Number Input */}
                            <Form.Group className="mb-2" controlId="formSerialNumber">
                                <Form.Label className='subs_desc'>Serial number</Form.Label>
                                <Form.Control type="text" placeholder="Enter serial number" />
                            </Form.Group>

                            {/* ID Input */}
                            <Form.Group className="mb-2" controlId="formID">
                                <Form.Label className='subs_desc'>ID <span className="text-muted">(optional)</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter ID" />
                            </Form.Group>

                            {/* Expiry Date Input */}
                            <Form.Group className="mb-2" controlId="formExpiry">
                                <Form.Label className='subs_desc'>Expires at</Form.Label>
                                <Form.Control type="date" placeholder="DD-MM-YYYY" />
                            </Form.Group>

                            {/* Buttons */}
                            <div className="d-flex justify-content-between">
                                <Button className='cancel_butn'>Cancel</Button>
                                <Button variant="primary">Save</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default AddSubscription