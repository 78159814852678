import React from 'react'
import "../../assets/css/magicmotorsport.css"
import MainHeader from '../header/Header';
import MainFooter from '../footer/Footer';
import toolequipment from "../../assets/images/toolequipment.jpg"
import car from "../../assets/images/car.png"
import toolpreview from "../../assets/images/toolpreview.png"
import ModelSearchFormSecond from './ModelSearchFormSecond';
const magicmotorsport = () => {
    return (
        <>
            <section>
                <MainHeader />
            </section>
            <section className='alientechtools_page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='magicmotorsport_content'>
                                <h1 className='heading_alientech'>MAGICMOTORSPORT</h1>
                                <p className='pragraph_fleshtool'>Meet MagicMotorsport, a set of different programmers which share common strengths: flexibility, simplicity, safety. Thanks to an extremely advanced Tech Support system and to the huge investments in Research & Development, MagicMotorsport is one of the leaders in the sector of electronic programmers for ECU & TCU repair and ECU software remapping.</p>
                                <p className='pragraph_fleshtool my-5'>Looking for MagicMotorsport tuning tools? Find them all right here on our new<a href="" className='Alientech_link'> website!</a></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-12 responsive_tab'>
                            <div className='magicmotorsport_1'>
                                <img src={toolpreview} alt="" width={750} height={400} className='box_image' />
                                <div className='responsive_tab'>
                                    <p className='pragraph_alientech_3'>Use your FLEX to easily tune, reprogram, upgrade or modify the software of most control units directly through the OBD socket of the vehicle. An internet connection gives you access to all the potential that the tool offers you.</p>
                                    <p className='pragraph_alientech_3'>For advanced and repairing jobs, use the Flexbox module to program your control unit in BDM, Bootloader, JTAG and AUD modes. The device detects eventual connection or communication errors thanks to the concept of its state-of-the -art design.</p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-12  responsive_tab'>
                            <div className='magicmotorsport_1'>
                                <h1 className='heading_alientech_1'>FLEX INCLUDES </h1>
                                <p className='pragraph_alientech_3'>The MagicMotorsport Flex tuning tool comes with a complete set of accessories, which are listed below: </p>
                                <img src={toolequipment} alt="" width={750} height={400} className='box_image' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                            <div className='magicmotorsport_2'>
                                <h1 className='heading_alientech_1'>SUBSCRIPTIONS & PROTOCOLS</h1>
                                <ul className='Software_tab mt-5'>
                                    <li>Software Flex ECU OBD + Bench </li>
                                    <li>Software Flex TCU OBD + Bench</li>
                                    <li>Software Flex Motorola MPC5xx </li>
                                    <li>Software Flex Nexus MPCxxx</li>
                                    <li>Full Flex SW Package</li>
                                    <li>Software Flex Renesas SH7xxxx</li>
                                    <li>Software Flex Siemens C165/166/167</li>
                                    <li>Software Flex ST10F2xx</li>
                                    <li>Software Flex JTAG NEC 76F00X</li>
                                    <li>Software Flex Infineon TC17xx</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                            <p className='pragraph_alientech_4'>Looking for MAGICMOTORSPORT tuning tools? Find them all right here on our new<a href="" className='Alientech_link_1'> website!</a></p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row '>
                        <div className='col-8 my-5 speedometer_image_1'>
                            <div className='p-5 pragraph_alientech_7'>
                                <p className='pragraph_alientech_6 '>CURIOUS ABOUT ALIENTECH'S ASSORTMENT?</p>
                                <p className='pragraph_alientech_6 '>SUBSCRIPTION & PROTOCOLS </p>
                                <p className='pragraph_alientech_6 '>The best and fast webshop provider by the </p>
                                <p className='pragraph_alientech_6 '>Best experience and technology and engineers</p>
                                <button className='Shop_alientech   shadow-lg '>Shop Magicmotorsport</button>
                                <div className='worldwide_shiping my-4'>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>worldwide shiping</h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'>premium brands </h6>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <div className='shop_btn'>
                                            <svg className='shop_btn_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                        </div>
                                        <h6 className='pragraph_alientech_6'> high quality chiptuning tools</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row responsive_alientech'>
                        <div className='col-4 mt-5 responsive_tab'>
                            <img src={car} alt="car" width={500} height={300} className='box_image' />
                        </div>
                        <div className='col-8 my-5 responsive_tab_1'>
                            {/* <form action="" className='from_car shadow-lg '>
                                <h1 className='pragraph_alientech_5 my-2'>View all our <a href="" className='Alientech_link_1'>chiptuning files</a> specifications online</h1>
                                <div className='select_tab_alienech'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <div className='select_tab_alienech responsive_choose'>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a make

                                        </option>
                                    </select>
                                    <select name="" id="" className='Choose_tab'>
                                        <option value="">Choose a model

                                        </option>
                                    </select>
                                </div>
                                <button className='Start_Seaching my-3 shadow-lg '>Start Seaching</button>
                            </form> */}
                            <ModelSearchFormSecond />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <MainFooter />
            </section>
        </>
    )
}

export default magicmotorsport