import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

const EngineSelect = ({ value, handleChange, engineData }) => {
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/[\s-]/) 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };
    return (
      <Form.Select name='engine_id' onChange={handleChange} value={value}>
        <option value="">Choose an engine</option>
        {Array.isArray(engineData) &&
    engineData.map((engine) => (
      <option key={engine._id} value={engine._id}>
        {toTitleCase(engine.engine_name)}
      </option>
    ))}
      </Form.Select>
    );
  };
export default EngineSelect