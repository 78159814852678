import React from "react";
import DataTable from "react-data-table-component";
import { Badge, Spinner } from "react-bootstrap";

const Datatable = ({columns,data}) => {

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            responsive
        />
    );
};

export default Datatable;
